import React, { useEffect, useState } from 'react'
import { BiCheck } from 'react-icons/bi'
import Axios from '../../services/Config';
import { FormatDateHeure } from '../../components/FormatDate';

const Solder = ({ setSelectedTransaction }) => {
  const [dataTransactionsValide, setDataTransactionsValide] = useState([]);
  // État pour le produit sélectionné
  const token = localStorage.getItem('sessionDataToken');

  useEffect(() => {
    Axios.get("supplier/transactions?category=sale_admin&status=validated", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        console.log("Operation de transaction effectuée!", response.data);
        setDataTransactionsValide(response.data.data)
      })

      .catch(error => console.log(error));
  }, []);
  // Fonction pour ouvrir le modal avec le produit sélectionné
  const modalData = (transaction) => {
    setSelectedTransaction(transaction); // Enregistrer le produit sélectionné
    console.log(transaction)
  };
  return (
    <div>
      <h6 className="text-muted text-start mx-3">Aujourd'hui <hr className='trait-fin'/></h6>
      { dataTransactionsValide && dataTransactionsValide.length > 0 ? (
        dataTransactionsValide.map((transaction) =>(
          <div key={transaction.id} className="border bg-white p-1 rounded-3 m-2 text-black shadow-sm d-flex align-items-center">
            {transaction.order.items?.map((item)=>(
              <div className="row">
                <div className="col-2">
                  <img src={item.product.images[0]} width={80} height={80} className="rounded float-start" alt="..." />
                </div>
                <div className="col-2 text-start border-end">
                  <h6 className="">{item.product.name}</h6>
                  <p className="card-text">
                    <small className="text-muted"># {transaction?.order?.reference}</small>
                    <br />
                    <small className="text-muted date">{FormatDateHeure(transaction.created_at)}</small>
                  </p>
                </div>
                <div className="col-2 border-end">
                  <h6 className="text-truncate">Prix partenaire </h6>
                  <p className="card-text">
                    <small className="fw-bold">{item.product.price.partner} FCFA</small>
                  </p>
                </div>
                <div className="col-2 border-end">
                  <h6 className="text-truncate">Total à payer </h6>
                  <p className="card-text">
                    <small className="fw-bold text-primary">{item.total_product} FCFA</small>
                  </p>
                </div>
                <div className="col-2 border-end">
                  <h6 className="text-truncate">Commission daymond</h6>
                  <p className="card-text">
                    <small className="fw-bold text-orange">{item.product.price.commission === null ? "0" : item.product.price.commission}  FCFA </small>
                  </p>
                </div>
                <div className="col-2 card-none">
                  <div className="d-flex float-end mb-2">
                    <small className='date'> 
                      {/* il y'a une minute */}
                      {FormatDateHeure(transaction.created_at) !== new Date() ? FormatDateHeure(transaction.created_at) : "Il y'a quelques minutes"}
                      </small>
                  </div>
                  <div className="btn text-wrap" data-bs-toggle="modal" data-bs-target="#staticBackdrop"  onClick={() => modalData(transaction)}><div className="text-success fw-bold fs-4"><BiCheck className='icon-check' /> PAYÉ</div></div>
                </div>
              </div>            
            ))}
          </div>        
        ))
      ) : (
        <div className='alert alert-danger fw-semibold'>Aucune transaction validé à ce jour.</div>
      ) 

      }
    </div>
  )
}

export default Solder