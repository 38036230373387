import React, { useEffect } from 'react'
import { useState } from 'react';
import { IoArrowBack } from 'react-icons/io5'
import ImageDefault from './../../assets/ImageDefault.svg'
import { Select, Tag } from 'antd';
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload, Form } from 'antd';
import Swal from 'sweetalert2';
import Axios from '../../services/Config';

const options = [
  {
    value: 'black',
    // name:"noir"
  },
  {
    value: 'white',
    name:"blanc"
  },
  {
    value: 'red',
    // name:"rouge"
  },
  {
    value: 'blue',
    // name:"bleu"
  },
  {
    value: 'green',
    // name:"verte"
  },
  {
    value: 'royalblue',
    // name:"bleu foncé"
  },
  {
    value: 'pink',
    // name:"rose"
  },
  {
    value: 'skyblue',
    // name:"bleu ciel"
  },
  {
    value: 'orange',
    // name:"orange"
  },
  {
    value: 'yellow',
    // name:"jaune"
  },
  // {
  //   value: 'red',
  //   name:"rouge"
  // },
];


const tailleOptions = [
  // Tailles de vêtements classiques
  "XXS", "XS", "S", "SM", "M", "L", "XL", "XXL", "2XL", "3XL", "4XL", "5XL",
  // Tailles pour accessoires (chaussures, chapeaux, montres)
  "S/M", "M/L", "L/XL",

  // Tailles pour accessoires électroniques (ordinateurs, tablettes, etc.)
  "9 pouces", "10 pouces", "11 pouces", "12 pouces", "13 pouces", "14 pouces", "15 pouces", "17 pouces", "18 pouces", "20 pouces",
  "4 pouces", "4.7 pouces", "5 pouces", "5.5 pouces", "5.8 pouces", 
  "6 pouces", "6.1 pouces", "6.2 pouces", "6.3 pouces", "6.4 pouces", 
  "6.5 pouces", "6.7 pouces", "6.8 pouces", "7 pouces", "7.2 pouces",
  // Modèles de téléphone avec des suffixes
  "Mini", "Pro", "Pro Max", "Ultra", "Plus", "Max",

  // Tailles de téléphone spécifique (pour d'autres caractéristiques)
  "Taille compacte", "Taille standard", "Grand format",

  // Tailles de pantalons / jeans
  "28", "30", "32", "34", "36", "38", "40", "30L", "32L", "34L", "36L",

  // Chaussures (international)
  "6", "6.5", "7", "7.5", "8", "8.5", "9", "10", "11", "12",
  "39", "40", "41", "42", "43", "44",
  "5", "6", "7", "8", "9", "10",

  // // Tailles pour bébés/enfants
  // "0-3 mois", "3-6 mois", "6-12 mois", "12-18 mois", "18-24 mois", 
  // "2T", "3T", "4T",

  // Autres options spécifiques
  "One Size", "Taille unique", "Taille enfant", "Taille adulte",
  "Petit", "Moyen", "Grand", "Standard", "Large", "Extra Large",

  // Classifications basées sur la taille ou le volume
  "Petite", "Moyenne", "Grande", "Très grande",
];

const AjoutProduit = () => {

  const [selectedItems, setSelectedItems] = useState([]);
  // const filteredOptions = tailleOptions.filter((o) => !selectedItems.includes(o));
  const [dataBoutiques, setDataBoutiques] = useState([]);
  
  //recuperer les donnees de la sessionStorage pour voir le role de l'utilisateur present
  const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
  const role = sessionData.option.is_admin;


  const { handleSubmit, register, control, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };


  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    );
  };

  const token = localStorage.getItem('sessionDataToken');
  // console.log(token);
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const onSubmit = (data) => {
    // data.sizes = Array.isArray(data.sizes) ? data.sizes : [data.sizes];
    // let sizes = data.sizes; 
    console.log("size", data.sizes);

    const formData = new FormData();

    formData.append('alias', data.alias);
    formData.append('code', data.code);
    formData.append('description', data.description);
    formData.append('name', data.name);
    formData.append('price_partner', data.price_partner);
    formData.append('price_supplier', data.price_supplier);
    formData.append('state_id', data.state_id);
    formData.append('quantity', data.stock);
    if(data.boutique_id){
      formData.append('shop_id', data.boutique_id);
    }
    fileList.forEach((file) => {
      formData.append('images[]', file.originFileObj); // Envoi du fichier brut
    });
    // fileList.forEach((file) => {
    //   formData.append('images[]', file.originFileObj); // Envoi du fichier brut
    // });
    data.colors.forEach((item, index) => {
      formData.append(`colors[${index}]`, item);
      // formData.append('colors[]', data.colors);
    });

    data.sizes.forEach((item, index) => {
      formData.append(`sizes[${index}]`, item);
      // formData.append(`stock_sizes_${index}`, data.stock_sizes[index]); 
    });
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    Axios.post('supplier/product', formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(function (response) {
        console.log("Ajouter avec succès : ", response.data);
        Swal.fire({
          title: "Opération réussie!",
          text: "Produit ajouté avec succès.",
          icon: "success"
        }).then((result) => {
          if (result.isConfirmed) {
            //redirection si le client clique sur le bouton "ok";
            navigate('/produits');
          }
        });
      })
      .catch(error => {
        console.log(error);
        Swal.fire({
          title: 'Requête échouée!',
          text: error.response.data.message,
          icon: 'error',
          // confirmButtonText: 'OK'
        });
      });
  }

  // Récupérer les villes
  const [dataState, setDataState] = useState([]);
  useEffect(() => {
    //recupérer toutes les boutique relié au compte
    Axios.get("params/data?option=state", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        console.log("Operation 2 effectuée!", response.data);
        setDataState(response.data.data);
      })
      .catch(error => console.log(error));
  }, []);


  //Recupérer toutes les boutiques reliés au compte
  useEffect(() => {
      Axios.get("supplier/shop", {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
          .then((response) => {
              console.log("Operation 2 effectuée!", response.data);
              setDataBoutiques(response.data.data);
          })
          .catch(error => console.log(error));
  }, []);

  return (
    <div>
      <div className='mt-3 mb-3'>
        <div className="card">
          <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
            <div className="card-header bg-white">
              <div className="d-flex gap-3">
                <div className="">
                  <IoArrowBack className='me-2' onClick={() => navigate(-1)} />
                  Ajouter un produit</div>
                <div className='add-produit d-flex text-nowrap'>
                  <div className="btn btn-outline-warning rounded-5 me-2">Scanner</div>
                  {/* validation du formulaire */}
                  <button className="btn btn-warning rounded-5 text-light">Soumettre</button>
                </div>
              </div>
            </div>
            <div className="card-body row">
              {/* formulaire d'ajout */}
              <div className="col-4">
                <div className="card" style={{ backgroundColor: "#F5F6FB" }}>
                  <div className="card-body">
                    <div className="card text-center bg-white mb-3">
                      <div className="card-body">
                        <div className="card-title">
                          <img src={ImageDefault} width={50} className="img-fluid" alt="..." />
                          {/* {uploadButton} */}
                        </div>
                        <small className="card-text">Image principale <br /> 5 images minimums</small>
                      </div>
                    </div>
                    {/* <Controller
                      name="images"
                      control={control}
                      rules={{ required: "Veuillez télécharger au moins un fichier" }}
                      defaultValue={[]}
                      render={({ field }) => (
                        <div>
                          <Form.Item
                            validateStatus={errors.images ? "error" : ""}
                            help={errors.images?.message}
                          ></Form.Item>
                          <Upload
                            listType="picture-card"
                            fileList={field.value}
                            onPreview={handlePreview}
                            beforeUpload={() => false}
                            multiple
                            accept=".png,.jpeg,.jpg"      // Acceptation des fichiers PNG, JPEG, JPG uniquement
                            onChange={({ fileList }) => {
                              field.onChange(fileList); // Mettre à jour la valeur de images avec fileList
                            }}
                          >
                            {fileList.length >= 5 ? null : uploadButton}
                          </Upload>
                        </div>
                      )}
                    /> */}
                    <Upload
                      listType="picture-card"
                      multiple
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      beforeUpload={() => false}
                      maxCount={5}
                    >
                      {fileList.length >= 5 ? null : uploadButton}
                    </Upload>
                    {/* {previewImage && (
                      <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                      />
                    )} */}
                    <div style={{ marginTop: 16, display: 'flex', alignItems: "center" }}>
                      {previewImage && (
                        <Image
                          wrapperStyle={{
                            display: 'none',
                          }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                          }}
                          src={previewImage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="card text-start border-none">
                  <div className="card-body">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" id="floatingInput1" placeholder="Scanner le code produit" {...register("code")} />
                      <label htmlFor="floatingInput1">Code produit</label>
                      {/* <small>{errors.code && <p role="alert" className="text-danger">{errors.code.message}</p>}</small> */}
                    </div>
                    {role === 1 ? 
                      <div className="form-floating mb-3">
                        <select className='form-select' id="boutique_id" name='boutique_id' {...register("boutique_id", { required: "Ce champs est réquis" })} required>
                          <option disabled></option>
                          {dataBoutiques.map((city) => (
                            <option key={city.id} value={city.id}>{city.name}</option>
                          ))}
                        </select>
                        <label htmlFor="boutique_id">Selectionnez la boutique</label>
                      </div>         
                    : ""}


                    <div className="row mb-3">
                      <div className="col">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="floatingInput2" placeholder="Nom du produit" {...register("name", { required: "Ce champs est réquis" })} />
                          <label htmlFor="floatingInput2">Nom du produit</label>
                          <small>{errors.name && <p role="alert" className="text-danger">{errors.name.message}</p>}</small>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="floatingInput3" placeholder="Marque" {...register("alias", { required: "Ce champs est réquis" })} />
                          <label htmlFor="floatingInput3">Marque</label>
                          <small>{errors.alias && <p role="alert" className="text-danger">{errors.alias.message}</p>}</small>
                        </div>
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <textarea type="text" rows="10" cols="33" className="form-control h-25" placeholder="Information du produit ici" id="floatingTextarea4" {...register("description", { required: "Ce champs est réquis" })}></textarea>
                      <label htmlFor="floatingTextarea4">Information du produit</label>
                      <small>{errors.description && <p role="alert" className="text-danger">{errors.description.message}</p>}</small>
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <div className="form-floating mb-3">
                          <select className='form-select' id="floatingInput5" name='state' {...register("state_id", { required: "Ce champs est réquis" })}>
                            {dataState.map((state) => (
                              <option key={state.id} value={state.id}>{state.name}</option>
                            ))}
                          </select>
                          <label htmlFor="floatingInput5">Etat du produit</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating">
                          <input type="number" className="form-control" id="floatingInput6" placeholder="quantité en stock" {...register("stock", { required: "Ce champs est réquis" })} />
                          <label htmlFor="floatingInput6">Quantité en stock</label>
                          <small>{errors.stock && <p role="alert" className="text-danger">{errors.stock.message}</p>}</small>

                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col text-start">
                        <label htmlFor="">Taille du produit</label>
                        <Controller
                          name="sizes"
                          mode="multiple"
                          control={control}
                          rules={{ required: "Veuillez sélectionner au moins une option" }}
                          defaultValue={[]}
                          render={({ field }) => (
                            <div>
                              <Form.Item
                                validateStatus={errors.sizes ? "error" : ""}
                                help={errors.sizes?.message}
                              >
                                <Select
                                  mode="multiple"
                                  // placeholder="Inserted are removed"
                                  // value={selectedItems}
                                  onChange={(value) =>{ 
                                    const valueArray = Array.isArray(value) ? value : [value];
                                    console.log(valueArray  ); 
                                    field.onChange(valueArray)}}
                                  style={{
                                    width: '100%',
                                    height: '50px',
                                  }}
                                  {...field}
                                  options={tailleOptions.map((item) => ({
                                    value: item,
                                    label: item,
                                  }))}
                                />
                              </Form.Item>
                            </div>
                          )}
                        />
                      </div>
                      <div className="col">
                        <div className='text-start'>
                          <label htmlFor="">Couleur du produit</label>
                          <Controller
                            name="colors"
                            control={control}
                            rules={{ required: "Veuillez sélectionner au moins une couleur" }}
                            defaultValue={[]}
                            render={({ field }) => (
                              <Form.Item
                                validateStatus={errors.colors ? "error" : ""}
                                help={errors.colors?.message}
                              >
                                <Select
                                  mode="multiple"
                                  tagRender={tagRender}
                                  style={{ width: '100%', height: '50px', background: 'skyBlue' }}
                                  options={options}
                                  {...field}
                                />
                              </Form.Item>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="floatingInput7" placeholder="Prix officiel du marché" {...register("price_supplier", { required: "prix officiel est réquis" })} />
                          <label htmlFor="floatingInput7">Prix officiel du marché</label>
                          <small>{errors.price_supplier && <p role="alert" className="text-danger">{errors.price_supplier.message}</p>}</small>
                          <p className='text-muted fw-lighter' id="text-tiny">* Cela nous permet de proposer des prix homologués et compétitifs sur le marché </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="floatingInput8" placeholder="Prix partenaire Daymond" {...register("price_partner", { required: "le prix partenaire est réquis" })} />
                          <label htmlFor="floatingInput8">Prix partenaire Daymond</label>
                          <small>{errors.price_partner && <p role="alert" className="text-danger">{errors.price_partner.message}</p>}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AjoutProduit