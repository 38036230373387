import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ModalFooter } from './modalComponents/ModalFooter';
import { ModalHeader } from './modalComponents/ModalHeader';
import Swal from 'sweetalert2';
import Axios from '../../services/Config';
import { useParams } from 'react-router-dom';

const ModalEditStock = ({ title, boutonText, idPoduit }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const token = localStorage.getItem('sessionDataToken');
    const { id } = useParams();

    // Fonction de soumission
    const onSubmit = (data) => {
        // console.log("Form Data Submitted: ", data);
        Axios.post(`supplier/product/${id}`, data, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(function (response) {
                console.log("Ajouter avec succès : ", response.data);
                Swal.fire({
                    title: "Opération réussie!",
                    text: "Stock mise à jour avec succès.",
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        //redirection si le client clique sur le bouton "ok";
                        //   navigate('/produits');
                        window.location.reload();
                    }
                });
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    title: 'Erreur!',
                    text: 'Erreur lors de la requête d\'enregistrement (POST).',
                    icon: 'error',
                    // confirmButtonText: 'OK'
                });
            });
    };
    return (
        <div>
            <div className="modal fade" id="updateStock" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <ModalHeader title={title}></ModalHeader>
                        <div className="modal-body gap-3">
                            <div className="row">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {/* Bouton de soumission */}
                                    <div className="">
                                        <input type="hidden" className="form-control" id="floatingOption" placeholder="Option" defaultValue="stock" {...register("option")} />
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input type="number" className="form-control" id="floatingStock" placeholder="stock" {...register("stock", { required: "Ce champs est réquis" })} />
                                        <label htmlFor="floatingStock">stock</label>
                                        <small>{errors.stock && <p role="alert" className="text-danger">{errors.stock.message}</p>}</small>
                                    </div>

                                    <button type="submit" className="btn btn-primary">{boutonText}</button>
                                </form>
                            </div>
                        </div>
                        {/* <ModalFooter ></ModalFooter> */}
                        {/* onSave={onSave} boutonText={boutonText} */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditStock;
