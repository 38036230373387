import React, { useEffect, useState } from 'react'
import Axios from '../../services/Config';
import { FormatDateHeure } from '../../components/FormatDate';
import { Link, NavLink } from 'react-router-dom';

const NonSolder = ({setSelectedTransaction}) => {
  const [dataTransactions, setDataTransactions] = useState([]);
    // État pour le produit sélectionné
  const token = localStorage.getItem('sessionDataToken');

  useEffect(() => {
    Axios.get("supplier/transactions?category=sale_admin&status=pending", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        console.log("Operation de transaction effectuée!", response.data);
        setDataTransactions(response.data.data)
      })

      .catch(error => console.log(error));
  }, []);
 // Fonction pour ouvrir le modal avec le produit sélectionné
 const modalData = (transaction) => {
  setSelectedTransaction(transaction); // Enregistrer le produit sélectionné
  console.log(transaction)
};

  return (
    <div className=''>
      <h6 className="text-muted text-start mx-3">Aujourd'hui <hr className='trait-fin'/></h6>
      { dataTransactions && dataTransactions.length > 0 ? (
        dataTransactions.map((transaction) =>(
        <div key={transaction.id} className="order-card bg-white p-2 rounded shadow-sm text-black text-decoration-none mb-2">
          {transaction.order.items?.map((item)=>(
            <div className="row">
              {/* <Link to={`/commandes/detail/${transaction.order.id}`} key={item.id} className="text-decoration-none"> */}
              <div className="col-2">
                <img src={item.product.images[0]} width={100} height={100} className="rounded float-start" alt="..." />
              </div>
              {/* </Link> */}

              <div className="col-2 text-start border-end">
                <h6 className="card-title">{item.product.name}</h6>
                <p className="card-text">
                  <small className="text-muted"># {transaction?.order?.reference}</small>
                  <br />
                  <small className="text-muted date">{FormatDateHeure(transaction.created_at)}</small>
                </p>
              </div>
              <div className="col-2 border-end">
                <h6 className="card-title text-truncate">Prix partenaire </h6>
                <p className="card-text">
                  <small className="fw-bold">{item.product.price.partner}  FCFA </small>
                </p>
              </div>
              <div className="col-2 border-end">
                <h6 className="card-title text-truncate">Total à payer </h6>
                <p className="card-text">
                  <small className="fw-bold text-primary">{item.total_product} FCFA </small>
                </p>
              </div>
              <div className="col-2 border-end">
                <h6 className="card-title text-truncate">Commission daymond </h6>
                <p className="card-text">
                  <small className="fw-bold text-orange">{transaction.amount === null ? "0" : transaction.amount}  FCFA </small>
                </p>
              </div>

              <div className="col-2 card-none">
                <div className="d-flex float-end mb-2">
                  <small className='date mb-4'> il y'a une minute</small>
                </div>
                <div className=''>
                  <button className="btn-solder text-wrap" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => modalData(transaction)}>Solder</button>
                </div>
              </div>
            </div>            
          ))}

        </div>        
      ))        
      ) :(
        <div className="alert alert-danger fw-semibold">Aucune transaction pour le moment.</div>
      )
}

      
    </div>
  )
}

export default NonSolder