import React from 'react'
import { ModalHeader } from './modalComponents/ModalHeader'
import ImageDefault from './../../assets/image.png'

const ModalInfoEntreprise = ({bodyContent, id, title}) => {
  return (
    <div>
        <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby={id} tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <ModalHeader title={title} />
                
                <div className="modal-body" >
                    <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={bodyContent.name} id="first_name" disabled/>
                                <label htmlFor="first_name">Nom de l'entreprise :</label>
                    </div>  

                    <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={bodyContent.address} id="Adresse" disabled/>
                                <label htmlFor="Adresse">Adresse :</label>
                    </div>  
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" defaultValue={bodyContent.phone_number} id="Telephone" disabled/>
                        <label htmlFor="Telephone">Téléphone :</label>
                    </div>  
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" defaultValue={bodyContent.email} id="email" disabled/>
                        <label htmlFor="email">Nom de l'entreprise :</label>
                    </div>  
                    {/* <p className='left'>Nom <span className="fw-semibold">{bodyContent.name}</span> </p>
                    <p className='left'>Adresse : <span className="fw-semibold">{bodyContent.address}</span>  </p>
                    <p className='left'>Téléphone : <span className="fw-semibold">{bodyContent.phone_number}</span>  </p>
                    <p className='left'>Email : <span className="fw-semibold">{bodyContent.email}</span>  </p> */}
                    <div className="col">
                        <p>Logo</p>
                        <div className="profile-image-container">
                            <img src={bodyContent.logo === null ? ImageDefault : bodyContent.logo} width={200} className="profile-image" alt="..." />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
                </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default ModalInfoEntreprise