import React, { useEffect, useState } from 'react'
import ImageDefault from './../../assets/image.png'
import AddButton from './../../assets/addBouton.png'
import ModalInfoGerant from '../../components/modals/ModalInfoGerant'
import { Link, NavLink } from 'react-router-dom'
import { ModalInfoCompte } from '../../components/modals/ModalInfoCompte'
import Axios from '../../services/Config';
import { ModalInfoBoutique } from '../../components/modals/ModalInfoBoutique'
import ModalInfoProprietaire from '../../components/modals/ModalInfoProprietaire'
import ComptabiliteIcone from './../../assets/comptabiliteIcone.svg'
import { accountService } from '../../services/Account.service'
import { LoadSpinnerTwo } from '../../components/LoadSpinnerTwo'
import "./../../styles/parametre.css"
import ModalInfoEntreprise from '../../components/modals/ModalInfoEntreprise'

const Parametres = () => {
    const [dataBusiness, setDataBusiness] = useState([]);
    const [dataAdmin, setDataAdmin] = useState([]);
    const [dataBoutiques, setDataBoutiques] = useState([]);
    const [dataManager, setDataManager] = useState([]);
    const [dataManagerInfo, setDataManagerInfo] = useState([]);
    const [dataBoutique, setDataBoutique] = useState([]);
    const [loading, setLoading] = useState(true);


    const data = [{ id: 1, prixPartenaire: "10000", prixVente: "10000", fraisLivraison: "10000", totalPaye: "10000", commission: "10000", stockActuel: "10", stockGlobal: "10", code: "10", commissionDaymond: 2000, description: "Ceci est la première ligne de données." }];
    const token = localStorage.getItem('sessionDataToken');
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
    const role = sessionData.option.is_admin;
    // const id_user = sessionData.user.id;

    // Initialiser la première boutique du compte
    useEffect(() => {
        if (dataBoutiques.length > 0) {
            changeBoutique(dataBoutiques[0].id);
            accountService.dataBoutique(JSON.stringify(dataBoutique));
        }
    }, [dataBoutiques]);
    // Recupéré la boutique
    const changeBoutique = async (boutique) => {
        let selectedShop = boutique
        Axios.get(`supplier/shop/${selectedShop}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response1) => {
                console.log("Operation GET effectuée!", response1.data);
                setDataBoutique(response1.data.data);
                accountService.dataBoutique(JSON.stringify(dataBoutique));
            })
            .catch(error => console.log(error));
    }

    //Recupérer toutes les boutiques reliés au compte
    useEffect(() => {
        Axios.get("supplier/shop", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                console.log("Operation 2 effectuée!", response.data);
                setDataBoutiques(response.data.data);
            })
            .catch(error => console.log(error));
    }, []);

    // Récupérer les informations relative au manager ou gérant de la boutique de l'entreprise
    useEffect(() => {
        Axios.get("supplier/manager", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response1) => {
                console.log("Operation 3 effectuée!", response1.data.data);
                setDataManager(response1.data.data)
            })
            .catch(error => console.log(error));
    }, []);

    // Récupérer les informations relative au propietaire du compte et à l'entreprise
    useEffect(() => {
        Axios.get("supplier/business", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response1) => {
                console.log("Operation 5 effectuée!", response1.data.data);
                setDataBusiness(response1.data.data.business)
                setDataAdmin(response1.data.data.admin)
                setLoading(false);
                console.log("business", response1.data.data.business)

            })
            .catch(error => console.log(error));
    }, []);

    // Fonction pour afficher le modal avec l'ID du gerant capturé
    const handleShowModal = (itemId) => {
        console.log('data', itemId);
        Axios.get("supplier/manager/" + itemId, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response1) => {
                console.log("Operation 4 effectuée!", response1.data.data);
                setDataManagerInfo(response1.data.data)
            })
            .catch(error => console.log(error));
    };

    if (loading) {
        return <LoadSpinnerTwo />
    }
    return (
        <div>
            <div className="card text-start rounded">
                <div className="card-header bg-white">
                    <div className="card-title">
                        <div className="d-flex">
                            <div className="h3 text-uppercase">Paramètres</div>
                            {role === 0 ? "" : <NavLink to="ajouter/boutique" className="btn btn-outline-secondary add-produit">Ajouter une boutique</NavLink>}
                        </div>
                    </div>
                </div>

                <div className="card-body" style={{ backgroundColor: "#F5F6FB" }}>
                    <div className="card py-2">
                        <div className="text-start">
                                <div className="row gap-3 ms-3 align-items-center">
                                            {/* Info Entreprise */}
                                    <div className="col-md-4 card custom-card p-3" data-bs-toggle="modal" data-bs-target="#infoEntreprise" role='button'>
                                        <div className="card-text fw-semibold text-reduit mb-3">Info entreprise</div>
                                        <div className="d-flex align-items-center">
                                            {/* <div className="col-3"> */}
                                                <img src={dataBusiness?.logo === null ? ImageDefault : dataBusiness?.logo} width={85} className=" rounded-circle d-flex align-items-center justify-content-center mr-3" alt="..." />
                                            {/* </div> */}
                                            <div className=''>
                                                <div className='fs-6 fw-semibold'>{dataBusiness?.name}</div>
                                                <div className='text-wrap'>Fournisseur : {dataBusiness?.ncc} </div>
                                                <div className='date'>Inscrit le {dataBusiness?.created_at_fr}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Info Propriétaire --> */}
                                    <div className="col-md-4 card custom-card p-3" data-bs-toggle="modal" data-bs-target="#infoProprio" role='button'>
                                        <div className="card-text fw-semibold text-reduit mb-3">Info propriétaire</div>
                                        <div className="d-flex align-items-center gap-1">
                                            {/* <div className="col-4"> */}
                                                <img src={dataAdmin?.profile === null ? ImageDefault : dataAdmin?.profile} width={65} className="img-fluid rounded-5" alt="..." />
                                            {/* </div> */}
                                            <div className="">
                                                <div class="card-title mb-1">{dataAdmin?.first_name} {dataAdmin?.last_name} </div>
                                                <div  class="card-text text-muted">{dataAdmin?.phone_number}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 d-flex flex-column align-items-end justify-content-end right">
                                        <div class="w-100">
                                            <small class="text-muted">Changer de boutique</small>
                                            <div className="dropdown">
                                                <button className="btn btn-outline-secondary border-secondary fw-semibold dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                   {dataBoutique.name}
                                                </button>
                                                <ul className="dropdown-menu">
                                                    {dataBoutiques.map((boutique) => (
                                                        <li><Link className={`dropdown-item ${role === 0 ? "disabled" : ""}`} key={boutique.id} onClick={() => changeBoutique(boutique.id)}>{boutique.name}</Link></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row align-items-stretch">
                            {/* Info boutique */}
                        <div class="col-md-4 mb-4">
                                <div class="card custom-card h-100"  data-bs-toggle="modal" href="#infoBoutique" role="button">
                                <div className="mx-3 text-reduit fw-lighter">Info fournisseur</div>
                                    <div class="card-body d-flex align-items-center gap-1">
                                    <img src={dataBusiness?.logo === null ? ImageDefault : dataBusiness?.logo} width={60} className="rounded-circle mr-3" alt="logo entreprise" />
                                    <div>
                                            <h5 class="card-title mb-1">{dataBoutique.name}</h5>
                                            <p class="card-text text-muted">{dataBoutique.city?.name}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {dataBoutique.managers && dataBoutique.managers.length > 0 ? (
                                dataBoutique.managers.map((item, index) => (
                                    // <!-- Info Gérant Principal -->
                                    <div class="col-md-4 mb-4">
                                        <div class="card custom-card h-100" data-bs-toggle="modal" href="#exampleModalToggle" role="button" onClick={() => handleShowModal(item.id)}>
                                            <div className="text-reduit fw-lighter mx-3">Info gérant</div>
                                            <div class="card-body d-flex align-items-center gap-1">
                                            <img src={item.profile === null ? ImageDefault : item.profile} width={65} className="rounded-circle mr-3" alt="alt" />
                                            <div>
                                                    <h5 class="card-title mb-1">{item.first_name} {item.last_name}</h5>
                                                    <p class="card-text text-muted">{item.phone_number}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            ) : (
                                ""
                            )}
                            {/* <!-- Ajouter un Gérant --> */}
                            <div className="col-md-4 mb-4">
                                <Link className='text-decoration-none' to={`/parametres/ajouter/gerant?shop_id=${dataBoutique.id}&shop_name=${dataBoutique.name}`}>
                                    <div className="card custom-card h-100 d-flex align-items-center justify-content-center">
                                        <div className="text-center gap-1">
                                            <img src={AddButton} width={55} className="rounded-circle" alt="..." />
                                            <p>Ajouter un gérant</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        {role === 1 ? (
                            <div className="row align-items-stretch">
                                <div className="col-md-4 mb-4" data-bs-toggle="modal" data-bs-target="#infoCompte">
                                    <div className='card custom-card h-100'>
                                        <div className="card-body d-flex align-items-center gap-1">
                                            {/* <div className="row"> */}
                                            <div className="icon bg-primary text-white rounded-circle d-flex align-items-center justify-content-center mr-3">
                                                <img src={ComptabiliteIcone} height={49} width={50} className="rounded-5" alt="..." />
                                            </div>
                                            <div>
                                                <small className="card-text fw-semibold">INFORMATION SUR COMPTE</small>
                                                <div className="card-text">{dataAdmin?.phone_number}</div>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    {/* ) : ""} */}

                </div>
            </div>
            {/* Modal pour ajouter un gérant */}
            <ModalInfoGerant
                title="Info Gérant"
                id="exampleModal"
                bodyContent={dataManagerInfo}
                boutonText="Solder"
            ></ModalInfoGerant>
            <ModalInfoCompte
                title="INFORMATION SUR COMPTE "
                id="infoCompte"
                bodyContent={data}
                boutonText="Solder"
            />
            <ModalInfoBoutique
                title="INFO BOUTIQUE"
                id="infoBoutique"
                bodyContent={dataBoutique}
            />
            <ModalInfoProprietaire
                title="INFO PROPRIETAIRE"
                id="infoProprio"
                bodyContent={dataAdmin}
            />

            <ModalInfoEntreprise 
                title="INFO ENTREPRISE"
                id="infoEntreprise"
                bodyContent={dataBusiness}
            />
        </div>
    )
}

export default Parametres