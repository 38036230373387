const saveSessionData = (data) => {
    sessionStorage.setItem('sessionData', data);
};
const dataBoutique = (data) => {
    sessionStorage.setItem('dataBoutiqueSession', data);
};

const getSessionDataToken = (data) => {
    localStorage.setItem('sessionDataToken', data);
};

const logout = () => {
    localStorage.removeItem('sessionDataToken');
    sessionStorage.removeItem('sessionData');
};

const isLogged = () => {
    let sessionDataToken = localStorage.getItem('sessionDataToken');
    let sessionData = sessionStorage.getItem('sessionData');

    return !!sessionDataToken && !!sessionData;
};

export const accountService ={
    saveSessionData, logout, isLogged, getSessionDataToken, dataBoutique
}