import React, { useEffect, useState } from 'react'
import ProduitImage from './../../assets/produitDefault.png';
import { BiEdit } from 'react-icons/bi';
import { LuRefreshCw } from 'react-icons/lu';
import { AiOutlineStop } from 'react-icons/ai';
import { IoArrowBack } from 'react-icons/io5';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import Axios from '../../services/Config';
import ModalEditStock from '../../components/modals/ModalEditStock';
import Swal from 'sweetalert2';
import styleCss from './../../styles/produitDetail.module.css'
const ProduitDetails = () => {
    const navigate = useNavigate();
    const [produitItem, SetProduitItem] = useState([]);
    const [loading, setLoading] = useState(true);
    const [time, setTime] = useState('');
    const token = localStorage.getItem('sessionDataToken');

    const { id } = useParams();
    useEffect(() => {
        Axios.get(`supplier/product/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                console.log("Operation 1 effectuée!", response.data);
                SetProduitItem(response.data.data)
                setLoading(false);
                const time = new Date(produitItem?.created_at).toLocaleTimeString();
                setTime(time);
                //   console.log(time)
            })

            .catch(error => { console.log(error); setLoading(true); });
    }, []);

    const updateProduit = (id) => {
        const data = new FormData();
        data.append('option', 'out_stock');
        Swal.fire({
            icon: "info",
            title: "Etes-vous sûr d'effectuer cette opération ?",
            showDenyButton: true,
            confirmButtonText: "Oui, je confirme",
            denyButtonText: `Annuler`
        }).then((result) => {
            if (result.isConfirmed) {
                Axios.post(`supplier/product/${id}`, data, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(function (response) {
                    console.log("Mis en rupture de stock avec succès : ", response.data);
                    Swal.fire({
                        title: "Opération réussie!",
                        text: "Stock mise à jour avec succès.",
                        icon: "success"
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            //redirection si le client clique sur le bouton "ok";
                            window.location.reload();
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire({
                        title: 'Erreur!',
                        text: 'Erreur lors de la requête d\'enregistrement.',
                        icon: 'error',
                        // confirmButtonText: 'OK'
                    });
                });
            }
        });

    }

    if (loading) {
        return <p><div className="spinner-grow text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
        </div></p>;
    }

    return (
        <div>
            <div className="row">
                <div className='mb-3'>
                    <div className="card">
                        <div className="card-header bg-white">
                            <div className="d-flex gap-3">
                                <div className='col-7 text-start'><IoArrowBack onClick={() => navigate(-1)} /> Aperçu</div>
                                <Link to="/produits/ajouter-produit" className=' add-produit'><div className="btn btn-warning rounded-5 text-light">Ajouter un produit</div></Link>
                            </div>
                        </div>

                        <div className="card-body mt-4 mx-2">
                            <div className="row">
                                <div className="mx-auto">
                                    <ul className="nav justify-content-end gap-3">
                                        <li className="nav-item">
                                            <div className="card status-produit bg-light rounded text-black shadow-sm"><span className='text-reduit'>{produitItem.created_at_fr} <br/>{time === 'Invalid Date' ? "" : time}</span></div>
                                        </li>
                                        <li className="nav-item">
                                        <div className={`status-produit fs-meduim rounded-pill text-white`} id={produitItem.out_stock === 0 ? "overlay3" : "overlay4"}>{produitItem.out_stock === 0 ? "En vente" : "Rupture de stock"}</div>
                                            {/* <div className="badge bg-success rounded-pill text-white text-mini">{produitItem.out_stock === 0 ? "en vente" : "rupture de stock"}</div> */}
                                        </li>
                                    </ul>
                                </div>
                                {/* <span className='badge bg-light text-black text-center me-2'>
                                    <small className='text-decoration-none'>{produitItem.created_at_fr}</small><br />
                                    <small className='text-decoration-none'>{time == 'Invalid Date' ? "" : time}</small>
                                </span> */}                                
                                {/* <div className='col-3'>
                                    <div className='badge bg-success rounded-pill text-white add-produit'>{produitItem.out_stock === 0 ? "en vente" : "rupture de stock"}</div>
                                </div> */}
                            </div>
                            <div className="container text-center mt-3 mb-3">
                                <div className="row row-cols-5 row-cols-lg-5 g-2 g-lg-3">
                                    {produitItem.images && produitItem.images.length > 0 ? (
                                        produitItem.images.map((item, index) => (
                                            <div className="col" key={index}>
                                                <img src={item} width={300} className="img-fluid h-75" alt="..." />
                                            </div>
                                        ))
                                    ) : (
                                        <p>Aucune image disponible.</p>
                                    )
                                    }
                                </div>
                            </div>
                            <div className="container text-start">
                                <div className="row row-cols-3 row-cols-lg-3 g-2 g-lg-3">
                                    <div className="col">
                                        <div className="card"  style={{ height: "500px"}}>
                                            <div className="card-body">
                                                <div className="card-title fw-bold">
                                                    {produitItem.name}, {produitItem.sub_title}
                                                </div>
                                                <div className="card-text fw-semibold">Prix d’en gros</div>
                                                <div className="card-text text-blue mb-2">{produitItem.price.seller || "Prix indisponibles."} </div>
                                                <div className="card-text mb-2">Stock <span className="text-blue fw-semibold">{produitItem.order_stock}</span> / {produitItem.stock} Pieces</div>
                                                <div className="card-text fw-semibold">{produitItem.code}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col ">
                                        <div className="card" style={{ height: "500px", overflow: "auto"}}>
                                            <div className="card-body">
                                                <div className="card-title fw-semibold">Caracteristique</div>
                                                <div className="card-text">
                                                    {produitItem.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card" style={{ height: "500px"}}>
                                            <div className="card-body">
                                                <div className="card-title fw-semibold">Etat du produit : <span className='text-orange'>{produitItem?.state?.name}</span> </div>
                                                <div className="card-title d-flex flex-row mt-4 fw-semibold" >Taille du produit <span id='line-after'></span></div>
                                                <div className="card-text gap-2">
                                                    {/* <span className="badge text-bg-warning  me-2"> */}
                                                    {produitItem.sizes !== null ? (
                                                        <div>{produitItem.sizes.map((size, index) => (
                                                            <small className="badge text-bg-secondary rounded-pill me-1" key={index}>
                                                                {size}
                                                            </small>
                                                        ))
                                                        }
                                                        </div>) : (<p className='text-muted'>Informations sur la taille indisponible.</p>)
                                                    }
                                                </div>
                                                <div className="card-title d-flex flex-row mt-4 fw-semibold" >Couleur du produit <span id='line-after'></span></div>
                                                <div className="card-text gap-2">
                                                    {produitItem.colors === null ? (<p className='text-muted'>Informations sur la couleur indisponible.</p>) : (<span className="badge text-bg-primary me-2">{produitItem.colors}</span>)}
                                                    {/* <span className="badge text-bg-secondary me-2">gris</span>
                                                    <span className="badge text-bg-success me-2">vert</span>
                                                    <span className="badge text-bg-danger me-2">rouge</span>
                                                    <span className="badge text-bg-warning me-2">jaune</span>
                                                    <span className="badge text-bg-info me-2">bleu-ciel</span>
                                                    <span className="badge text-bg-light me-2">gris-clair</span>
                                                    <span className="badge text-bg-dark me-2">Black</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card rounded-top-4 mt-3'>
                            <div className="d-flex flex-row gap-3">
                                <div className='col-4 text-start py-1 mx-1'>
                                    <div className='fs-5'>Paramètre de produits</div>
                                    <small className='text-secondary'>Rapport de modification</small>
                                </div>
                                <div className='col-8 mt-2 mb-3 d-flex justify-content-center align-items-end gap-2'>
                                    <NavLink to={`/produits/modifier-produit/${produitItem.id}`} className={styleCss.btnPrimary}><BiEdit /> Modifier les infos</NavLink>
                                    <div className={styleCss.btnWarning} data-bs-toggle="modal" data-bs-target="#updateStock"><LuRefreshCw /> Mettre à jour le stock</div>
                                    <div className={styleCss.btnSecondary} onClick={() => updateProduit(produitItem.id)}><AiOutlineStop />{produitItem.out_stock === 0 ? " Marqué Stock épuisé" : " Marqué le produit en stock"} </div>

                                    {/* <NavLink to={`/produits/modifier-produit/${produitItem.id}`} className={"btn btn-outline-primary rounded-5 me-2 mb-2"}><BiEdit /> Modifier les infos</NavLink>
                                    <div className="btn btn-outline-warning rounded-5 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#updateStock"><LuRefreshCw /> Mettre à jour le stock</div>
                                    <div className="btn btn-outline-secondary rounded-5 me-2 mb-2" onClick={() => updateProduit(produitItem.id)}><AiOutlineStop />{produitItem.out_stock === 0 ? " Marqué Stock épuisé" : " Marqué le produit en stock"} </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ModalEditStock
                title='Modifier le Stock'
                boutonText="enregistrer"
                id="updateStock"
                idProduit={id}
            />
        </div>
    )
}

export default ProduitDetails