import React from 'react'
import ImageDefault from "./../../assets/ImageDefault.svg"
import { Image, Upload, Form } from 'antd';

const InfoGerantForm = ({
    boutiqueInfo, nextStep, prevStep, handleOnChange, dataCity,
    // Props pour le profil
    profilFileList,
    handleProfilPreview,
    handleProfilChange,
    profilPreviewOpen,
    setProfilPreviewOpen,
    profilPreviewImage,
    setProfilPreviewImage,
    // props pour les pieces
    pieceFileList,
    handlePiecePreview,
    handlePieceChange,
    piecePreviewOpen,
    setPiecePreviewOpen,
    piecePreviewImage,
    setPiecePreviewImage
}) => {
    // Bouton pour uploader (logo)
    const uploadProfilButton = (
        <div>
            <img src={ImageDefault} width={50} className="img-fluid opacity-50" alt="..." />
            <div style={{ marginTop: 8 }}>Upload Logo</div>
        </div>
    );
    // Bouton pour uploader (pieces)
    const uploadPieceButton = (
        <div>
            <img src={ImageDefault} width={50} className="img-fluid opacity-50" alt="..." />
            <div style={{ marginTop: 8 }}>Upload Logo</div>
        </div>
    )
    return (
        <div>
            <div className='mt-3 mb-3' >
                <div className="card-body row">
                    <div className="col-4">
                        <div className="col mb-3 rounded-2" style={{ backgroundColor: "#F5F6FB" }}>
                            <div className="card-body">
                                <div className="card text-center bg-white mb-3">
                                    <div className="card-body">
                                        <Upload
                                            listType="picture-card"
                                            fileList={profilFileList}  // Pour le logo
                                            onPreview={handleProfilPreview}  // Fonction pour preview logo
                                            onChange={handleProfilChange}  // Fonction pour changement logo
                                            beforeUpload={() => false}
                                        >
                                            {profilFileList.length >= 1 ? null : uploadProfilButton}
                                        </Upload>

                                        {/* Prévisualisation pour le logo */}
                                        {profilPreviewImage && (
                                            <Image
                                                wrapperStyle={{ display: 'none' }}
                                                preview={{
                                                    visible: profilPreviewOpen,
                                                    onVisibleChange: (visible) => setProfilPreviewOpen(visible),
                                                    afterOpenChange: (visible) => !visible && setProfilPreviewImage(''),
                                                }}
                                                src={profilPreviewImage}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col p-3 rounded-2" style={{ backgroundColor: "#F5F6FB" }}>
                            <div className="d-flex justify-content-between mb-2">
                                <small>Pièce d'identité</small>
                                <small>2 images</small>
                            </div>
                            <div className="row">
                            <Upload
                      listType="picture-card"
                      fileList={pieceFileList}  // Pour le logo
                      onPreview={handlePiecePreview}  // Fonction pour preview logo
                      onChange={handlePieceChange}  // Fonction pour changement logo
                      beforeUpload={() => false}
                    >
                      {pieceFileList.length >= 2 ? null : uploadPieceButton}
                    </Upload>

                    {/* Prévisualisation pour le logo */}
                    {piecePreviewImage && (
                      <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: piecePreviewOpen,
                          onVisibleChange: (visible) => setPiecePreviewOpen(visible),
                          afterOpenChange: (visible) => !visible && setPiecePreviewImage(''),
                        }}
                        src={piecePreviewImage}
                      />
                    )}
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="text-start border-none">
                            <div className="card-body">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="nom_gerant" name='nom_gerant' placeholder="Nom" value={boutiqueInfo.nom_gerant} onChange={handleOnChange} />
                                    <label htmlFor="nom_gerant">Nom</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="prenom_gerant" name="prenom_gerant" placeholder="Prenoms" value={boutiqueInfo.prenom_gerant} onChange={handleOnChange} />
                                    <label htmlFor="prenom_gerant">prenom</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="cni_gerant" name="cni_gerant" placeholder="N* CNI" value={boutiqueInfo.cni_gerant} onChange={handleOnChange} />
                                    <label htmlFor="cni_gerant">N* CNI</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="email_gerant" name="email_gerant" placeholder="EMAIL" value={boutiqueInfo.email_gerant} onChange={handleOnChange} />
                                    <label htmlFor="email_gerant">EMAIL</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="contact_gerant" name="contact_gerant" placeholder="CONTACT" value={boutiqueInfo.contact_gerant} onChange={handleOnChange} />
                                    <label htmlFor="contact_gerant">CONTACT</label>
                                </div>

                                {/* <div className="form-floating mb-3">
                                    <select className='form-select'  id="ville_gerant" name='ville_gerant' value={boutiqueInfo.city_id} onChange={handleOnChange}>
                                        {dataCity.map((city) => (
                                        <option key={city.id} value={city.id}>{city.name}</option>
                                        ))}
                                    </select>                                    
                                    <label htmlFor="ville_gerant">VILLE/COMMUNE</label>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='float-start'>
                    <button type="button" className='btn btn-secondary' onClick={() => { prevStep() }}>Précédent</button>
                </div>
            </div>
        </div>
    )
}

export default InfoGerantForm