import React, {useEffect, useState} from 'react'
import { ModalHeader } from './modalComponents/ModalHeader'
import orangeMoney from "./../../assets/orange-money.svg"
import MoovMoney from "./../../assets/moov-money.svg"
import Wave from "./../../assets/wave.svg"
import Momo from "./../../assets/momo.svg"
import {  BiCheckCircle, BiPencil, BiPlusCircle } from 'react-icons/bi'
import ModalAddCompteRecharge from './ModalAddCompteRecharge'
import Axios from '../../services/Config'
import { useForm, Controller } from 'react-hook-form'
import Swal from 'sweetalert2'

const dataImage = [{id:1, logo: Momo, operator: "Mtn"}, {id:2, logo: Wave, operator: "Wave"}, {id:3, logo: orangeMoney, operator: "Orange"}, {id:4, logo: MoovMoney, operator: "Moov"}]

export const ModalInfoCompte = ({title}) => {
    
    const [dataNumber, setDataNumber] = useState([]);
    const token = localStorage.getItem('sessionDataToken');
    const [number, setNumber] = useState('');


    useEffect(()=>{
        Axios.get("supplier/phone_number", {
          headers: {
            'Authorization': `Bearer ${token}`
          }})
        .then((response)=> {
            console.log("Operation 1 effectuée!", response.data);
            setDataNumber(response.data)
        })
    
        .catch(error => console.log(error));
      }, []);
    
     const onSave = () => {
        alert("enregistrement effectué")
     }
  // Etat pour gérer quel input est activé
  const [activeInputId, setActiveInputId] = useState(null); // Par défaut, aucun input n'est activé

  // Fonction pour activer un input spécifique par son ID
  const toggleInput = (id) => {
    setActiveInputId(activeInputId === id ? null : id); // Si déjà activé, on désactive
  };

  // Fonction pour mettre à jour la valeur 
  const handleUpdate = ( id, numberOperator ) => {
    // const formData = new FormData();
    // formData.append('phone_number', number);
    // formData.append('operator', numberOperator);
    const data = {
        operator: numberOperator,
        phone_number: number,
      };
    // let data = JSON.stringify(dataUpdate); 

    if(number !== '' ){
    Swal.fire({
        title: "Etes-vous sûr d'effectuer cette opération ?",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: "Oui",
        denyButtonText: `Annuler`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            Axios.put(`supplier/phone_number/${id}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    // 'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (response) {
                console.log("recharge effectué avec succès : ", response.data.message);
                Swal.fire({
                    title: "Opération réussie!",
                    text: "retrait effectué avec succès",
                    icon: "success"
                })
            })
            .catch(error => {
                // Lorsqu'il y a une erreur 
                console.log(error);
                Swal.fire({
                    title: 'Erreur lors de la requête!',
                    text: 'Ressayez plutard s\'il vous plaît.',
                    icon: 'error',
                    // confirmButtonText: 'OK'
                });
            });  
        } else if (result.isDenied) {
          Swal.fire("opération annulée", "", "danger");
        }
      });        
    }
    // alert("Veuillez saisir un numéro valide")
    // return
  };

    return (
        <div>
            <div className="modal fade" id="infoCompte" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <ModalHeader className="d-flex text-center" title={title} />
                        <div className="modal-body">
                            <div className="col mb-3 border rounded-2" style={{ backgroundColor: "#F5F6FB" }}>
                                <div className="card-body p-3">
                                    <div className="card-header bg-none text-start">
                                        <div className="d-flex flex-row mb-2">
                                            <h6>Compte Recharge</h6>
                                            <div className='add-produit fs-5 mb-1' data-bs-toggle="modal" data-bs-target="#addCompteRecharge"><BiPlusCircle></BiPlusCircle></div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {dataNumber.map((number, index) => (
                                            <div className="card-text border mb-2">
                                             <div className='d-flex flex-row p-1'>
                                                 <img src={number.operator === "Orange" ? orangeMoney : (number.operator === "Wave" ? Wave : (number.operator === "Moov" ? MoovMoney : Momo ) )} width={45} className="img-fluid" alt="..." />
                                                 <div className='ms-auto p-2'>
                                                     {/* L'input est désactivé si isDisabled est vrai */}
                                                     {/* <form></form> */}
                                                    <input type="text" onChange={(e) => setNumber(e.target.value)} defaultValue={number.phone_number} disabled={activeInputId !== number.id} className={`form-control border-none ${activeInputId === number.id ? 'bg-white' : 'bg-light'}`} />
                                                 </div>
                                                 {activeInputId !== number.id ? (<BiPencil className='mt-2 mx-2'  onClick={() => toggleInput(number.id)}></BiPencil>) : (<BiCheckCircle className='mt-2 mx-2' onClick={() => handleUpdate(number.id, number.operator)}></BiCheckCircle>)}
                                             </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
                <ModalAddCompteRecharge
                    title="Ajouter un compte recharge"
                    data={dataImage}
                    boutonText="Enregistrer"
                    id="addCompteRecharge"
                    onSave= {onSave}
                />            
        </div>
    )
}
