import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'

const Layout = () => {
    return (
        <div className='layout'>
            <Header/>
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    {/* #F8F8FB */}
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-1 main-bg">
                        <div className="container mt-2">
                            <Outlet></Outlet>
                        </div>
                    </main>
                </div>
            </div>
            
        </div>
    )
}

export default Layout