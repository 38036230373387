import React from 'react'

export const ModalHeader = ({ id, title, style }) => {
    return (
        <div>
            <div className="modal-header" id={style}>
                <h1 className="modal-title fs-5 text-uppercase" id="staticBackdropLabel">{title}</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
        </div>
    )
}
