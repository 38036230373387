import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import { ModalHeader } from './modalComponents/ModalHeader';
import { BiCheckCircle, BiPencil } from 'react-icons/bi';
import Axios from '../../services/Config';
import Swal from 'sweetalert2';

export const ModalInfoBoutique = ({ title, bodyContent, id }) => {
    const [dataCity, setDataCity] = useState([]);
    const token = localStorage.getItem('sessionDataToken');
    const { handleSubmit, register, setValue} = useForm({
        defaultValues: {
            name: '',
            // phone_number: '',
            city_id: '',
            address: '',
        }
    });
    useEffect(() => {
        if (bodyContent.name) {
          setValue('name', bodyContent.name);
          setValue('city_id', bodyContent.city_id);
          setValue('address', bodyContent.address);
    
        }
      }, [bodyContent.name, setValue]);
    const [activeInputId, setActiveInputId] = useState(true); // Par défaut, aucun input n'est activé
    console.log(bodyContent)
    const toggleInput = (id) => {
        setActiveInputId(!activeInputId); // Si déjà activé, on désactive
    };
    // Récupérer les villes
    useEffect(() => {
        //recupérer toutes les boutique relié au compte
        Axios.get("params/data?option=city", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                console.log("Operation 2 effectuée!", response.data);
                setDataCity(response.data.data);
            })
            .catch(error => console.log(error));
    }, []);
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSubmit(onSubmit)(); // Déclenche la validation quand "Enter" est pressé
        }
      };
    // console.log(bodyContent);
    const onSubmit = (data) => {
        // TODO : envoi des données vers le serveur pour la récharge
        console.log(data);
        Swal.fire({
            title: "Etes-vous sûr d'effectuer cette opération ?",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: "Oui",
            denyButtonText: `Annuler`
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Axios.put(`supplier/shop/${bodyContent.id}`,data,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(function (response) {
                        setActiveInputId(true);
                        Swal.fire({
                            title: "Opération réussie!",
                            text: "Modification apporté avec succès.",
                            icon: "success"
                        })

                    })
                    .catch(error => {
                        // Lorsqu'il y a une erreur 
                        console.log("erreur inattendu", error);
                        Swal.fire({
                            title: 'Erreur!',
                            text: 'Erreur lors de la requête de modification.',
                            icon: 'error',
                            // confirmButtonText: 'OK'
                        });
                    });
            } else if (result.isDenied) {
                Swal.fire("Opération annulée", "", "info");
            }
        });
    }
    return (
        <div>
            <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <ModalHeader title={title} />
                        <div className="modal-body">
                            <form onKeyPress={handleKeyPress} >
                                <div className="form-floating mb-3 bg-white">
                                    <input type="text" className="form-control bg-white" id="name" disabled={activeInputId}  {...register("name")} />
                                    <label htmlFor="name">Nom</label>
                                    <div
                                        className="edit-btn d-flex right"
                                        type="button"
                                    >
                                        {activeInputId ? (<BiPencil size={16} onClick={toggleInput} />): ""}
                                        </div>
                                </div>
                                {/* {activeInputId !== bodyContent?.phone_number ? (<BiPencil className='mt-2 mx-2'  onClick={() => toggleInput(bodyContent?.phone_number)}/>) : (<BiCheckCircle className='mt-2 mx-2' onClick={() => onSubmit(bodyContent?.phone_number, bodyContent?.id)}></BiCheckCircle>)} */}

                                {/* <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="phone_number" placeholder={bodyContent?.phone_number} disabled={activeInputId !== bodyContent?.phone_number} />
                                <label htmlFor="phone_number">{bodyContent?.phone_number}</label>
                            </div> */}
                                {/* <div className="form-floating mb-3 bg-white">
                                    <input type="text" className="form-control bg-white" id="city" value={bodyContent?.city?.name} disabled={activeInputId}  {...register("phone_number")}/>
                                    <label htmlFor="city" className=''>Ville/Commune</label>
                                    <div
                                        className="edit-btn d-flex right"
                                        type="button"
                                    >
                                        <BiPencil size={16} onClick={toggleInput} />
                                    </div>
                                </div> */}
                                <div className="form-floating mb-3">
                                    <select className='form-select' id="city_id" name='city_id' disabled={activeInputId} {...register("city_id")} >
                                        <option disabled></option>
                                        {dataCity.map((city) => (
                                            <option key={city.id} value={city.id} selected={city.id === bodyContent?.city?.id}>{city.name}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="city_id">Ville/Commune</label>
                                    {/* {activeInputId ? (<BiPencil size={16} onClick={toggleInput} />): ""} */}
                                </div>
                                <div className="form-floating mb-3 bg-white">
                                    <input type="text" className="form-control bg-white" id="address" disabled={activeInputId}  {...register("address")} />
                                    <label htmlFor="email">Adresse</label>
                                    <div
                                        className="edit-btn d-flex right"
                                        type="button"
                                    >
                                        {activeInputId ? (<BiPencil size={16} onClick={toggleInput} />): ""}
                                        {/* (<BiCheckCircle />) */}
                                    </div>
                                </div>
                                {/* <h6>Dossier</h6>
                            <div className="col p-3 rounded-2 mt-3">
                                <div className="row">
                                    <div className="col">
                                        <p>Logo</p>
                                        <div className="card">
                                            <img src={bodyContent.business?.logo} width={200} className="img-fluid rounded-5" alt="..." />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <p>Registre de commerce</p>
                                        <div className="col card">
                                            <img src={bodyContent.business?.registers_path[0]} width={200} className="img-fluid" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <small className="text-black d-flex justify-content-center align-items-center date">CREER LE {bodyContent.created_at_fr}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
