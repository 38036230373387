import React, {useEffect, useState} from 'react'
import { ModalHeader } from './modalComponents/ModalHeader'
import { ModalFooter } from './modalComponents/ModalFooter'
import { useForm } from "react-hook-form"
import Swal from 'sweetalert2';
import Axios from '../../services/Config';

const ModalAddCompteRecharge = ({ title, data, boutonText, onSave }) => {
    const [selectedOperator, setSelectedOperator] = useState(null);

    const handleOperatorClick = (itemOperator) => {
        setSelectedOperator(itemOperator);
    };
    const { handleSubmit, register, formState: { errors } } = useForm();
    const token = localStorage.getItem('sessionDataToken');
  
      const onSubmit = (data) => {
        if(data.phone_number === data.phone_number_valid){
            if (selectedOperator ) { 
                const formData = new FormData();
                formData.append('phone_number', data.phone_number);
                formData.append('operator', selectedOperator.operator);
                
                for (let [key, value] of formData.entries()) {
                    console.log(`${key}: ${value}`);
                }  

                Axios.post('supplier/phone_number', formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                        // 'Content-Type': 'multipart/form-data',
                    }
                })
                .then(function (response) {
                    console.log("Ajouter avec succès : ", response.data);
                    Swal.fire({
                        title: "Opération réussie!",
                        text: "Numéro mobile ajouter avec succès",
                        icon: "success"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            //redirection si le client clique sur le bouton "ok";
                            window.location.reload();
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire({
                        title: 'Erreur!',
                        text: 'Erreur lors de la requête d\'enregistrement.',
                        icon: 'error',
                        // confirmButtonText: 'OK'
                    });
                });        
            } else {
            alert('Seletionnez un opérateur mobile SVP!!');
            }
        }else{
            alert("SVP veuillez saisir les même numéros !")
        }

      };

    return (
        <div>
            <div className="modal fade" id="addCompteRecharge" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="modal-content">
                        <ModalHeader className="d-flex text-center" title={title} />
                        <div className="modal-body">
                            {/* <div className="col mb-3 border rounded-2" style={{ backgroundColor: "#F5F6FB" }}> */}
                            <div className="card-body p-3">
                                <div className="card-header bg-none text-start">
                                    <p>Selectioner l'opérateur</p>
                                </div>
                                <div className="card-body">
                                    <div className="card-title">
                                    <div className="row">
                                        {data.map((itemOperator) => (
                                            <div key={itemOperator.id} className="col-3">
                                            <div
                                                className={`card ${selectedOperator?.id === itemOperator.id ? 'border-primary' : ''}`}
                                                onClick={() => handleOperatorClick(itemOperator)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img
                                                src={itemOperator.logo}
                                                className="card-img-top"
                                                width={50}
                                                height={50}
                                                alt={itemOperator.logo}
                                                style={{ objectFit: 'contain', marginTop: '10px' }}
                                                />
                                                <div className="card-body text-center">
                                                <p className="card-text">{itemOperator.operator}</p>
                                                </div>
                                            </div>
                                            </div>
                                        ))}
                                    </div>
                                    </div>
                                    <div className="card-text mt-3">
                                    <div className="form-floating mb-3">
                                        <input type="tel" className="form-control" id="phoneNumber" defaultValue="+225" {...register("phone_number", { required: "Ce champs est réquis" })}/>
                                        <label htmlFor="numberMoney" className='text-lowercase'>SAISISSEZ LE NUMERO MOBILE MONEY AVEC L'INDICATIF</label>
                                        <small>{errors.phone_number && <p role="alert" className="text-danger">{errors.phone_number.message}</p>}</small>
                                        </div>
                                        <div className="form-floating">
                                        <input type="tel" className="form-control" id="phoneNumber" defaultValue="+225"  {...register("phone_number_valid", { required: "Ce champs est réquis" })}/>
                                        <label htmlFor="floatingPassword" className='text-lowercase'>SAISISSEZ A NOUVEAU LE NUMERO MOBILE MONEY AVEC L'INDICATIF</label>
                                        <small>{errors.phone_number_valid && <p role="alert" className="text-danger">{errors.phone_number_valid.message}</p>}</small>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="modal-footer d-flex justify-content-center">
                            <button type="submit" className="btn btn-primary align-items-center w-75">{boutonText}</button>
                        </div>
                    </div> 
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ModalAddCompteRecharge