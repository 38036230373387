import React from 'react'
import { ModalHeader } from './modalComponents/ModalHeader'
import ImageDefault from './../../assets/image.png'
import { BiPowerOff } from 'react-icons/bi';

const ModalInfoProprietaire = ({ title, bodyContent, id }) => {
function logout() {
    localStorage.removeItem('sessionDataToken');
    sessionStorage.removeItem('sessionData');
    window.location.reload();
}

  return (
    <div>
        <div className="modal fade" id={id} aria-hidden="true" aria-labelledby={id} tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <ModalHeader title={title} />
                        <div className="modal-body">
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="first_name" placeholder={bodyContent?.first_name} disabled/>
                                <label htmlFor="first_name" className='d-flex'>Nom <span className='marge-label'></span> {bodyContent?.first_name}</label>
                            </div>  
                            <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="last_name" placeholder={bodyContent.last_name} disabled/>
                                        <label htmlFor="last_name">Prenoms <span className='marge-label'></span>{bodyContent.last_name} </label>
                            </div>  
                            <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="number" placeholder={bodyContent.identity?.number} disabled/>
                                        <label htmlFor="number">Nom <span className='marge-label'></span>{bodyContent.identity?.number}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="phone_number" placeholder={bodyContent.phone_number} disabled/>
                                <label htmlFor="phone_number">Numero <span className='marge-label-long'></span>{bodyContent.phone_number}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="address" placeholder={bodyContent?.address} disabled/>
                                <label htmlFor="address">Adresse <span className='marge-label-long'></span>{bodyContent?.address}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="email" placeholder={bodyContent.email} disabled/>
                                <label htmlFor="email">Email <span className='marge-label-long'></span>{bodyContent.email}</label>
                            </div>  
                            <h6>Dossier</h6>   
                            <div className="col p-3 rounded-2 mt-3">
                                <div className="row">
                                    <div className="col">
                                        <p>Photo de profil</p>
                                        {/* card rounded-circle */}
                                        <div className="">
                                            <img src={bodyContent.profile === null ? ImageDefault : bodyContent.profile} width={200} className="img-fluid rounded-circle" alt="..." />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <p>Pièce d'identité</p>
                                        <div className="col card">
                                            <img src={bodyContent.identity?.pictures[0]} width={200} className="img-fluid" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>               
                        </div>
                        <div className="modal-footer">
                            <small className="text-black me-auto">CREER LE {bodyContent.business?.created_at_fr}</small>
                            <button className="btn btn-sm btn-outline-light text-danger" onClick={()=>logout()}><span className='icon'><BiPowerOff /></span>Déconnexion</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default ModalInfoProprietaire
{/* <li>
<span  className="nav-link text-dark d-flex align-items-center gap-2 mt-1" role='button'>
    
</span>
</li> */}