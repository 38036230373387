import React, { useState, useEffect } from 'react'
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import Box from './../../assets/box.svg';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './../../styles/OrderTracking.css';// Pour les styles personnalisés
import Axios from '../../services/Config';
import Swal from 'sweetalert2';
import { Popover } from 'antd';
import chevron from "./../../assets/chevron.svg"
import { LoadSpinnerTwo } from '../../components/LoadSpinnerTwo';

const CommandeDetails = () => {
    const [option, setOption] = React.useState(''); // État pour stocker la valeur de l'option du modal
    const navigate = useNavigate();
    const [dataOrders, setOrders] = useState([]);
    const token = localStorage.getItem('sessionDataToken');
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const handleOptionChange = (id, nouvelleValeur) => {
        // const value = nouvelleValeur
        // setOption(value);
        // console.log(id)
        Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        if (nouvelleValeur === "canceled") {
            try {
                const { value: formValues } = Swal.fire({
                    title: "<div class='border-start border-3 mb-2'>Motif de l'annulation</div>",
                    html: `
                <input 
                  name="motif" 
                  id="motif" 
                  class="form-control mb-4 shadow-sm" 
                  placeholder="Selectionner les motifs" 
                  required
                >
                <textarea 
                  name="description" 
                  rows="10" 
                  cols="50" 
                  id="description" 
                  class="form-control mb-4 shadow-sm" 
                  placeholder="Enseigner les motifs de l'annulation" 
                  required
                ></textarea>
              `,
                    focusConfirm: true,
                    showCancelButton: true,
                    confirmButtonText: 'Confirmer',
                    cancelButtonText: 'Annuler',
                    preConfirm: () => {
                        const motif = document.getElementById("motif").value;
                        const description = document.getElementById("description").value;

                        if (!motif || !description) {
                            Swal.showValidationMessage('Veuillez remplir tous les champs');
                            return false;
                        }

                        return { motif, description };
                    }
                });

                if (formValues) {
                    // Afficher un loading pendant la requête
                    Swal.fire({
                        title: 'Traitement en cours...',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });

                    const response = Axios.put(
                        `supplier/order?status=${nouvelleValeur}`,
                        formValues
                    );

                    if (response && response.status === 200) {
                        Swal.fire({
                            icon: 'success',
                            title: "L'opération a été un succès",
                            showConfirmButton: true
                        });
                        window.location.reload();
                    } else {
                        throw new Error('Erreur lors de la mise à jour');
                    }
                }
            } catch (error) {
                console.error("Erreur rencontrée:", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: error.response?.data?.message || error.message || "Une erreur est survenue",
                    showConfirmButton: true
                });
            }
        } else {
            Axios.put(`supplier/order/${id}?status=${nouvelleValeur}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })

                .then((response) => {
                    console.log("Opération 3 effectuées!", response.data);
                    // setDataOrder(response.data.data)
                    window.location.reload();
                })

                .catch(error => {
                    console.log(error);
                    Swal.fire({
                        title: 'Erreur!',
                        text: 'Erreur lors de la requête de modification du statut.',
                        icon: 'error',
                        // confirmButtonText: 'OK'
                    });
                });
        }

    }

    useEffect(() => {
        Axios.get(`supplier/order/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                console.log("Operation 1 effectuée!", response.data);
                setOrders(response.data.data)
                setLoading(false);
            })

            .catch(error => { console.log(error); setLoading(true); });
    }, []);

    const showModal = (event) => {
        const value = event.target.value
        setOption(value);

        if (value === "Annulee") {
            //openModal(); // Ouvrir le modal si une option est sélectionnée
            alert("ok")
        }
    }

    // Étapes du suivi
    const steps = [
        { label: 'Nouvelle commande', icon: <FaCheckCircle />, key: 'confirm' },
        { label: 'En attente', icon: <FaCheckCircle />, key: 'pending' },
        { label: 'Validé', icon: <FaCheckCircle />, key: 'validated' },
        { label: 'Annulé', icon: <FaTimesCircle />, key: 'canceled' }
    ];

    const getStepStatus = (stepKey) => {
        const stepOrder = ['confirm', 'pending', 'validated', 'canceled'];
        const currentStepIndex = stepOrder.indexOf(dataOrders.status);
        const stepIndex = stepOrder.indexOf(stepKey);
        
        // Si la commande est annulée
        if (dataOrders.status === 'canceled') {
          if (stepKey === 'canceled') return 'canceled';
          // Marque toutes les étapes jusqu'à "validated" comme complétées
          return stepIndex <= stepOrder.indexOf('validated') ? 'completed' : 'inactive';
        }
    
        // Pour toutes les autres étapes
        if (currentStepIndex === -1) return 'inactive';
        
        // Si nous sommes à l'étape actuelle
        if (stepIndex === currentStepIndex) {
          // Pour "validated", marquer les étapes précédentes comme complétées
          if (stepKey === 'validated') {
            return 'current';
          }
          return 'current';
        }
        
        // Si c'est une étape précédente
        if (stepIndex < currentStepIndex) {
          return 'completed';
        }
        
        // Marquer automatiquement les étapes précédentes comme complétées
        if (dataOrders.status === 'validated' && stepIndex < stepOrder.indexOf('validated')) {
          return 'completed';
        }
        
        // Pour les étapes futures
        return 'inactive';
      };
    
      const getProgressWidth = () => {
        const stepOrder = ['confirm', 'pending', 'validated', 'canceled'];
        const currentStepIndex = stepOrder.indexOf(dataOrders.status);
        if (currentStepIndex === -1) return '0%';
        
        const totalSteps = stepOrder.length - 1;
        if (dataOrders.status === 'canceled') {
          return `${(2 / totalSteps) * 100}%`;
        }
        
        // Si en cours de livraison, inclure aussi les étapes précédentes
        if (dataOrders.status === 'pending') {
          return `${(2 / totalSteps) * 100}%`;
        }
        
        return `${(currentStepIndex / totalSteps) * 100}%`;
      };
    
      const getCanceledBarWidth = () => {
        if (dataOrders.status !== 'canceled') return '0%';
        const stepOrder = ['confirm', 'pending', 'validated', 'canceled'];
        const totalSteps = stepOrder.length - 1;
        return `${(1 / totalSteps) * 100}%`;
      };
    
      const getCanceledBarPosition = () => {
        const stepOrder = ['confirm', 'pending', 'validated', 'canceled'];
        const totalSteps = stepOrder.length - 1;
        return `${(2 / totalSteps) * 100}%`;
      };

    const francCFAFormatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'XOF',
        minimumFractionDigits: 0,
    });

    const imprimInvoice = () => {
        alert("Impossible d'imprimer une facture pour l'instant. Veuillez vous adresser à l'administrateur.");
        // window.print();
    }

    if(loading){
        return <LoadSpinnerTwo/>
      }
    return (
        <div className='mt-3'>
            <div className="card rounded-3 thick-border mb-3 shadow-sm " id='card-border'>
                <div className="card-header bg-white d-flex flex-row">
                    <span className=''>
                        {/* revenir en arrière */}
                        <IoArrowBack onClick={() => navigate(-1)} className='fs-4 me-3' />
                        Nouvelle Commande
                    </span>
                    <span className='btn btn-outline-warning' id='btn-imprimer' onClick={() => { imprimInvoice() }}>
                        Imprimer une facture
                    </span>
                </div>
                <div className="card-body" style={{ backgroundColor: "#F5F6FB" }}>
                    {dataOrders.items?.map((item) => (

                        <div className="card" >
                            <div className="card-body gap-2">
                                <div className='row mb-3 gap-4'>
                                    <div className="col-sm-5 col-md-8 col-lg-4 border">
                                        <div className='text-start'>
                                            <img src={item.product.images[0]} width={75} height={75} className="rounded float-start d-sm-block d-md-block" alt="..." />
                                            <div className='mx-auto d-block'>{item.product.name}</div>
                                            <div>Stock {item.product.order_stock} / {item.product.stock} Pieces</div>
                                            <small className='date'>{item.product.code}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 col-md-4 col-lg-7 border">
                                        <div className='d-flex flex-row justify-content-center align-items-center p-1'>
                                            <div className="col-7">
                                                <div className="row">
                                                    <div className="col-4 border-end">
                                                        <img src={Box} width={70} alt="carton de livraison" />
                                                    </div>
                                                    <div className="col-8 text-start">
                                                        <h6>TYPE DE LIVRAISON</h6>
                                                        <div>A Domicile</div>
                                                        <small>N* {item.reference}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Select pour modifier le statut de la commande */}
                                            <div className="col-4">
                                                <div className="d-flex flex-column justify-content-center align-items-center p-1">
                                                    <span className='text-uppercase text-reduit text-start'>Changer le statut</span>
                                                    <span className="fw-bold text-success">
                                                        <Popover
                                                            placement="bottom"  // Placement du popover
                                                            title={<span className='text-center fw-lighter'>Marquer la commande</span>}  // Titre du popover
                                                            content={
                                                                <div className=''>
                                                                    <div className={`relative inline-flex items-center justify-between-center border-select px-2 py-2 rounded-md text-light bg-orange mb-2`}
                                                                        onClick={() => handleOptionChange(dataOrders.id, "confirm")}
                                                                        hidden={dataOrders.status === "confirm" ? true : false} selected={dataOrders.status === "confirm" ? true : false}
                                                                    >Nouvelle Commande</div>
                                                                    <div className={`relative inline-flex items-center justify-between border-select px-2 py-2 rounded-md text-light bg-purple mb-2`}
                                                                        onClick={() => handleOptionChange(dataOrders.id, "pending")}
                                                                        hidden={dataOrders.status === "pending" ? true : false}
                                                                    >En attente</div>
                                                                    <div className="relative inline-flex items-center justify-between border-select px-2 py-2 rounded-md text-light bg-primary mb-2"
                                                                        onClick={() => handleOptionChange(dataOrders.id, "in_progress")}
                                                                        hidden={dataOrders.status === "in_progress" ? true : false}
                                                                    >En cours de livraison</div>
                                                                    <div className="relative inline-flex items-center justify-between border-select px-2 py-2 rounded-md text-light bg-green mb-2"
                                                                        onClick={() => handleOptionChange(dataOrders.id, "validated")}
                                                                        hidden={dataOrders.status === "validated" ? true : false}
                                                                    >Commande livrée</div>
                                                                    <div className="relative inline-flex items-center justify-between border-select px-2 py-2 rounded-md text-light bg-danger mb-2"
                                                                        onClick={() => handleOptionChange(dataOrders.id, "canceled")}
                                                                        hidden={dataOrders.status === "canceled" ? true : false}
                                                                    >Commande annulées</div>
                                                                </div>
                                                            }  // Contenu du popover
                                                            trigger="click"  // Le popover s'affiche sur le clic
                                                        >
                                                            <div className={`relative inline-flex items-center justify-between border-select px-2 py-2 rounded-md text-light ${dataOrders.status === "validated" ? "bg-green" : (dataOrders.status === "pending" ? "bg-purple" : (dataOrders.status === "in_progress" ? "bg-primary" : (dataOrders.status === "canceled" ? "bg-danger" : (dataOrders.status === "confirm" ?? "bg-orange"))))} rounded-3`}>
                                                                <span>
                                                                    {dataOrders.status === "validated" ? "Commande livrée" : (dataOrders.status === "pending" ? "En attente" : (dataOrders.status === "in_progress" ? "En cours de livraison" : (dataOrders.status === "canceled" ? "commande annulée" : (dataOrders.status === "confirm" ?? "Nouvelle commande"))))}
                                                                </span>
                                                                <img src={chevron} className='ms-2' alt="select-picker" />
                                                            </div>
                                                        </Popover >
                                                        {/* <select name={`select-${dataOrders.id}`} id={`select-${dataOrders.id}`} value={option?.[dataOrders.id]} className={`form-select fs-6 rounded-3`} onChange={
                                                            (e) => {
                                                                const nouvelleValeur = e.target.value;
                                                                setOption((prevState) => ({
                                                                    ...prevState,
                                                                    [dataOrders.id]: nouvelleValeur,
                                                                }));
                                                                // console.log(option);
                                                                handleOptionChange(dataOrders.id, nouvelleValeur);
                                                            }
                                                        }>
                                                            <option value="confirm"
                                                                disabled={dataOrders.status === "confirm" ? true : false}
                                                                selected={dataOrders.status === "confirm" ? true : false}
                                                            >Nouvelle Commande</option>
                                                            <option value="pending"
                                                                // disabled={dataOrders.status==="pending" ? true : false}
                                                                selected={dataOrders.status === "pending" ? true : false}
                                                            >En attente</option>
                                                            <option value="in_progress"
                                                                // disabled={dataOrders.status ==="in_progress"  ? true : false}
                                                                selected={dataOrders.status === "in_progress" ? true : false}
                                                            >En cours de livraison</option>
                                                            <option value="validated"
                                                                // disabled={dataOrders.status === "validated" ? true : false}
                                                                selected={dataOrders.status === "validated" ? true : false}
                                                            >Livrées</option>
                                                            <option value="canceled"
                                                                disabled={dataOrders.status === "canceled" ? true : false}
                                                            // selected={dataOrders.status==="pending" ? true : false}
                                                            >Annulées</option>
                                                        </select> */}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="d-flex justify-content-between align-items-center my-1">
                                                <div className="order-tracker">
                                                    <div className="progress-container">
                                                        {/* Progress Bar Background */}
                                                        <div className="progress-bar-bg" />

                                                        {/* Active Progress Bar */}
                                                        <div
                                                            className="progress-bar-active"
                                                            style={{ width: getProgressWidth() }}
                                                        />
                                                              {/* Canceled Progress Bar (Red) */}
                                                                {dataOrders.status === 'canceled' && (
                                                                <div 
                                                                    className="progress-bar-canceled"
                                                                    style={{ 
                                                                    width: getCanceledBarWidth(),
                                                                    left: getCanceledBarPosition()
                                                                    }}
                                                                />
                                                                )}
                                                        {/* Steps */}
                                                        {steps.map((step, index) => {
                                                            const status = getStepStatus(step.key);
                                                            return (
                                                                <div key={step.key} className={`step-container ${status}`}
                                                                    style={{ 
                                                                    flex: index === 0 ? '0 1 auto' : 
                                                                          index === steps.length - 1 ? '0 1 auto' : '1 1 auto'
                                                                  }}
                                                    
                                                                >
                                                                    <div className="icon-container">
                                                                        {step.icon}
                                                                    </div>
                                                                    <div className="step-label-container">
                                                                        <span className="step-label">{step.label}</span>
                                                                        {/* <span className="step-date">Aujourd'hui à 10:12</span> */}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-row gap-2'>
                                    <div className="card card-none col-sm-3 col-md-6 col-lg-6">
                                        <div className="card-body d-flex flex-column justify-content-start">
                                            <div className="card-text bg-white text-center text-uppercase">Détail de la commande</div>
                                            <hr />
                                            <div className='content-card'><p className='right'>Taille du produit </p><span className='left'>{item.size === null ? "Aucune taille" : item.size}</span></div>
                                            <div className='content-card'><p className='right'>Couleur du produit </p><span className='left'>{item.color === null ? "Aucune couleur" : item.color}</span></div>
                                            <div className='content-card'><p className='right'>Quantité </p><span className='left'>{item.quantity}</span></div>
                                            <div className='content-card'><p className='right'>Prix d'achat </p><span className='left'>{francCFAFormatter.format(item.price)}</span></div>
                                            <div className='content-card'><p className='right'>Livraison à <b>{dataOrders.delivery.city.name}</b></p><span className='left'>{francCFAFormatter.format(item.fees)}</span></div>
                                            <hr />
                                            <div className='content-card fw-semibold'><p className='right'>MONTANT A PAYER</p><span className='left text-blue'>{francCFAFormatter.format(item.total)}</span></div>
                                        </div>
                                    </div>
                                    <div className="card col-sm-3 col-md-6 col-lg-6">
                                        <div className="card-body d-flex justify-contend-start flex-column ">
                                            <div className="card-text bg-white text-center text-uppercase">Détail de la livraison</div>
                                            <hr />
                                            <div className='content-card'><p className='right'>Nom </p><span className='left'>{dataOrders.client?.name}</span></div>
                                            <div className='content-card'><p className='right'>Contact </p><span className='left'>{dataOrders.client?.phone_number !== "" ? dataOrders.client?.phone_number : dataOrders.client?.phone_number_2}</span></div>
                                            <div className='content-card'><p className='right'>Lieu de livraison</p><span className='left'>{dataOrders.delivery?.city.name}</span></div>
                                            <div className='content-card'><p className='right'>Date de Livraison </p><span className='left'>{dataOrders.confirmed?.date === new Date() ? "aujourd'hui," : dataOrders.confirmed?.date} à {dataOrders.confirmed.time}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CommandeDetails