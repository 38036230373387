/**
 * 
 * page de connexion du fournisseur
 */
import { useForm } from "react-hook-form"
import Bouton from "../components/Bouton";
import Logo from "../assets/logo.svg"
import { Link, useNavigate } from "react-router-dom";
import { accountService } from "../services/Account.service";
import Axios from "../services/Config";
import { useState } from "react";

const Login = () => {

    const { handleSubmit, register, formState: { errors } } = useForm();
    const [isChecked, setIsChecked] = useState(false);
    const [message, setMessage] = useState('');

    // Fonction pour gérer le changement de la checkbox
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Inverse l'état actuel
        console.log(isChecked);
    };
    const navigate = useNavigate();
    // const onSubmit = (data) =>{
    //  console.log(data);   
    //  navigate('/accueil');
    // } 

    // Fonction pour gérer le formulaire de connexion
    const onSubmit = async (data) => {

        await Axios.post('auth/supplier/login', data)
            .then(function (response) {
                console.log("Connexion réussi...", response.data.data.token);
                // Sauvegarde du token de session dans le local storage
                accountService.getSessionDataToken(response.data.data.token);
                // Sauvegarde des données de session dans le session storage
                accountService.saveSessionData(JSON.stringify(response.data.data));
                console.log("result: " +  response.data.data)
                navigate('/accueil')
            })
            .catch(error =>{
                console.log('Erreur d\'authentification')
                setMessage(error.response?.data?.message)
                // alert("tesr", error.response.data.message);
            });
    }

    return (
        <div className="login-section min-vh-100">
            <div className="container-fluid">
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
                    <div className="col-md-3 mb-2 mb-md-0">
                        <a href="/" className="d-inline-flex link-body-emphasis text-decoration-none">
                            <img src={Logo} alt="Logo" className="logo" />
                        </a>
                    </div>
                    <h2 className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 text-light">
                        Bienvenue CHEZ DAYMOND
                    </h2>
                    <div className="col-md-3 text-end">
                    </div>
                </header>
                <p className="text-light fs-5">Connectez-vous à votre compte</p>
                <div className="row d-flex justify-content-center mx-0">
                    <div className="card col-6 mt-2" style={{ backgroundColor: '#D9D9FF', marginBottom: '25px' }}>
                        <div className="p-3">
                            {message ? (<div className="alert alert-danger mt-3">{message}</div>) : "" }
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group py-3 mb-3 text-start">
                                    <label htmlFor="email">Entrez votre adresse email:</label>
                                    {/* defaultValue={"supplier2@nomail.com"}  */}
                                    <input type="email" id="email" className="form-control form-control-lg" placeholder="Email" {...register("email", { required: true })} aria-invalid={errors.email ? "true" : "false"} />
                                    <small>{errors.email && <p role="alert" className="text-danger">Email est réquis</p>}</small>
                                </div>

                                <div className="form-group mb-3 text-start">
                                    <label htmlFor="motDePasse">Entrez votre mot de passe:</label>
                                    {/* defaultValue={"password"} */}
                                    <input type="password" id="motDePasse" className="form-control form-control-lg" placeholder="Mot de passe" {...register("password", { required: "le mot de passe est réquis" })} />
                                    <small>{errors.password && <p role="alert" className="text-danger">{errors.password.message}</p>}</small>
                                </div>
                                <div className="d-flex mb-3 mx-5">
                                    <div className="left">
                                    <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="checkbox" checked={isChecked} onChange={handleCheckboxChange} id="flexCheckDefault" />
                                    </div>
                                    </div>
                                    <div className="right">J'accepte <Link to={"/conditions-generales"}>les conditions générales d'utilisation</Link></div>

                                </div>
                                <div className="form-group mb-3 mt-3">
                                    <Bouton disabled={!isChecked} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
