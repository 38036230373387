import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import Axios from '../../services/Config'
import { DeviceFormat } from '../../components/DeviceFormat'
import "../../styles/commande.css"
import { formatHeureMinute } from '../../components/FormatDate'
import chevron from "./../../assets/chevron.svg"
import { Popover } from 'antd'
import { LoadSpinnerTwo } from '../../components/LoadSpinnerTwo'


const Commande = () => {

  const [option, setOption] = useState(null);
  const [dataOrder, setDataOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Page courante
  const [totalPages, setTotalPages] = useState(1); // Total des pages
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem('sessionDataToken');
  const [activeButton, setActiveButton] = useState('');

  const fetchOrders = (queryParam) => {
    // statut de la commande qui s'effectuera dans la query param
    Axios.get("supplier/order?status=" + queryParam, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

      .then((response) => {
        console.log("Operation 1 effectuée!", response.data);
        setLoading(false);
        setDataOrder(response.data.data)
        setCurrentPage(response.data.current_page); // Mettre à jour la page actuelle
        setTotalPages(response.data.last_page); // Mettre à jour le nombre total de pages
      })

      .catch(error => console.log(error));
  }

  useEffect(() => {
    fetchOrders('');
  }, []);
  // Gérer le clic sur "Précédent"
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Gérer le clic sur "Suivant"
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const steps = [
    { name: 'Tout', status: '' },
    { name: 'Nouvelle commande', status: 'confirm' },
    { name: 'En attente', status: 'pending' },
    { name: 'En cours de livraison', status: 'in_progress' },
    { name: 'Livrées', status: 'validated' },
    { name: 'Annulées', status: 'canceled' },
  ];

  // const selectContent = () => {
  //   <div>
  //     {steps.map((step, index) => (
  //       <div
  //         className={`custom-btn ${step.status === activeButton? 'active' : ''}`}
  //         key={index}
  //         onClick={() => {
  //           setActiveButton(step.status);
  //           fetchOrders(step.status);
  //         }}
  //       >
  //         {step.label}
  //       </div>
  //     ))}
  //   </div>
  // }

  const getStatusButton = (status) => {
    const statusClasses = {
      confirm: 'btn-primary',
      pending: 'btn-warning',
      in_progress: 'btn-info',
      validated: 'btn-success',
      canceled: 'btn-danger'
    };
    return statusClasses[status] || 'btn-light';
  };

  const handleOptionChange = (id, nouvelleValeur) => {
    // const value = nouvelleValeur
    // setOption(value);
    // console.log(id)
    Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    if (nouvelleValeur === "canceled") {
        const { value: formValues } = Swal.fire({
          title: "<div class='border-start border-3 mb-2'>Motif de l'annulation</div>",
          html: `
            <input 
              name="motif" 
              id="motif" 
              class="form-control mb-4 shadow-sm" 
              placeholder="Selectionner les motifs" 
              required
            >
            <textarea 
              name="reason" 
              rows="10" 
              cols="50" 
              id="reason" 
              class="form-control mb-4 shadow-sm" 
              placeholder="Enseigner les motifs de l'annulation" 
              required
            ></textarea>
          `,
          focusConfirm: true,
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
            customClass: {
                // title: 'text-warning',  // Classe CSS personnalisée pour le titre
                confirmButton: 'btn btn-danger text-white'  // Classe CSS personnalisée pour le bouton
            },
          preConfirm: () => {
            const motif = document.getElementById("motif").value;
            const reason = document.getElementById("reason").value;
            if (!motif || !reason) {
              Swal.showValidationMessage('Veuillez remplir tous les champs');
              return false;
            }else{
              console.log({motif, reason})
              Axios.put(`supplier/order/${id}?status=${nouvelleValeur}`, {motif, reason},{
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              })
        
                .then((response) => {
                  console.log("Opération 3 effectuées!", response.data);
                  // setDataOrder(response.data.data)
                  window.location.reload();
                })
        
                .catch(error => {
                  console.log(error);
                  Swal.fire({
                  title: 'Erreur!',
                  text: 'Erreur lors de la requête de modification du statut.',
                  icon: 'error',
                  // confirmButtonText: 'OK'
                  });
                });
            }
            
            // return { motif, reason };
            // alert(motif);
          }
        });      

    } else {
      Axios.put(`supplier/order/${id}?status=${nouvelleValeur}`,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

        .then((response) => {
          console.log("Opération 3 effectuées!", response.data);
          // setDataOrder(response.data.data)
          window.location.reload();
        })

        .catch(error => {
          console.log(error);
          Swal.fire({
          title: 'Erreur!',
          text: 'Erreur lors de la requête de modification du statut.',
          icon: 'error',
          // confirmButtonText: 'OK'
          });
        });
    }

  }

  if(loading){
    return <LoadSpinnerTwo/>
  }
  return (
    <div>
      <div className='mt-3'>
        <div className="card rounded thick-border">
          <div className="card-header d-flex flex-wrap justify-content-start bg-white">
            {/* iterer la valeur des etapes */}
            {steps.map((step, index) => (
              <div
                className={`custom-btn ${step.status === activeButton ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  setActiveButton(step.status);
                  fetchOrders(step.status);
                }}
              >
                {step.name}
              </div>
            ))}
          </div>

          <div className="card-body" >
            {dataOrder && dataOrder.length > 0 ? (
              dataOrder.map((order, index) => (
                <div className="order-card bg-white p-2 rounded shadow-sm text-black text-decoration-none mb-2" key={index}>
                  <div className="d-flex justify-content-between">
                    {order.items.map((item) => (
                      <>
                        <NavLink to={`/commandes/detail/${order.id}`} key={item.id} className="d-flex flex-row text-black text-decoration-none align-items-center">
                          <span className=""><img src={item.product.images[0]} width={70} height={75} className="border rounded me-3 " alt="..." />
                          </span>
                          <div className='col-3 d-flex flex-column justify-content-start align-items-start'>
                            <div className="product-title fw-semibold">{item.product.name}</div>
                            <small className='text-reduit mb-4'>{item.product.code}</small>
                            <div className='text-date'>{order.confirmed.date === new Date() ? "aujourd'hui," : order.confirmed.date} à {formatHeureMinute(order.confirmed.time)}</div>
                          </div>
                          <div className="col-3 d-flex flex-column justify-content-start align-items-start">
                            <span className="product-title">Prix de vente</span><span className="text-reduit text-dark fw-semibold">{DeviceFormat(item.price)} </span>
                          </div>
                          <div className="col-3 d-flex flex-column justify-content-start align-items-start"><span className="product-title">Frais de livraison</span><span className="text-reduit fw-semibold">{DeviceFormat(item.fees)}</span></div>
                          <div className="col-3 d-flex flex-column justify-content-start align-items-start"><span className="product-title">Total a payer</span><span className="text-reduit text-blue fw-semibold">{DeviceFormat(item.total)}</span></div>
                          <div className="col-3 d-flex flex-column justify-content-start align-items-start"><span className="product-title">Lieux de livraison</span><span className="text-reduit fw-semibold text-blue">{order.delivery.city.name}</span></div>
                        </NavLink>
                        <div className="col-3 d-flex flex-column justify-content-start align-items-start">
                          <span className="text-uppercase text-reduit mb-1">statut</span><span className="fw-bold text-success">
                            <Popover
                              placement="bottom"  // Placement du popover
                              title={<span className='text-center fw-lighter'>Marquer la commande</span>}  // Titre du popover
                              content={
                                <div className=''>
                                  <div className={`relative inline-flex items-center justify-between-center border-select px-2 py-2 rounded-md text-light bg-orange mb-2`}
                                  onClick={()=>handleOptionChange(order.id, "confirm")}
                                  hidden={order.status === "confirm" ? true : false} selected={order.status === "confirm" ? true : false}
                                  >Nouvelle Commande</div>
                                  <div className={`relative inline-flex items-center justify-between border-select px-2 py-2 rounded-md text-light bg-purple mb-2`}
                                  onClick={()=>handleOptionChange(order.id, "pending")}
                                    hidden={order.status === "pending" ? true : false}
                                  >En attente</div>
                                  <div className="relative inline-flex items-center justify-between border-select px-2 py-2 rounded-md text-light bg-primary mb-2"
                                  onClick={()=>handleOptionChange( order.id, "in_progress")}
                                    hidden={order.status === "in_progress" ? true : false}
                                  >En cours de livraison</div>
                                  <div className="relative inline-flex items-center justify-between border-select px-2 py-2 rounded-md text-light bg-green mb-2"
                                  onClick={()=>handleOptionChange(order.id, "validated")}
                                    hidden={order.status === "validated" ? true : false}
                                  >Commande livrée</div>
                                  <div className="relative inline-flex items-center justify-between border-select px-2 py-2 rounded-md text-light bg-danger mb-2"
                                  onClick={()=>handleOptionChange(order.id, "canceled")}
                                    hidden={order.status === "canceled" ? true : false}
                                  >Commande annulées</div>
                                </div>
                              }  // Contenu du popover
                              trigger="click"  // Le popover s'affiche sur le clic
                            >
                              <div className={`relative inline-flex items-center justify-between border-select px-2 py-2 rounded-md text-light ${order.status === "validated" ? "bg-green" : (order.status === "pending" ? "bg-purple" : (order.status === "in_progress" ? "bg-primary" : (order.status === "canceled" ? "bg-danger" : (order.status === "confirm" ?? "bg-orange"))))} rounded-3`}>
                                <span>
                                  {order.status === "validated" ? "Commande livrée" : (order.status === "pending" ? "En attente" : (order.status === "in_progress" ? "En cours de livraison" : (order.status === "canceled" ? "commande annulée" : (order.status === "confirm" ?? "Nouvelle commande"))))}
                                </span>
                                <img src={chevron} className='ms-2' alt="select-picker" />
                              </div>
                            </Popover >
                          </span>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              ))
            ) : (<h5 className='empty-position'>Aucune commande disponible</h5>)}
            {/* pagination bar */}
            {dataOrder.length >= 20 ? (
              <nav className="d-flex justify-content-center">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={handlePrevPage}>
                      &laquo;
                    </button>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={handleNextPage}>
                      &raquo;
                    </button>
                  </li>
                </ul>
              </nav>
            ) : (" ")}
          </div>
        </div >
      </div >
    </div >
  )
}

export default Commande