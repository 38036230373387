import React from 'react'

export const PageNotFund = () => {
  return (
    <div>
        <h1> Page Non trouvé</h1>
        <p>La page que vous recherchez n'existe pas.</p>
        <h2 className='text-center'>404</h2>
    </div>
  )
}
