import React from 'react'
import { ModalFooter } from './modalComponents/ModalFooter'
import { ModalHeader } from './modalComponents/ModalHeader'
import { DeviceFormat } from '../DeviceFormat'
import { FormatDateHeure } from '../FormatDate'

const ModalDetailTransaction = ({ title, bodyContent, onSave, boutonText }) => {
    return (
        <div>
            <div className="modal fade" id="modalDetailT" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <ModalHeader title={title}></ModalHeader>
                        <div className="modal-body gap-3">
                            <div className="row">
                                <div className="col-6 py-2 text-start">
                                    <div className='d-flex mx-4 flex-column'>
                                        <div className='text-secondary'>Statut</div>
                                    </div>
                                </div>
                                <div className="col-6 py-2 text-end">
                                    <div className='d-flex mx-4 flex-column'>
                                        <h6 className='text-primary'>{bodyContent?.category === "recharge" ? "+" + bodyContent?.category : "- retrait" }</h6>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-6 py-2 text-start">
                                    <div className='d-flex mx-4 flex-column'>
                                        <div className='text-primary'>Montant</div>
                                    </div>
                                </div>
                                <div className="col-6 py-2 text-end">
                                    <div className='d-flex mx-4 flex-column'>
                                        <h6 className='text-secondary'> {DeviceFormat(bodyContent?.amount)}</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6 py-2 text-start">
                                    <div className='d-flex mx-4 flex-column'>
                                        <div className='text-secondary'>Solde après transaction</div>
                                    </div>
                                </div>
                                <div className="col-6 py-2 text-end">
                                    <div className='d-flex mx-4 flex-column'>
                                        <h6 className='text-secondary'>...</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6 py-2 text-start">
                                    <div className='d-flex mx-4 flex-column'>
                                        <div className='text-secondary'>Date & heure</div>
                                    </div>
                                </div>
                                <div className="col-6 py-2 text-end">
                                    <div className='d-flex mx-4 flex-column'>
                                        <h6 className='text-secondary'>{FormatDateHeure(bodyContent?.created_at)}</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6 py-2 text-start">
                                    <div className='d-flex mx-4 flex-column'>
                                        <div className='text-secondary'>Référence</div>
                                    </div>
                                </div>
                                <div className="col-6 py-2 text-end">
                                    <div className='d-flex mx-4 flex-column'>
                                        <h6 className='text-secondary'> {bodyContent?.reference}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ModalFooter onSave={onSave} boutonText={boutonText}></ModalFooter>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetailTransaction