import React from 'react'
import { NavLink } from 'react-router-dom';
import { BiHome } from 'react-icons/bi';
import { BsCart3 } from "react-icons/bs";
import { GrCubes } from "react-icons/gr";
import { CiCalculator1 } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import '../styles/sidebar.css';

const Sidebar = () => {

    return (
        <>
            <div className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-light">
                <div className="offcanvas-md offcanvas-end min-vh-100" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                    <div className="offcanvas-header mt-5">
                        <h5 className="offcanvas-title d-none" id="sidebarMenuLabel">Fournisseur Daymond</h5>
                        <button type="button" className="btn-close text-light" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 marge-gauche overflow-y-auto" style={{position: "fixed"}}>
                        <ul className="nav flex-column mb-auto">
                            <li>
                                <NavLink to="/accueil" className="sidebar-item nav-link d-flex align-items-center gap-2 text-truncate">
                                    <span className='icon'><BiHome /></span>
                                    Tableau de bord
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/commandes" className="sidebar-item nav-link d-flex align-items-center gap-2">
                                    <span className='icon'><BsCart3 /></span>
                                    Commandes
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/produits" className="sidebar-item nav-link d-flex align-items-center gap-2">
                                    <span className='icon'><GrCubes /></span>
                                    Produits
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/comptabilite" className="sidebar-item nav-link d-flex align-items-center gap-2">
                                    <span className='icon'><CiCalculator1 /></span>
                                    Comptabilité
                                </NavLink>
                            </li>
                            <hr className='mx-4 mt-5' />
                            <li>
                                <NavLink to="/parametres" className="sidebar-item nav-link d-flex align-items-center gap-2">
                                    <span className='icon'><IoSettingsOutline /></span>
                                    Paramètre
                                </NavLink>
                            </li>
                        </ul>
                        {/* <hr className="my-3" /> */}
                        <br className='py-2' />
                        <div className="fixed-bottom">
                            
                            <span className="d-flex align-items-end" aria-expanded="false">
                                <strong>Version 0.0.1</strong>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar