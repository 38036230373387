import React, { useEffect, useState } from 'react'
import ModalDetailTransaction from '../../components/modals/ModalDetailTransaction'
import Axios from '../../services/Config'
import { FormatDateHeure } from '../../components/FormatDate'

const Recharge = () => {
  let dataTransaction = [{
    id: 1,
    status: '+ Recharge',
    date: '19/09/2020 18:10',
    montant: 1000,
    soldeApresTrans: 1000,
    typePaiement: 'Orange',
    reference: '4532 1234 5678 9012'
  }]

  const VoirFacture = () => {
    alert('facture N°1');
  }

  const [dataTransactions, setDataTransactions] = useState([]);
  const token = localStorage.getItem('sessionDataToken');

  const [transaction, setTransaction] = useState(null);
  const showDetail = (recharge) => {
    setTransaction(recharge);
    console.log("transaction", transaction);
  }
  useEffect(() => {
    // Promise.all([
    Axios.get("supplier/transactions?category=transaction", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    // Axios.get("supplier/transactions?category=withdrawal", {
    //   headers: {
    //     'Authorization': `Bearer ${token}`
    //   }
    // })   
    // ])

      .then((response1) => {
        console.log("Operation de recharge effectuée!", response1.data);
        setDataTransactions(response1.data.data);
      })

      .catch(error => console.log(error));
  }, []);

  return (
    <div>
      {dataTransactions.map((recharge) =>(
      <div className="card bg-White text-black shadow-sm mb-3" data-bs-toggle="modal" data-bs-target="#modalDetailT" key={recharge.id} onClick={()=>showDetail(recharge)}>
        <div className="row" >
          <div className="col-6 py-4 text-start">
            <div className='d-flex mx-4 flex-column'>
              <div className='text-indigo text-'>{recharge.category === "withdrawal" ? "Retrait" : "Recharge"}</div>
              <div className={recharge.operator === "Wave" ? "fw-bold text-primary" : (recharge.operator === "Mtn" ? "fw-bold text-warning" : (recharge.operator === "Orange" ? "fw-bold text-orange" : "fw-bold text-success"))}>{recharge.operator}</div>
            </div>
          </div>
          <div className="col-6 py-4 text-end">
            <div className='d-flex mx-4 flex-column'>
              <h6 className='text-primary'> {recharge.category === "withdrawal" ? "- " + recharge.amount : "+ " + recharge.amount}</h6>
              <small className='text-secondary date'>{FormatDateHeure(recharge.created_at)}</small>
            </div>
          </div>
        </div>
      </div>
      ))}

      <ModalDetailTransaction
        title="Détail De La Transaction"
        id="modalDetailT"
        bodyContent={transaction}
        boutonText="Voir la facture"
        onSave={VoirFacture}
      />
    </div>
  )
}

export default Recharge