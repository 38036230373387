import React from 'react'
import { accountService } from './Account.service';
import { Navigate } from 'react-router-dom';

export const AuthGuardUser = ({children}) => {

    if(!accountService.isLogged()){
        return <Navigate to="/"/>
    }
  return children
}
