// Formater un objet date selon le format français
// Autres paramètre en dessous pour ajouter plusieurs configuration dans le const "options" 
//, hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short'

export const FormatDateHeure = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
};

export const formatDate = (datetime) => {
    const date = new Date(datetime);
    return date.toLocaleDateString(); // Formate la date selon les paramètres régionaux
};

export const FormatDateNormal = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric'};
    return new Date(dateString).toLocaleDateString('fr-FR', options);
};

export const FormatDateMY = (dateString) => {
    const options = { year: 'numeric', month: 'long'};
    return new Date(dateString).toLocaleDateString('fr-FR', options);
};

export const extractHeure = (datetime) =>{
    // Convertir la chaîne en objet Date
    const date = new Date(datetime);
    
    // Obtenir les heures et les minutes
    const hours = date.getHours().toString().padStart(2, '0'); // Heure avec zéro devant si nécessaire
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Minute avec zéro devant si nécessaire
    
    // Retourner l'heure et les minutes au format HH:mm
    return `${hours}:${minutes}`;
}

export const formatHeureMinute = (time) => {
  // Séparer les heures, minutes et secondes
  const [hours, minutes] = time.split(':');

  // Retourner le résultat sous forme d'objet
  return `${hours}:${minutes}`;

};