import React from 'react'
import { ModalHeader } from './modalComponents/ModalHeader'
import { useForm } from "react-hook-form"
import orangeMoney from "./../../assets/orange-money.svg"
import MoovMoney from "./../../assets/moov-money.svg"
import Wave from "./../../assets/wave.svg"
import Momo from "./../../assets/momo.svg"
import Swal from 'sweetalert2'
import Axios from '../../services/Config'

export const ModalRetraitFinal = ({ titleAndSolde, bodyContent, boutonText }) => {
    const { handleSubmit, register, formState: { errors } } = useForm();
    const token = localStorage.getItem('sessionDataToken');

    const onSubmit = (data) => {
        // console.log("input",data);
        const formData = new FormData();
        formData.append('amount', data.amount);
        formData.append('phone_number', bodyContent?.phone_number);
        formData.append('operator', bodyContent?.operator);
        for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
        }
        Swal.fire({
            title: "Etes-vous sûr d'effectuer cette operation ?",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: "Oui",
            denyButtonText: `Annuler`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Axios.post('supplier/payment/withdrawal', formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                        // 'Content-Type': 'multipart/form-data',
                    }
                })
                .then(function (response) {
                    console.log("retrait effectué avec succès : ", response.data);
                    Swal.fire({
                        title: "Opération réussie!",
                        text: "retrait effectué avec succès",
                        icon: "success"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            //redirection si le client clique sur le bouton "ok";
                            window.location.reload();
                        }
                    });
                })
                .catch(error => {
                    // Lorsqu'il y a une erreur 
                    console.log(error);
                    Swal.fire({
                        title: 'Erreur lors de la requête!',
                        text: 'Ressayez plutard s\'il vous plaît.',
                        icon: 'error',
                        // confirmButtonText: 'OK'
                    });
                });  
            } else if (result.isDenied) {
              Swal.fire("opération annulée", "", "danger");
            }
          });
    }

    return (
        <>
            {/* // type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" */}
            <div className="modal fade" id="stepOne" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className='bg-primary text-white text-center'>
                            <ModalHeader title={titleAndSolde}></ModalHeader>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-body">
                                <div className="d-flex flex-column">
                                    <div className="col border rounded mb-3">
                                        <div className="row p-1">
                                            <div className="col-2 text-start">
                                                <div className='d-flex'>
                                                    <img src={bodyContent?.operator === "Orange" ? orangeMoney : (bodyContent?.operator === "Wave" ? Wave : (bodyContent?.operator === "Moov" ? MoovMoney : Momo ) )} alt={bodyContent?.operator} style={{ width: '50px', marginRight: '10px' }} />
                                                </div>
                                            </div>
                                            <div className="col-6 text-end mt-3">
                                                <div className='d-flex mx-4'>
                                                    <h6 className='text-secondary'>
                                                        <strong>{bodyContent?.operator}</strong>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="col-4 d-flex text-end mt-3">
                                                <div className='d-flex justify-content-end'>
                                                    <h6 className='text-secondary'>
                                                        <small>{bodyContent?.phone_number}</small>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-floating mb-3">
                                            <input type="number" className="form-control" id="amount" placeholder="Scanner le code produit" {...register("amount", { required: "Veuillez saisir le montant" })} />
                                            <label htmlFor="amount">Montant a recharger</label>
                                            <small>{errors.amount && <p role="alert" className="text-danger">{errors.amount.message}</p>}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 mb-5">
                                    <button type='submit' className="btn btn-primary text-center w-75">
                                        {boutonText}
                                    </button>
                                </div>                                 
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
