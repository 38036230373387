import React, { useEffect, useState } from 'react'
import Produit from './../../assets/produitDefault.png'
import { Modal } from '../../components/modals/Modal'
import Solder from './Solder'
import NonSolder from './NonSolder'
import Recharge from './Recharge'
import { ModalRetraitCompte } from '../../components/modals/ModalRetraitCompte'
import orangeMoney from "./../../assets/orange-money.svg"
import MoovMoney from "./../../assets/moov-money.svg"
import Wave from "./../../assets/wave.svg"
import Momo from "./../../assets/momo.svg"
import Axios from '../../services/Config'
import ModalRechargeCompte from '../../components/modals/ModalRechargeCompte'
import { DeviceFormat } from '../../components/DeviceFormat'
import Swal from 'sweetalert2'
import "../../styles/comptabilite.css"
import { FormatDateMY } from '../../components/FormatDate'
import { LoadSpinnerTwo } from '../../components/LoadSpinnerTwo'
import { NavLink, Outlet, useLocation } from 'react-router-dom'


const recharge = () => <Recharge />;
const solde = () => <Solder />;
const nonSolde = () => <NonSolder />;

const Comptabilite = () => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const dataAccounts = [{ id: 1, name: "MTN", phone: "07 59 02 85 45", logo: Momo, }, { id: 2, name: "Wave", phone: "07 59 02 85 45", logo: Wave }, { id: 3, name: "Orange", phone: "07 59 02 85 45", logo: orangeMoney }, { id: 4, name: "Moov", phone: "07 59 02 85 45", logo: MoovMoney }]

  const [dataAmount, setDataAmount] = useState([]);
  const token = localStorage.getItem('sessionDataToken');
  const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
  const role = sessionData.option.is_admin;
  const [loading, setLoading] = useState(true);

  const [dataNumber, setDataNumber] = useState([]);

  const location = useLocation();

  // Récupère le chemin actuel
  const currentPath = location.pathname;
  // console.log(currentPath);
  
  useEffect(()=>{
    //Récuperer les numéros enregistrés
      Axios.get("supplier/phone_number", {
        headers: {
          'Authorization': `Bearer ${token}`
        }})
      .then((response)=> {
          console.log("Operation 1 effectuée!", response.data);
          setDataNumber(response.data)
      })
  
      .catch(error => console.log(error));
    }, []);
  
    // Fonction pour solder tout 
   const payAll = () => {
      Swal.fire({
        title: "Etes-vous sûr d'effectuer cette opération ?",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: "Oui",
        denyButtonText: `Annuler`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            Axios.get('supplier/transaction/all/pay', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(function (response) {
                console.log("Paiement effectué avec succès : ", response.data.message);

                Swal.fire({
                    title: "Opération réussie!",
                    text: "Paiement effectué avec succès.",
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        //redirection si le client clique sur le bouton "ok";
                        window.location.reload();
                    }
                });
            })
            .catch(error => {
                console.log(error);
                console.log("data", error.response.data.message, error.status)
                if(error.status === 404){
                  Swal.fire({
                    title: error.response.data.message,
                    text: `Daymond vous remercie pour votre fidelité!`,
                    icon: 'info',
                    // confirmButtonText: 'OK'
                    customClass: {
                        confirmButton: 'btn btn-info text-white'  // Classe CSS personnalisée pour le bouton
                    }
                  });
                }else{
                  Swal.fire({
                      title: 'Erreur lors de la requête!',
                      text: 'Ressayez plutard s\'il vous plaît.',
                      icon: 'error',
                      // confirmButtonText: 'OK'
                  });
                }
            });  
        } else if (result.isDenied) {
          Swal.fire("opération annulée", "", "danger");
        }
      });
   }

   // recuperation des données pour le dashboard de la comptabilité
  useEffect(() => {
    Axios.get("supplier/dashboard?option=apk", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        console.log("Operation 2 effectuée!", response.data);
        setDataAmount(response.data)
        setLoading(false);
        // console.log(dataAmount.wallet.amount)
      })

      .catch(error => console.log(error));
  }, []);

  // // Déclaration de l'état pour gérer la vue active
  // const [activeView, setActiveView] = useState('non-solder');

  // // Fonction pour afficher le composant correspondant
  // const renderComponent = () => {
  //   switch (activeView) {
  //     case 'recharge':
  //       return <Recharge />;
  //     case 'solder':
  //       return <Solder Produit={Produit}  setSelectedTransaction={setSelectedTransaction}/>;
  //     case 'non-solder':
  //       return <NonSolder Produit={Produit} setSelectedTransaction={setSelectedTransaction} />;
  //     default:
  //       return <NonSolder Produit={Produit} setSelectedTransaction={setSelectedTransaction} />;
  //   }
  // };
  if(loading){
    return <LoadSpinnerTwo/>
  }
  return (
    <div >        
      <div className="card" style={{ background: '#0055FF', position: "relative", marginBottom: '10px' }}>
        <div className="card-body text-start mx-3">
          <div className="row g-3">
            <div className="col-sm-3 me-3 card rounded-top text-white" style={{ background: '#0808FC' }}>
              <div className="card-body">
                <div className="card-text">
                  Chiffres d'affaire
                </div>
                <span className="date">{FormatDateMY(new Date())}</span>
                <div className="card-title text-truncate"><h2>{DeviceFormat(dataAmount?.chiffre_affaire)}</h2></div>
                <div className="card-text text-info">À retirer : RAS </div>
              </div>
            </div>
            <div className="col-sm-3 card text-white" style={{ background: '#04389f' }}>
              <div className="card-body">
                <h3 className="card-title text-success">
                  Solde
                </h3>
                <h2>{dataAmount?.solde } <span className='device-money'>FCFA</span></h2>
                <div className="card-text text-orange">
                  Total A Solder
                </div>
                <h2>{DeviceFormat(dataAmount?.a_solder)}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='row text-white text-start'>
          <div className="col-sm-2 col-lg-3 col-md-3 line">
            <div className="card-body">
              <div className="card-title fs-6 text-uppercase">tOTAL Reçu AUJOURD'hui</div>
              <h4 className='card-text'>+ {DeviceFormat(dataAmount?.retrait_jour)}</h4>
            </div>
          </div>
          <div className="col-sm-2 col-lg-3 col-md-3 line">
            <div className="card-body">
              <div className="card-title fs-6 text-uppercase text-truncate">Commission daymond DU JOURS</div>
              <h4 className='card-text'>+ 3{DeviceFormat(dataAmount?.commission_jour)}</h4>
            </div>
          </div>
          <div className="col-sm-2 col-lg-3 col-md-3 line">
            <div className="card-body">
              <div className="card-title fs-6 text-uppercase">Recharge AUJOURD'hui</div>
              <h4 className='card-text'>+ {DeviceFormat(dataAmount?.recharge_jour)}</h4>
            </div>
          </div>
          <div className="col-sm-2 col-lg-3 col-md-3 ">
            {role === 1 ? 
              <div className="d-flex flex-column card-body">
                <div className="btn btn-outline-light btn-sm mt-3 mb-2" data-bs-toggle="modal" data-bs-target="#rechargeCompte">Recharger votre compte</div>
                <div className="btn btn-outline-info btn-sm text-wrap" data-bs-toggle="modal" data-bs-target="#retraitCompte">Retirer de l'argent</div>
              </div>            
             : ""}

          </div>
        </div>
      </div>
      <div className="card card-position">
        <div className="card-body row">
          <div className="col-6 text-start">
          <ul className="nav nav-pills justify-content-start gap-3">
              <li className="nav-item">
                <NavLink to={"/comptabilite/non-solder"} className={`nav-item btn btn-sm btn-outline-primary rounded-5 btn-size ${currentPath === "/comptabilite" && "active"} `} >Non soldés</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={"/comptabilite/solder"} className={`nav-item btn btn-sm btn-outline-primary rounded-5 btn-size`}>Solder</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={"/comptabilite/recharge"} className={`nav-item btn btn-sm btn-outline-primary rounded-5 btn-size`}>Recharge</NavLink>
              </li>
            </ul>

            {/* <ul className="nav nav-pills justify-content-start gap-3">
              <li className="nav-item">
                <div onClick={() => setActiveView('non-solder')} className={`nav-item btn ${activeView === 'non-solder' ? 'btn btn-sm btn-outline-primary rounded-5 active' : ''}`}  >Non soldés</div>
              </li>
              <li className="nav-item">
                <div onClick={() => setActiveView('solder')} className={`nav-item btn ${activeView === 'solder' ? 'btn btn-sm btn-outline-primary rounded-5 active' : ''}`} >Soldés</div>
              </li>
              <li className="nav-item">
                <div onClick={() => setActiveView('recharge')} className={`nav-item btn ${activeView === 'recharge' ? 'btn btn-sm btn-outline-primary rounded-5 active' : ''}`} >Transactions</div>
              </li>
            </ul> */}
          </div>
          <div className="col-3 mt-2">
            <p>Total A Solder : <span className='text-danger'>{DeviceFormat(dataAmount?.a_solder)}</span></p>
          </div>
          
          <div className="col-3 text-end mt-1">
            <button className="btn-solder" onClick={()=> payAll()}>Tout solder</button>
          </div>
        </div>

        <div className="container container-sale mt-2 pt-2">
          {/* {renderComponent()} */}
          <Outlet />
        </div>
      </div>

      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        title={selectedTransaction?.category === "sale_admin" && selectedTransaction?.status === "pending" ? "Non soldé" : "Soldé"}
        style={selectedTransaction?.category === "sale_admin" && selectedTransaction?.status === "pending" ? "custom-modal-header-2" : "custom-modal-header-1"}
        id="exampleModal"
        bodyContent={selectedTransaction}
        boutonText="Solder"
      ></Modal>

      <ModalRetraitCompte
        title="Selectionner un compte"
        boutonText="Valider"
        // onSave={next}
        id="retraitCompte"
        bodyContent={dataNumber}
        amount={dataAmount?.solde}
      ></ModalRetraitCompte>

      <ModalRechargeCompte
        title="Selectionner un compte pour le recharge"
        boutonText="Valider"
        // onSave={next}
        id="rechargeCompte"
        bodyContent={dataNumber}
        amount={dataAmount?.solde}
      ></ModalRechargeCompte>
    </div>
  )
}

export default Comptabilite