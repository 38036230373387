import React, { useState } from 'react'
import { ModalFooter } from './modalComponents/ModalFooter'
import { ModalHeader } from './modalComponents/ModalHeader'
import { ModalRetraitFinal } from './ModalRetraitFinal'
import orangeMoney from "./../../assets/orange-money.svg"
import MoovMoney from "./../../assets/moov-money.svg"
import Wave from "./../../assets/wave.svg"
import Momo from "./../../assets/momo.svg"

export const ModalRetraitCompte = ({ title, bodyContent, amount, id, boutonText }) => {
    const [selectedAccount, setSelectedAccount] = useState(null);

    const handleSelect = (account) => {
        setSelectedAccount(account);
        // console.log(account);
    };

    return (
        <div>
            <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <ModalHeader title={title}></ModalHeader>
                        <div className="modal-body">
                            <div className="container mt-4">
                                <h3 className="text-center">Sélectionner un compte</h3>
                                <ul className="list-group">
                                    {bodyContent.map((account) => (
                                        <li
                                            key={account.id}
                                            className={`list-group-item d-flex align-items-center mb-1 mt-1 rounded-2 shadow-sm ${selectedAccount?.id === account.id ? 'bg-info text-white' : ''}`}
                                            onClick={() => handleSelect(account)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="row">
                                                <div className="col-3 text-start">
                                                    <div className='d-flex mx-4'>
                                                        <img src={account.operator === "Orange" ? orangeMoney : (account.operator === "Wave" ? Wave : (account.operator === "Moov" ? MoovMoney : Momo ) )} width={45} className="img-fluid" alt="..." />
                                                        {/* <img src={account.logo} alt={account.name} style={{ width: '50px', marginRight: '10px' }} /> */}
                                                    </div>
                                                </div>
                                                <div className="col-4 text-end">
                                                    <div className='d-flex mx-4'>
                                                        <h6 className='text-secondary'>
                                                            <strong>{account.operator}</strong>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="col-4 d-flex text-end">
                                                    <div className='d-flex justify-content-end'>
                                                        <h6 className='text-secondary'>
                                                            <small>{account.phone_number}</small>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <div className="mt-5">
                                    <button className="btn btn-primary text-center w-75" data-bs-toggle="modal" data-bs-target="#stepOne" disabled={!selectedAccount}>
                                        Validé
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <ModalFooter onSave={handleSelect} boutonText={boutonText} disabled={!selectedAccount}></ModalFooter> */}
                    </div>
                </div>
            </div>
            <ModalRetraitFinal 
                titleAndSolde={"Soldes actuel: " +`${amount}`+" CFA"}
                id="stepOne"
                bodyContent={selectedAccount}
                boutonText="Retrait"
            />
        </div>
    )
}
