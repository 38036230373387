import React, { useState } from 'react'
import { IoArrowBack } from 'react-icons/io5'
import ImageDefault from './../../assets/ImageDefault.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Axios from '../../services/Config';
import { Controller, useForm } from "react-hook-form"
import { Image, Upload, Form } from 'antd';
import { LoadSpinner } from '../../components/LoadSpinner';

const AjouterGerant = () => {
    const { handleSubmit, register, control, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const token = localStorage.getItem('sessionDataToken');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);

    // État pour le 3e upload (Photo de piece)
    const [pieceFileList, setPieceFileList] = useState([]);
    const [piecePreviewOpen, setPiecePreviewOpen] = useState(false);
    const [piecePreviewImage, setPiecePreviewImage] = useState('');

    // Fonction pour gérer le changement de fichiers pour la pièce
    const handlePieceChange = ({ fileList }) => setPieceFileList(fileList);
    // Fonction pour prévisualiser la pièce
    const handlePiecePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await file.originFileObj;
        }
        setPiecePreviewImage(file.url || file.preview);
        setPiecePreviewOpen(true);
    };

      // Obtenir l'objet location
    const location = useLocation();

    // Créer une instance de URLSearchParams en passant location.search
    const queryParams = new URLSearchParams(location.search);

    // Utiliser queryParams.get pour obtenir la valeur d'un paramètre spécifique
    const shop_id = queryParams.get('shop_id');
    const shop_name = queryParams.get('shop_name');
    // console.log("Paramètre : ", shop_name);

    const onSubmit = (data) => {
        setLoading(true);

        const formData = new FormData();
        // Ajout des pièces seulement s'il sont sélectionnées
        if (pieceFileList.length > 0) {
            pieceFileList.forEach((file) => {
                formData.append('identity_doc_picture[]', file.originFileObj); // Envoi du fichier brut
            });
        }
        // Ajout de l'image au FormData
        fileList.forEach((file) => {
            formData.append('picture', file.originFileObj); // Envoi du fichier brut
        });
        // Ajout des autres champs
        formData.append('first_name', data.nom);
        formData.append('last_name', data.prenoms);
        formData.append('email', data.email);
        formData.append('phone_number', data.contact);
        formData.append('address', data.adresse);
        formData.append('identity_doc_no', data.numero_piece);
        formData.append('identity_doc_type', data.type_piece);
        formData.append('shop_id', shop_id);

        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }

        Axios.post('supplier/manager', formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(function (response) {
                console.log("Ajouter avec succès : ", response.data);
                setLoading(false);
                Swal.fire({
                    title: "Opération réussie!",
                    text: "Gérant ajouter avec succès",
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        //redirection si le client clique sur le bouton "ok";
                        navigate('/parametres');
                    }
                });
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
                Swal.fire({
                    title: 'Erreur rencontrée!',
                    text: error.message,
                    icon: 'error',
                    // confirmButtonText: 'OK'
                });
            });
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <img src={ImageDefault} width={50} className="img-fluid opacity-50" alt="..." />
            <div
                style={{
                    marginTop: 8,
                }}
            >
            </div>
        </button>
    );
    // Bouton pour uploader (pieces)
    const uploadPieceButton = (
        <div>
            <img src={ImageDefault} width={50} className="img-fluid opacity-50" alt="..." />
            <div style={{ marginTop: 8 }}>Recto & Verso</div>
        </div>
    )
    return (
        <div>
            <div className='mt-3 mb-3' >
                <div className="card shadow-sm">
                    <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>

                        <div className="card-header bg-white">
                            <div className="d-flex gap-3">
                                <div className="">
                                    <IoArrowBack className='me-2' onClick={() => navigate(-1)} />
                                    Ajouter un gérant ( <b className='color-daymond'>{shop_name}</b> )</div>
                                <div className='add-produit d-flex text-nowrap'>
                                    <button className="btn btn-warning color-daymond text-light" disabled={loading}>
                                        {loading ? ("Chargement...") : "Soumettre A DAYMOND"}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body row">
                            <div className="col-4">
                                <div className="col mb-3 p-2 rounded-2" style={{ backgroundColor: "#F5F6FB" }}>
                                    <div className="card-body">
                                        <div className="card text-center bg-white">
                                            <div className="card-body rounded-pill">
                                                <div className="d-flex justify-content-between">
                                                    <small>Photo de profil</small>
                                                </div>
                                                {/* <div className="row"> */}
                                                    <div className="col d-flex flex-column justify-content-center align-items-center">
                                                        <Controller
                                                            name="pictures"
                                                            control={control}
                                                            rules={{ required: "Veuillez télécharger au moins un fichier" }}
                                                            defaultValue={[]}
                                                            render={({ field, fieldState: { error } }) => (
                                                                <div>
                                                                    <Form.Item
                                                                        validateStatus={errors.pictures ? "error" : ""}
                                                                        help={errors.pictures?.message}
                                                                    ></Form.Item>
                                                                    <Upload
                                                                        listType="picture-card"
                                                                        fileList={fileList}
                                                                        onPreview={handlePreview}
                                                                        beforeUpload={() => false}
                                                                        accept=".png,.jpeg,.jpg"      // Acceptation des fichiers PNG, JPEG, JPG uniquement
                                                                        onChange={({ fileList: newFileList }) => {
                                                                            setFileList(newFileList);  // Mise à jour de la liste des fichiers
                                                                            field.onChange(newFileList);  // Mettre à jour le champ dans react-hook-form
                                                                        }}
                                                                    >
                                                                        {fileList.length >= 1 ? null : uploadButton}
                                                                    </Upload>
                                                                </div>
                                                            )}
                                                        />
                                                        {previewImage && (
                                                            <Image
                                                                wrapperStyle={{
                                                                    display: 'none',
                                                                }}
                                                                preview={{
                                                                    visible: previewOpen,
                                                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                                }}
                                                                src={previewImage}
                                                            />
                                                        )}
                                                    </div>
                                                {/* </div> */}
                                                {/* <div className="card-title"><img src={ImageDefault} width={50} className="img-fluid" alt="..." /></div> */}
                                                {/* <small className="card-text">photo de profil<br /></small> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col p-2 rounded-2" style={{ backgroundColor: "#F5F6FB" }}>
                                    <div className="d-flex justify-content-between mb-2">
                                        <small>PIECES D’IDENTITÉ</small>
                                        <small>2 images (recto et verso)</small>
                                    </div>
                                    <div className="row p-2">
                                        <div className="col d-flex flex-column justify-content-center align-items-center">
                                            <Upload
                                                listType="picture-card"
                                                fileList={pieceFileList}  // Pour le logo
                                                onPreview={handlePiecePreview}  // Fonction pour preview logo
                                                onChange={handlePieceChange}  // Fonction pour changement logo
                                                beforeUpload={() => false}
                                            >
                                                {pieceFileList.length >= 2 ? null : uploadPieceButton}
                                            </Upload>

                                            {/* Prévisualisation pour le logo */}
                                            {piecePreviewImage && (
                                                <Image
                                                    wrapperStyle={{ display: 'none' }}
                                                    preview={{
                                                        visible: piecePreviewOpen,
                                                        onVisibleChange: (visible) => setPiecePreviewOpen(visible),
                                                        afterOpenChange: (visible) => !visible && setPiecePreviewImage(''),
                                                    }}
                                                    src={piecePreviewImage}
                                                />
                                            )}

                                            {/* <p className='text-center'>recto</p> */}
                                        </div>
                                        {/*<div className='col bg-light'>
                                            <div className="">
                                                <Upload
                                                    listType="picture-card"
                                                    fileList={fileListVerso}
                                                    onPreview={handlePreview}
                                                    onChange={handleChangeVerso}
                                                    customRequest={({ file, onSuccess }) => {
                                                        setFileListVerso([file]);
                                                        onSuccess("ok");
                                                    }}
                                                    accept=".png,.jpeg,.jpg"
                                                    maxCount={1}  // Limite la sélection à une seule image
                                                >
                                                    {fileListVerso.length >= 1 ? null : uploadButton}
                                                </Upload>

                                                {/* Prévisualisation de l'image *}
                                                {previewImage && (
                                                    <Image
                                                        style={{ marginTop: 16 }}
                                                        src={previewImage}
                                                        preview={{
                                                            visible: previewOpen,
                                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                                        }}
                                                    />
                                                )} 
                                            </div>
                                            <p>verso</p>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="text-start border-none">
                                    <div className="card-body">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="Nom" {...register("nom", { required: "Ce champs est réquis" })} />
                                            <label htmlFor="floatingInput">Nom</label>
                                            <small>{errors.nom && <p role="alert" className="text-danger">{errors.nom.message}</p>}</small>

                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="prénoms" {...register("prenoms", { required: "Ce champs est réquis" })} />
                                            <label htmlFor="floatingInput">prénoms</label>
                                            <small>{errors.prenoms && <p role="alert" className="text-danger">{errors.prenoms.message}</p>}</small>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="N* de la pièce" {...register("numero_piece", { required: "Ce champs est réquis" })} />
                                            <label htmlFor="floatingInput">N* de la pièce</label>
                                            <small>{errors.numero_piece && <p role="alert" className="text-danger">{errors.numero_piece.message}</p>}</small>

                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="type de pièce" {...register("type_piece", { required: "Ce champs est réquis" })} />
                                            <label htmlFor="floatingInput">type de pièce (cni, passeport...)</label>
                                            <small>{errors.type_piece && <p role="alert" className="text-danger">{errors.type_piece.message}</p>}</small>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="EMAIL" {...register("email", { required: "Ce champs est réquis" })} />
                                            <label htmlFor="floatingInput">EMAIL</label>
                                            <small>{errors.email && <p role="alert" className="text-danger">{errors.email.message}</p>}</small>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="CONTACT" {...register("contact", { required: "Ce champs est réquis" })} />
                                            <label htmlFor="floatingInput">CONTACT</label>
                                            <small>{errors.contact && <p role="alert" className="text-danger">{errors.contact.message}</p>}</small>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="VILLE/COMMUNE" {...register("adresse", { required: "Ce champs est réquis" })} />
                                            <label htmlFor="floatingInput">VILLE/COMMUNE</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AjouterGerant