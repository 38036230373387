import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Produit } from './produits/Produit';
import Axios from '../services/Config';
import { DeviceFormat } from '../components/DeviceFormat';
import { FormatDateMY, FormatDateNormal } from '../components/FormatDate';

import asolder from '../assets/asolder.ico';
import newCmd from '../assets/cmd_new.ico';
import valideCmd from '../assets/cmd_valide.ico';
import CA from '../assets/ca_icone.ico';
import { LoadSpinnerTwo } from '../components/LoadSpinnerTwo';

const Accueil = () => {
  
  const token = localStorage.getItem('sessionDataToken');
  const [dataDashboard, setDataDashboard] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Axios.get("supplier/dashboard?option=apk", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        console.log("Operation 2 effectuée!", response.data);
        setDataDashboard(response.data)
        setLoading(false);
        // console.log(dataAmount.wallet.amount)
      })

      .catch(error => console.log(error));
  }, []);

  let periode = FormatDateNormal(new Date());
  console.log(periode)

  if(loading){
    return <LoadSpinnerTwo/>
  }
  return (
    <div className='mb-3'>
      <div className="row">
        <div className="col-md-3 mb-2">
          <NavLink to={"/comptabilite"} className="card border-primary text-decoration-none">
              <div className="row">
                <div className="col-4" id="card-reduit">
                  <div className="icone-container bg-primary">
                    {/* <CiCalculator1 width={70} className="text-light" /> */}
                    <img src={CA} height={25} className='img-container' alt='a solder' />
                  </div>
                </div>
                <div className="col-8 text-start">
                  <b className="text-five text-primary">{DeviceFormat(dataDashboard.chiffre_affaire)}</b>
                  <div className='text-reduit fw-semibold'>Chiffre d’affaires</div>
                  <div className="text-date">{FormatDateMY(new Date())}</div>
                </div>
              </div>
          </NavLink>
        </div>
        <div className="col-md-3 mb-2">
          <NavLink to={"/comptabilite"} className="card border-danger text-decoration-none">
              <div className="row">
              <div className="col-4" id="card-reduit">
                <span className='icone-container bg-red'>
                  <img src={asolder} height={25} className='img-container' alt='a solder' />
                  {/* <CiCalculator1 size={40} className="text-light" /> */}
                </span>
              </div>
              <div className="col-8 text-start">
                <b className="text-five text-red">{DeviceFormat(dataDashboard.a_solder)}</b>
                <div className='text-reduit fw-semibold'>Total A Solder</div>
                <div className="text-date">Aujourd'hui, {periode}</div>
              </div>
              </div>
          </NavLink>
        </div>
        <div className="col-md-3 mb-2">
          {/* <div className="card border-success"> */}
            <NavLink to={"/commandes"} className="border-green card text-decoration-none" style={{border: "1px solid #04D111"}}>
              <div className="row">
                <div className="col-4" id="card-reduit">
                  <span className="icone-container bg-succes">
                  <img src={valideCmd} height={25} className='img-container' alt='a solder' />
                    {/* <BsCartCheckFill size={40} className="text-light"></BsCartCheckFill> */}
                  </span>
                </div>
                <div className="col-8 text-start">
                  <b className="text-five text-succes">{dataDashboard.nb_commande_validee}</b>
                  <div className='text-reduit fw-semibold'>Commandes livrées</div>
                  <div className="text-date">{FormatDateMY(new Date())}</div>
                </div>
              </div>
            </NavLink>
          {/* </div> */}
        </div>
        <div className="col-md-3 mb-2 ">
          <NavLink to={"/commandes"} className="card border-warning text-decoration-none">
            {/* <div className="card-body"> */}
              <div className="row">
              <div className="col-4" id="card-reduit">
                <span className="icone-container bg-daymond">
                <img src={newCmd} height={25} className='img-container' alt='nouvelles commandes' />
                  {/* <FaCartPlus size={40} className="text-light" /> */}
                </span>
              </div>
              <div className="col-8 text-start" id="marge-null">
                <b className="text-five text-warning">{dataDashboard.nb_nouvelle_commande}</b>
                <div className='text-reduit fw-semibold text-truncate'>Nouvelles commandes</div>
                <div className="text-date">{periode}</div>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
      <div className='mt-3'>
        <Produit/>
      </div>
    </div>
  )
}

export default Accueil