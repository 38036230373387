import React from 'react'
import { BiMenu, BiSearch } from 'react-icons/bi'
// import '../styles/sidebar.css';
import Navbar from './Navbar';
import { Popover } from 'antd';
import CallLogo from '../assets/call.svg'
import Notification from '../assets/serviceClient.svg'
import assistanceImg from '../assets/assistanteImage.svg'
import '../styles/contactService.css';


const Header = () => {

    const whatsappAction = () => {
        window.location.assign("https://wa.me/message/UGPQ3XCY2DXHB1")
    }

    const callAction = () => {
        window.location.assign('tel:+2250506790055')
    }
    const emailAction = () => {
        window.location.assign('mailto:aboubakarbarro23@gmail.com')
    }
    
    const contentCallCenter = (
        <div className=''>
            {/* <div className="contact-container container p-4"> */}
                <div className="contact-content">
                    {/* Header with image and title */}
                    <div className="text-center mb-2">
                        <div className="profile-image-container mb-2">
                            <img
                                src={assistanceImg}
                                alt="Service client"
                                className="profile-image"
                            />
                        </div>
                        <b className="contact-title">CONTACTEZ LE SERVICE</b>
                    </div>

                    {/* Contact buttons */}
                    <div className="button-container">
                        {/* WhatsApp Button */}
                        <button className="btn btn-contact whatsapp-btn d-flex align-items-center w-100 mb-3" onClick={()=>{whatsappAction()}}>
                            <div className="contact-icon me-3">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.4054 3.5C18.1607 1.25 15.1716 0 11.9946 0C5.4375 0 0.101786 5.33571 0.101786 11.8929C0.101786 13.9821 0.648214 16.0179 1.6875 17.8393L0 24L6.30536 22.3393C8.05357 23.2946 9.99107 23.7857 11.9946 23.7857C18.5518 23.7857 23.8875 18.45 23.8875 11.8929C23.8875 8.71607 22.65 5.75 20.4054 3.5ZM11.9946 21.7768C10.2125 21.7768 8.47321 21.3054 6.94821 20.4161L6.58929 20.1964L2.85 21.1875L3.85714 17.5357L3.61071 17.1607C2.63036 15.5714 2.11071 13.7589 2.11071 11.8929C2.11071 6.44464 6.54643 2.00893 12 2.00893C14.6786 2.00893 17.1911 3.07143 19.0714 4.95179C20.9518 6.83214 22.0143 9.34464 22.0143 11.8929C22.0143 17.3411 17.4429 21.7768 11.9946 21.7768ZM17.4161 14.3839C17.1161 14.2339 15.6589 13.5161 15.3857 13.4161C15.1125 13.3161 14.9089 13.2661 14.7054 13.5661C14.5018 13.8661 13.9321 14.5339 13.7554 14.7375C13.5786 14.9411 13.4018 14.9661 13.1018 14.8161C12.8018 14.6661 11.8446 14.3589 10.725 13.3571C9.84643 12.5839 9.25714 11.6339 9.08036 11.3339C8.90357 11.0339 9.0625 10.8714 9.21429 10.7196C9.35 10.5839 9.51071 10.3661 9.66071 10.1893C9.81071 10.0125 9.86071 9.88571 9.96071 9.68214C10.0607 9.47857 10.0107 9.30179 9.93571 9.15179C9.86071 9.00179 9.275 7.54464 9.02143 6.94464C8.77679 6.36429 8.52679 6.44464 8.33929 6.43393C8.1625 6.42321 7.95893 6.42321 7.75536 6.42321C7.55179 6.42321 7.22857 6.49821 6.95536 6.79821C6.68214 7.09821 5.91429 7.81607 5.91429 9.27321C5.91429 10.7304 7.02857 12.1339 7.17857 12.3375C7.32857 12.5411 9.25179 15.5214 12.2143 16.8196C12.9643 17.1446 13.5536 17.3411 14.0143 17.4911C14.7589 17.7411 15.4393 17.7054 15.9786 17.6304C16.575 17.5464 17.7661 16.9071 18.0196 16.2071C18.2732 15.5071 18.2732 14.9071 18.1982 14.7875C18.1232 14.6679 17.9196 14.5929 17.4161 14.3839Z" fill="#25D366" />
                                </svg>
                            </div>
                            Whatsapp
                        </button>

                        {/* Direct Call Button */}
                        <button className="btn btn-contact call-btn d-flex align-items-center w-100 mb-3" onClick={()=>{callAction()}}>
                            <div className="contact-icon me-3">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.01 15.38C18.78 15.38 17.59 15.18 16.48 14.82C16.13 14.7 15.74 14.79 15.47 15.06L13.9 17.03C11.07 15.68 8.42 13.13 7.01 10.2L8.96 8.54C9.23 8.26 9.31 7.87 9.2 7.52C8.83 6.41 8.64 5.22 8.64 3.99C8.64 3.45 8.19 3 7.65 3H4.19C3.65 3 3 3.24 3 3.99C3 13.28 10.73 21 20.01 21C20.72 21 21 20.37 21 19.82V16.37C21 15.83 20.55 15.38 20.01 15.38Z" fill="#007AFF" />
                                </svg>
                            </div>
                            Appel direct
                        </button>

                        {/* Email Button */}
                        <button className="btn btn-contact email-btn d-flex align-items-center w-100" onClick={()=>{emailAction()}}>
                            <div className="contact-icon me-3">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="#FFB900" />
                                </svg>
                            </div>
                            E-mail
                        </button>
                    </div>
                </div>
            {/* </div> */}
        </div>
    );

    return (
        <div className='sticky-top'>
            <header className="navbar bg-white flex-md-nowrap p-0 shadow-sm" data-bs-theme="light">
                <div className="navbar-brand col-md-3 col-lg-2 me-0 px-1 fs-6 text-white" to="/">
                    <Navbar />
                </div>
                <ul className="navbar-nav flex-row mt-3">
                    <li className="nav-item text-nowrap d-md-none">
                        <button className="nav-link px-3 text-warning fs-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSearch" aria-controls="navbarSearch" aria-expanded="false" aria-label="Toggle search">
                            <BiSearch />
                        </button>
                    </li>
                    <li className="nav-item text-nowrap d-md-none">
                        <button className="nav-link px-3 text-warning fs-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <BiMenu />
                        </button>
                    </li>
                </ul>
                <ul className='navbar-nav flex-row mx-4 gap-3'>
                    <li className='nav-item d-none d-md-block '>
                        <div className="search-bar">
                            <input type="text" placeholder="Recherche" />
                            <button type="submit">
                                <img src="https://img.icons8.com/ios-glyphs/30/000000/search--v1.png" alt="Search" />
                            </button>
                        </div>
                        {/* <div className="input-group">
                            <input type="search" className='form-control form-control-lg' aria-describedby="inputGroupPrepend" placeholder='Recherche' />
                            <span className="input-group-text" id="inputGroupPrepend">
                                <button className='btn'><BiSearch className='fs-4' /></button>
                            </span>
                        </div> */}
                    </li>
                    <li className="nav-item d-none mt-2 d-md-block ">
                        <Popover
                            placement="bottom"  // Placement du popover
                            title={<span>Notifications</span>}  // Titre du popover
                            content="Aucune notification disponible."  // Contenu du popover
                            trigger="click"  // Le popover s'affiche sur le clic
                        >
                            <img src={Notification} alt="Logo" className="logo de notif" width={40} height={30} />
                        </Popover>
                    </li>
                    <li className="nav-item d-none mt-2 d-md-block ">
                        <Popover
                            placement="bottom"  // Placement du popover
                            title={<span>Call Center</span>}  // Titre du popover
                            content={contentCallCenter}  // Contenu du popover
                            trigger="click"  // Le popover s'affiche sur le clic
                        >
                            <img src={CallLogo} alt="Logo" className="call-center logo" width={40} height={30} />
                        </Popover>
                    </li>
                </ul>

                <div id="navbarSearch" className="navbar-search w-100 collapse">
                    <div className="input-group mb-1">
                        <input type="search" className='form-control form-control' aria-label="Search" aria-describedby="Search" placeholder='Recherche' />
                        <span className="input-group-text" id="inputGroupPrepend">
                            <button className='btn'><BiSearch className='fs-4' /></button>
                        </span>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header