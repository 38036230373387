import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import Axios from '../../services/Config';
import { DeviceFormat } from '../../components/DeviceFormat';
import "../../styles/produit.css"
import { LoadSpinnerTwo } from '../../components/LoadSpinnerTwo';
// import ProduitImage from './../../assets/produitDefault.png';

export const Produit = () => {

  const [dataProduit, setDataProduit] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Page courante
  const [totalPages, setTotalPages] = useState(1); // Total des pages
  const [loading, setLoading] = useState(true);



  const { Search } = Input; // champs de recherche
  // const onSearch = (value, _e, info) => console.log(info?.source, value);
  // const onSearch = (value) => console.log("valeur", value);

  const token = localStorage.getItem('sessionDataToken');
  // Button de changement de statut pour s'ils sont en stock ou pas.
  const [activeButton, setActiveButton] = useState('inStock');

  const fetchProducts = (out_stock, search = '') => {
    console.log("option", out_stock);
    // let queryParam = out_stock === 1 || 2 ? `out_stock=${out_stock}` : `unavailable=${out_stock}`;
    let queryParam = out_stock;

    if (search) {
      queryParam = `search=${encodeURIComponent(search)}`;
    }
    Axios.get("supplier/product?" + queryParam, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    .then((response) => {
      console.log("Operation 1 effectuée!", response.data);
      setLoading(false);
      setDataProduit(response.data.data)
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
    })

    .catch(error => console.log(error));
  }
  useEffect(() => {
    fetchProducts(`out_stock=${0}`);
  }, []);
  // Gérer le clic sur "Précédent"
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Gérer le clic sur "Suivant"
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  if(loading){
    return <LoadSpinnerTwo/>
  }
  return (
    <div>
      <div className="row">
        <div className='mt-3 mb-3'>
          <div className="card">
            <div className="card-header bg-white">
              <div className="d-flex gap-3">
                <button type='button' className={`custom-btn rounded-5 ${activeButton === 'inStock' ? 'active' : ''}`} onClick={() => { fetchProducts(`out_stock=${0}`); setActiveButton('inStock'); }}>En stock</button>
                <button type='button' className={`custom-btn rounded-5 ${activeButton === 'outOfStock' ? 'active' : ''}`} onClick={() => { fetchProducts(`out_stock=${1}`); setActiveButton('outOfStock'); }} >Stock epuisé</button>
                <button type='button' className={`custom-btn rounded-5 ${activeButton === 'unavailable' ? 'active' : ''}`} onClick={() => { fetchProducts(`unavailable=${1}`); setActiveButton('unavailable'); }} >Inactifs</button>
                <Link to="/produits/ajouter-produit" className="custom-button rounded-5 text-decoration-none add-produit">Ajouter un produit</Link>
              </div>
            </div>
            <div className="card-body py-4">
              <div className="card-header border rounded">
                <div className="d-flex gap-3">
                  <div>Total : {dataProduit.length}</div>
                  {/* outil de Recherche */}
                  <div className='search-tool'>
                    <Search
                      placeholder="Recherche"
                      onSearch={(value) => fetchProducts(false, value)}
                      style={{
                        width: 200,
                      }}
                    />
                  </div>
                  {/* <div>
                    <select name="" className="form-select rounded-5" id="">
                      <option selected disabled>trier</option>
                      <option value="">2</option>
                      <option value="">1</option>
                    </select>
                  </div> */}
                </div>
              </div>
              <div className="container-fluid produit-content px-3 text-center">
                <div className="row gap-2">
                  { dataProduit.length > 0 && dataProduit ? (
                    dataProduit.map((item, index) => (
                      <Link to={`/produits/details/${item.id}`} className="card text-start text-decoration-none" style={{ width: "180px" }}>
                        <div className="d-flex flex-column">
                          <div className="img-content">
                            <div className={`status-produit fs-meduim rounded-pill text-white`} id={item.out_stock === 0 ? "overlay1" : "overlay2"}>{item.out_stock === 0 ? "En vente" : "Rupture de stock"}</div>
                            <img src={item.images[0]} width={110} height={100} className="card-img-top rounded-2" alt="produit" />
                          </div>
                          <div className="mb-3">
                            <div className="text-name">{item.name}</div>
                            <div className="card-text fw-semibold text-blue">{DeviceFormat(item.price.price)}</div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center py-1">
                            <div className="left text-reduit">Stock <span className='text-blue'>{item.order_stock}</span> / {item.stock} Pieces</div>
                            <img src={item.shop.business.logo} width={40} className='float-right rounded-pill' alt={item.shop.business.name} />
                          </div>
                        </div>
                      </Link>
                    ))                 
                  ) :(<h5 className='d-flex justify-content-center vh-100 margin-element text-muted'>Aucun produit disponible.</h5>)}   

                </div>
              </div>
            </div>
            {dataProduit.length >= 20 ? (
            <nav className="d-flex justify-content-center">
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={handlePrevPage}>
                    &laquo;
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={handleNextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
            ): ""}
            {/* pagination bar */}

          </div>
        </div>
      </div>
    </div>
  )
}
