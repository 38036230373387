/**
 * <p>Cette page permet de modifier un produit existant.</p>
 */

import React, { useEffect } from 'react'
import { useState } from 'react';
import { IoArrowBack } from 'react-icons/io5'
import ImageDefault from './../../assets/ImageDefault.svg'
import { Select, Tag } from 'antd';
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload, Form } from 'antd';
import Swal from 'sweetalert2';
import Axios from '../../services/Config';

const options = [
  {
    value: 'black',
    // name:"noir"
  },
  {
    value: 'white',
    name:"blanc"
  },
  {
    value: 'red',
    // name:"rouge"
  },
  {
    value: 'blue',
    // name:"bleu"
  },
  {
    value: 'green',
    // name:"verte"
  },
  {
    value: 'royalblue',
    // name:"bleu foncé"
  },
  {
    value: 'pink',
    // name:"rose"
  },
  {
    value: 'skyblue',
    // name:"bleu ciel"
  },
  {
    value: 'orange',
    // name:"orange"
  },
  {
    value: 'yellow',
    // name:"jaune"
  },
  // {
  //   value: 'red',
  //   name:"rouge"
  // },
];


const tailleOptions = [
  // Tailles de vêtements classiques
  "XXS", "XS", "S", "SM", "M", "L", "XL", "XXL", "2XL", "3XL", "4XL", "5XL",
  // Tailles pour accessoires (chaussures, chapeaux, montres)
  "S/M", "M/L", "L/XL",

  // Tailles pour accessoires électroniques (ordinateurs, tablettes, etc.)
  "9 pouces", "10 pouces", "11 pouces", "12 pouces", "13 pouces", "14 pouces", "15 pouces", "17 pouces", "18 pouces", "20 pouces",
  "4 pouces", "4.7 pouces", "5 pouces", "5.5 pouces", "5.8 pouces", 
  "6 pouces", "6.1 pouces", "6.2 pouces", "6.3 pouces", "6.4 pouces", 
  "6.5 pouces", "6.7 pouces", "6.8 pouces", "7 pouces", "7.2 pouces",
  // Modèles de téléphone avec des suffixes
  "Mini", "Pro", "Pro Max", "Ultra", "Plus", "Max",

  // Tailles de téléphone spécifique (pour d'autres caractéristiques)
  "Taille compacte", "Taille standard", "Grand format",

  // Tailles de pantalons / jeans
  "28", "30", "32", "34", "36", "38", "40", "30L", "32L", "34L", "36L",

  // Chaussures (international)
  "6", "6.5", "7", "7.5", "8", "8.5", "9", "10", "11", "12",
  "39", "40", "41", "42", "43", "44",
  "5", "6", "7", "8", "9", "10",

  // // Tailles pour bébés/enfants
  // "0-3 mois", "3-6 mois", "6-12 mois", "12-18 mois", "18-24 mois", 
  // "2T", "3T", "4T",

  // Autres options spécifiques
  "One Size", "Taille unique", "Taille enfant", "Taille adulte",
  "Petit", "Moyen", "Grand", "Standard", "Large", "Extra Large",

  // Classifications basées sur la taille ou le volume
  "Petite", "Moyenne", "Grande", "Très grande",
];

const ModifProduit = () => {

  const [selectedItems, setSelectedItems] = useState([]);
  const filteredOptions = tailleOptions.filter((o) => !selectedItems.includes(o));
  const [defaultFileList, setDefaultFileList] = useState([]);


  const { handleSubmit, register, control, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };


  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    );
  };
  const token = localStorage.getItem('sessionDataToken');
  const [produitItem, SetProduitItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(()=>{
    Axios.get(`supplier/product/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }})
    .then((response)=> {
        console.log("Operation 1 effectuée!", response.data);
        SetProduitItem(response.data.data)
        setLoading(false);
        const files = response.data.data.images.map((file, index)=>({
        uid: index.toString(),   // Identifiant unique pour chaque fichier
        name: `image-${index + 1}`,  // Nom arbitraire ou basé sur un modèle
        status: 'done',          // Statut du fichier ('done' pour indiquer qu'il est déjà téléchargé)
        url: file                 // URL du fichier
        }))
        setDefaultFileList(files); // Mettre à jour le state

        console.log(defaultFileList)
    })

    .catch(error => {console.log(error); setLoading(true);});
  }, []);
  // console.log(token);
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const onSubmit = (data) => {

    const formData = new FormData();

    formData.append('alias', data.alias);
    formData.append('colors[]', data.colors);
    formData.append('description', data.description);
    formData.append('name', data.name);
    formData.append('price_partner', data.price_partner);
    formData.append('price_supplier', data.price_supplier);
    formData.append('sizes[]', data.sizes);
    formData.append('state_id', data.state_id);
    formData.append('option','product');
    formData.append('price', produitItem.price?.price);
    fileList.forEach((file) => {
      formData.append('images[]', file.originFileObj); // Envoi du fichier brut
    });

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    Axios.post(`supplier/product/${id}`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(function (response) {
        console.log("Ajouter avec succès : ", response.data);
        Swal.fire({
          title: "Opération réussie!",
          text: "Produit ajouté avec succès.",
          icon: "success"
        }).then((result) => {
          if (result.isConfirmed) {
            //redirection si le client clique sur le bouton "ok";
            navigate('/produits');
          }
        });
      })
      .catch(error => {
        console.log(error);
        Swal.fire({
          title: 'Erreur!',
          text: 'Erreur lors de la modification du projet',
          icon: 'error',
          // confirmButtonText: 'OK'
        });
      });
  }

  // Récupérer les villes
  const [dataState, setDataState] = useState([]);
  useEffect(() => {
    //recupérer toutes les boutique relié au compte
    Axios.get("params/data?option=state", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        console.log("Operation 2 effectuée!", response.data);
        setDataState(response.data.data);
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <div>
      <div className='mt-3 mb-3'>
        <div className="card">
          <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
            <div className="card-header bg-white">
              <div className="d-flex gap-3">
                <div className="">
                  <IoArrowBack className='me-2' onClick={()=> navigate(-1)}/>
                  Modifier un produit</div>
                <div className='add-produit d-flex text-nowrap'>
                  {/* <div className="btn btn-outline-warning rounded-5 me-2">Scanner</div> */}
                  {/* validation du formulaire */}
                  <button className="btn btn-warning rounded-5 text-light">Enregistrer</button>
                </div>
              </div>
            </div>
            <div className="card-body row">
              {/* formulaire d'ajout */}
              <div className="col-4">
                <div className="card" style={{ backgroundColor: "#F5F6FB" }}>
                  <div className="card-body">
                    <div className="card text-center bg-white mb-3">
                      <div className="card-body">
                        <div className="card-title"><img src={ImageDefault} width={50} className="img-fluid" alt="..." /></div>
                        <small className="card-text">Image principale <br /> 5 images minimum</small>
                      </div>
                    </div>
                    {/* composant ant pour télécharger les image */}
                    <Upload
                      listType="picture-card"
                      multiple
                      // fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      beforeUpload={() => false}
                      fileList={defaultFileList} 
                    >
                      {fileList.length >= 5 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                      />
                    )}
                    <div style={{ marginTop: 16, display: 'flex', alignItems: "center" }}>
                      {previewImage && (
                        <Image
                          wrapperStyle={{
                            display: 'none',
                          }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                          }}
                          src={previewImage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="card text-start border-none">
                  <div className="card-body">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" id="floatingInput1" placeholder="Scanner le code produit" disabled value={produitItem.code}/>
                      <label htmlFor="floatingInput1">Code produit</label>
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="floatingInput2" placeholder="Nom du produit" value={produitItem.name} {...register("name")} />
                          <label htmlFor="floatingInput2">Nom du produit</label>
                          {/* <small>{errors.name && <p role="alert" className="text-danger">{errors.name.message}</p>}</small> */}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="floatingInput3" placeholder="Marque" value={produitItem.alias} {...register("alias")} />
                          <label htmlFor="floatingInput3">Marque</label>
                          {/* <small>{errors.alias && <p role="alert" className="text-danger">{errors.alias.message}</p>}</small> */}

                        </div>
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <textarea type="text" className="form-control" placeholder="Information du produit ici" id="floatingTextarea4" value={produitItem.description} {...register("description")}></textarea>
                      <label htmlFor="floatingTextarea4">Information du produit</label>
                      {/* <small>{errors.description && <p role="alert" className="text-danger">{errors.description.message}</p>}</small> */}
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <div className="form-floating mb-3">
                          <select className='form-select' id="floatingInput5" name='state' value={produitItem?.state?.name} {...register("state_id")}>
                            {dataState.map((state) => (
                              <option key={state.id} value={state.id}>{state.name}</option>
                            ))}
                          </select>
                          <label htmlFor="floatingInput5">Etat du produit</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating">
                          <input type="number" className="form-control" id="floatingInput6" placeholder="quantité en stock" value={produitItem.stock} {...register("stock")} />
                          <label htmlFor="floatingInput6">quantité en stock</label>
                          {/* <small>{errors.stock && <p role="alert" className="text-danger">{errors.stock.message}</p>}</small> */}

                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col text-start">
                        <label htmlFor="">Taille du produit</label>
                        <Controller
                          name="sizes"
                          control={control}
                          rules={{ required: "Veuillez sélectionner au moins une option" }}
                          value={produitItem.sizes}
                          render={({ field }) => (
                            <div>
                              <Form.Item
                                validateStatus={errors.sizes ? "error" : ""}
                                help={errors.sizes?.message}
                              >
                                <Select
                                  mode="multiple"
                                  // placeholder="Inserted are removed"
                                  // value={selectedItems}
                                  onChange={setSelectedItems}
                                  style={{
                                    width: '100%',
                                    height: '50px',
                                  }}
                                  {...field}
                                  options={filteredOptions.map((item) => ({
                                    value: item,
                                    label: item,
                                  }))}
                                />
                              </Form.Item>
                            </div>
                          )}
                        />
                      </div>
                      <div className="col">
                        <div className='text-start'>
                          <label htmlFor="">Couleur du produit</label>
                          <Controller
                            name="colors"
                            control={control}
                            rules={{ required: "Veuillez sélectionner au moins une couleur" }}
                            value={produitItem.colors}
                            render={({ field }) => (
                              <Form.Item
                                validateStatus={errors.colors ? "error" : ""}
                                help={errors.colors?.message}
                              >
                                <Select
                                  mode="multiple"
                                  tagRender={tagRender}
                                  style={{ width: '100%', height: '50px', background: 'skyBlue' }}
                                  options={options}
                                  {...field}
                                />
                              </Form.Item>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="floatingInput7" placeholder="Prix officiel du marché" value={produitItem.price?.supplier} {...register("price_supplier")} />
                          <label htmlFor="floatingInput7">Prix officiel du marché</label>
                          {/* <small>{errors.price_supplier && <p role="alert" className="text-danger">{errors.price_supplier.message}</p>}</small> */}

                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="floatingInput8" placeholder="Prix partenaire Daymond" value={produitItem.price?.partner} {...register("price_partner")} />
                          <label htmlFor="floatingInput8">Prix partenaire Daymond</label>
                          {/* <small>{errors.price_partner && <p role="alert" className="text-danger">{errors.price_partner.message}</p>}</small> */}
                        </div>
                      </div>
                    </div>
                    {/* <small className='text-muted fw-lighter'>Cela me permet de proposer des prix homologué et compétitifs sur le marché </small> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModifProduit
// import React from 'react'

// const ModifProduit = () => {
//   return (
//     <div>
//         <h1>Modification d'un produit</h1>
        
//     </div>
//   )
// }

// export default ModifProduit