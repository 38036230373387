import React, { useEffect } from 'react'
import { ModalHeader } from './modalComponents/ModalHeader'
import ImageDefault from './../../assets/image.png'
import Swal from 'sweetalert2'
import Axios from '../../services/Config'
import { useForm } from 'react-hook-form'

const ModalInfoGerant = ({ title, bodyContent }) => {
    const token = localStorage.getItem('sessionDataToken');
    const { handleSubmit, register, setValue } = useForm({
        defaultValues: {
            first_name: bodyContent.first_name || '',
            last_name: bodyContent.last_name || '',
            phone_number: bodyContent.phone_number || '',
            email: bodyContent.email || '',
            identity_doc_no: bodyContent.identity?.number || '',
            identity_doc_type: bodyContent.identity?.type || ''
        }
    });

    const disableAccess = (id)=>{
        // console.log('You are not allowed to', id)
        Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        Swal.fire({
            title: "Etes-vous sûr d'effectuer cette opération ?",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: "Oui",
            denyButtonText: `Annuler`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Axios.put(`supplier/manager/${id}/locked`)
                .then(function (response) {
                    console.log("operation de désactivation effectuée avec succès : ", response.data.message);
                    Swal.fire({
                        title: "Opération réussie!",
                        text: "Opération de désactivation effectuée avec succès.",
                        icon: "success"
                    })
                })
                .catch(error => {
                    // Lorsqu'il y a une erreur 
                    console.log(error);
                    Swal.fire({
                        title: 'Erreur lors de la requête!',
                        text: 'Ressayez plutard s\'il vous plaît.',
                        icon: 'error',
                        // confirmButtonText: 'OK'
                    });
                });  
            } else if (result.isDenied) {
              Swal.fire("opération annulée", "", "danger");
            }
          });  
    }
 // Mettre à jour la valeur si `bodyContent.first_name` change après le premier rendu
 useEffect(() => {
    if (bodyContent.first_name) {
      setValue('first_name', bodyContent.first_name);
      setValue('last_name', bodyContent.last_name);
      setValue('phone_number', bodyContent.phone_number);
      setValue('email', bodyContent.email);
      setValue('identity_doc_no', bodyContent.identity?.number);
      setValue('identity_doc_type', bodyContent.identity?.type);


    }
  }, [bodyContent.first_name, setValue]);
    // const handleKeyDown = (event) => { if (event.key === 'Enter') { event.preventDefault(); handleSubmit(onSubmit)(); } };
    const onSubmit = (data) => {

        console.log(data);

        Swal.fire({
            title: "Etes-vous sûr d'effectuer cette opération ?",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: "Oui",
            denyButtonText: `Annuler`
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Axios.put(`supplier/manager/${bodyContent.id}`,data,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(function (response) {
                        Swal.fire({
                            title: "Opération réussie!",
                            text: "Modification apporté avec succès.",
                            icon: "success"
                        })
                        // .then((result) => {
                        //     if (result.isConfirmed) {
                        //         //redirection si le client clique sur le bouton "ok";
                        //         // window.location.reload();
                        //         // window.location.assign(res.data.url)
                        //     }
                        // });

                    })
                    .catch(error => {
                        // Lorsqu'il y a une erreur 
                        console.log("erreur inattendu", error);
                        Swal.fire({
                            title: 'Erreur!',
                            text: 'Erreur lors de la requête de modification.',
                            icon: 'error',
                            // confirmButtonText: 'OK'
                        });
                    });
            } else if (result.isDenied) {
                Swal.fire("Opération annulée", "", "info");
            }
        });
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSubmit(onSubmit)(); // Déclenche la validation quand "Enter" est pressé
        }
      };
    return (
        <>
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <ModalHeader title={title} />
                        <div className="modal-body">
                        <form onKeyPress={handleKeyPress} >
                        {/* <button type='submit'>ok</button> */}

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="first_name" {...register("first_name")}/>
                                <label htmlFor="first_name">Nom</label>
                            </div>  
                            <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="last_name" {...register("last_name")}/>
                                        <label htmlFor="last_name">Prénoms</label>
                            </div>  
                            <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="number"  {...register("identity_doc_no")}/>
                                        <label htmlFor="number">N* d'identité</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="type" {...register("identity_doc_type")}/>
                                <label htmlFor="number">Type de pièce</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="phone_number" {...register("phone_number")}/>
                                <label htmlFor="phone_number">Contact</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="address" value={bodyContent.business?.address} disabled/>
                                <label htmlFor="address">ville/commune</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="email" {...register("email") }/>
                                <label htmlFor="email">Email</label>
                            </div>  
                            <div className='separator'><h6>Dossier</h6></div>    
                            <div className="col p-3">
                                <div className="row gap-3">
                                    <div className="col border rounded-2">
                                        {/* <div className="card"> */}
                                            <p className='text-start date ms-1'>Photo de profil</p>
                                            <img src={bodyContent.picture === null ? ImageDefault : bodyContent.picture} width={100} className="img-fluid py-1 rounded-pill" alt="..." />
                                        {/* </div> */}
                                    </div>
                                    <div className='col border rounded-2'>
                                        {/* <div className="card"> */}
                                            <p className='text-start date ms-1'>Pièces d'identité</p>
                                            <img src={bodyContent.identity?.pictures === null ?  ImageDefault : bodyContent.identity?.pictures[0]} width={100} className="img-fluid py-1" alt="..." />
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>   
                        </form>            
                        </div>

                        <div className="modal-footer">
                            <small className="text-black me-auto">CREER LE {bodyContent.business?.created_at_fr}</small>
                            <button className="btn btn-outline-secondary rounded-none text-danger" onClick={()=>disableAccess(bodyContent.id)}>Désactiver l'accès</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalInfoGerant