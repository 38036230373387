import React from 'react'
import { Link } from 'react-router-dom'
import LetrineLogo from '../assets/letrineLogo.svg'
import '../styles/navbar.css';


const Navbar = () => {
    return (
        <header className="">
                <Link to="/accueil" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    {/* <i className="bi pe-none me-2" width="30" height="30"></i> */}
                    <img src={LetrineLogo} className='w-100' width="50" height="50" alt="logo" />
                    {/* <span className="fs-5 fw-semibold"> <span className='text-dark fw-semibold'>FOURNISSEUR</span> <br /> <span className='text-warning fw-semibold'>DAYMOND</span></span> */}
                </Link>
        </header>
    )
}
export default Navbar