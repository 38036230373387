import './styles/App.css';
import Login from './pages/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Accueil from './pages/Accueil';
import Layout from './pages/Layout';
import Commande from './pages/commandes/Commande';
import Comptabilite from './pages/comptabilite/Comptabilite';
import { Produit } from './pages/produits/Produit';
import { PageNotFund } from './pages/PageNotFund';
import CommandeDetails from './pages/commandes/CommandeDetails';
import ProduitDetails from './pages/produits/ProduitDetails';
import AjoutProduit from './pages/produits/AjoutProduit';
import Parametre from './pages/parametre/Parametres';
import Recharge from './pages/comptabilite/Recharge';
import Solder from './pages/comptabilite/Solder';
import AjouterGerant from './pages/parametre/AjouterGerant';
import AjouterBoutique from './pages/parametre/AjouterBoutique';
import { AuthGuardUser } from './services/AuthGuard.User';
import ModifProduit from './pages/produits/ModifProduit';
import ConditionGenerale from './pages/ConditionGenerale';
import NonSolder from './pages/comptabilite/NonSolder';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<AuthGuardUser><Layout/></AuthGuardUser>}>
            <Route path="/accueil" element={<Accueil />} />
            <Route path="/commandes" element={<Commande />}>
            </Route>
            <Route path="/commandes/detail/:id" element={<CommandeDetails />} />
            <Route path="/produits/" element={<Produit />}>
            </Route>              
            <Route path="/produits/details/:id" element={<ProduitDetails />} />
            <Route path="/produits/ajouter-produit" element={<AjoutProduit />} />
            <Route path="/produits/modifier-produit/:id" element={<ModifProduit />} />

            <Route path="/comptabilite" element={<Comptabilite />}>
              <Route index element={<NonSolder />} />  {/* Route par défaut */}
              <Route path="/comptabilite/recharge" element={<Recharge />}/>
              <Route path="/comptabilite/solder" element={<Solder />}/>
              <Route path="/comptabilite/non-solder" element={<NonSolder />}/>              
            </Route>

            <Route path="/parametres" element={<Parametre />}></Route>
            <Route path="parametres/ajouter/gerant" element={<AjouterGerant />}/>
            <Route path="parametres/ajouter/boutique" element={<AjouterBoutique/>}/>

            <Route path="/:page" element={<PageNotFund />} />
          </Route>
          <Route path="/" element={<Login />} />
          <Route path="/conditions-generales" element={<ConditionGenerale />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
