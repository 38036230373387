import React from 'react'
import { BiArrowBack } from 'react-icons/bi'

const ConditionGenerale = () => {
    return (
        <div className='container text-start mt-3 cgu'>
            <h1 className='h4 pb-2 mb-4 text-secondary border-bottom border-secondary'>Conditions Générales d'Utilisation des distributeurs Daymond</h1>
            <h4 className='text-decoration-underline fw-bold mb-4'>Conditions Générales d'Utilisation</h4>
            <h6 className='fw-bold'> <BiArrowBack /> DISTRIBUTEUR DAYMOND</h6>
            <br />
            <h5  className='fw-bold'>Préambule</h5>
            <p className='c23'>
                La société DAYMOND, Immatriculée au RCCM sous le numéro CI-TDI-2022-B-957,  Dont le siège est situé à Abidjan, pour La plate-forme Daymond Collaboration (ci-après dénommée “DAYMOND”). Les présentes conditions générales d’utilisation (les “CGU”) régissent l’utilisation de la plateforme DAYMOND,
            </p>
            <p>
                En accédant à la plateforme, vous acceptez sans réserve les présentes CGU. Si vous n’acceptez pas ces conditions, vous devez immédiatement cesser d’utiliser la plateforme.
            </p>
            <br />
            <h5  className='fw-bold'>1. Utilisation de DAYMOND COLLABORATION</h5>
            <p>
                1.1. DAYMOND COLLABORATION est une plateforme destinée exclusivement aux collaborateurs de la société Daymond. Elle inclut trois catégories d’utilisateurs : Recruteur Daymond, Ambassadeur Daymond, et distributeur Daymond. L’accès à ces services est gratuit et ne nécessite aucun frais d’abonnement.
            </p>
            <p>
                1.2. L’acceptation des CGU est obligatoire pour tous les utilisateurs. Si vous êtes en désaccord, cessez l’utilisation.
            </p>

            <p>
                1.3. La plate-forme est réservée aux personnes de 18 ans et plus, et son usage à des fins illégales est interdit.
            </p>
            <br />

            <h5  className='fw-bold'>
                2. Obligations des utilisateurs
            </h5>
            <p>2.1. Vous vous engagez à fournir des informations exactes lors de votre inscription et à les mettre à jour.</p>
            <p>2.2. Si vous utilisez la plate-forme à des fins professionnelles, vous certifiez disposer des autorisations nécessaires pour accepter ces CGU au nom de l’entité que vous représentez.</p>
            <p>2.3. Vous et toute entité associée êtes tenus de respecter les CGU.</p>
            <br />
            <h5  className='fw-bold'>3. Modifications des CGU</h5>
            <p>3.1. Nous nous réservons le droit de les modifier à tout moment. Votre utilisation continue de la plate-forme, vaut acceptation des mises à jour.</p>
            <br />
            <h5  className='fw-bold'>
                4. À propos des Distributeurs Daymond
            </h5>
            <p>
                4.1. En tant que Distributeur Daymond, vous acceptez de respecter ces Conditions Générales, qui définissent votre relation avec Daymond et l’utilisation de notre plateforme de commerce électronique. Par votre acceptation, vous certifiez disposer de l’autorisation nécessaire pour représenter votre entreprise et agir dans le respect des lois applicables à votre juridiction.
            </p>
            <p>
                Le Distributeur Daymond est un fournisseur de produits ou services proposant des tarifs de gros à Daymond. Ces conditions avantageuses permettent à Daymond de mettre ces produits à disposition des dropshippers à des prix similaires, leur offrant ainsi la possibilité de générer des marges sur leurs ventes.
            </p>

            <p>
                Daymond met à votre disposition une plateforme performante permettant la commercialisation efficace de vos offres. Vous bénéficiez également de divers services professionnels incluant un support marketing, une assistance dédiée, ainsi qu’une intégration fluide à notre réseau logistique et système de paiement. Cette collaboration vous
                offre ainsi les ressources nécessaires pour accroître vos ventes et améliorer l’expérience client tout en bénéficiant d’un accompagnement global.
            </p>
            <br />
            <h5  className='fw-bold'>
                5. Conditions d’Éligibilité
            </h5>

            <p>Pour devenir Distributeur Daymond, les critères suivants doivent être remplis :</p>
            <ul className='cgu-ul'>
                <li> <span className='fw-semibold'>Posséder une boutique physique :</span> Assure la légitimité et la stabilité de votre entreprise, renforçant ainsi la confiance des clients.</li>
                <li><span className='fw-semibold'>Localisation sur Google Maps : </span>Augmente la visibilité et la crédibilité de votre boutique, facilitant l’accès des clients.</li>
                <li><span className='fw-semibold'>Présence en capitale : </span>Les distributeurs doivent être basés dans la capitale pour faciliter les livraisons et les achats en présentiel.</li>
                <li><span className='fw-semibold'>Avoir un registre de commerce valide :</span> Assure la transparence et la conformité légale nécessaires à une collaboration professionnelle.</li>
                <li><span className='fw-semibold'>Stock minimum de 30 unités :</span>Permet une disponibilité rapide des produits pour les dropshippers et minimise les ruptures de stock.</li>
                <li><span className='fw-semibold'>Produits de qualité : </span> Essentiel pour maintenir une satisfaction élevée et réduire les retours, renforçant ainsi la confiance des clients.</li>
                <li><span className='fw-semibold'> Prix compétitifs :</span>Offre aux dropshippers une marge attractive pour encourager les ventes.</li>
                <li><span className='fw-semibold'>Respect des délais de livraison :</span>Garantit une expérience positive et fidélise les clients.</li>
                <li><span className='fw-semibold'>Service client efficace :</span>Permet de gérer les problèmes rapidement et d’améliorer la communication avec Daymond et les acheteurs.</li>
                <li><span className='fw-semibold'>Participation aux campagnes de promotion :</span> Accroît la visibilité de vos produits et augmente les ventes grâce à des actions conjointes.</li>
                <li><span className='fw-semibold'>Respect des conditions de collaboration :</span> Assure une relation harmonieuse et professionnelle avec Daymond.</li>
            </ul>
            <br />
            <h5  className='fw-bold'>Produits et Services Acceptés et Non Acceptés</h5>
            <h6  className='fw-bold'>
                6.1. Produits et Services Acceptés
            </h6>
            <p> Les Distributeurs Daymond peuvent proposer des catégories de produits telles que :</p>
            <ul className='cgu-ul'>
                <li>Vêtements et Accessoires</li>
                <li>Électronique et Gadgets</li>
                <li>Articles de Maison</li>
                <li>Produits Numériques</li>
                <li>Produits Locaux et Écologiques</li>
                <li>Produits Artisanaux</li>
                <li>Fournitures de Bureau</li>
                <li>Jeux Vidéo</li>
                <li>Immobilier et Véhicules</li>
                <li>Sport et Loisirs</li>
                <li>Matériel Professionnel</li>
                <li>Beauté et Soins Personnels</li>
                <li> Santé et Bien-être</li>
            </ul>
            <h6  className='fw-bold'>
                6.2. Produits et Services Non Acceptés
            </h6>
            <p>Les produits suivants sont strictement interdits :</p>
            <ul className='cgu-ul'>
                <li>Drogues et Substances Contrôlées</li>
                <li>Armes et Munitions</li>
                <li> Produits Contrefaits</li>
                <li>Produits Périssables</li>
                <li>Contenu Pornographique ou Explicite</li>
                <li>Animaux Vivants</li>
                <li>Produits Dangereux</li>
                <li>Produits Volés</li>
                <li>Tabac</li>
                <li>Contenus Haineux</li>
            </ul>
            <br />
            <h5  className='fw-bold'>
                7. Qualité et Authenticité des Produits
            </h5>
            <p>
                Tous les produits proposés sur la plateforme Daymond doivent être authentiques, de haute qualité et conformes aux descriptions fournies. Ils ne doivent pas être endommagés, contrefaits ou non conformes aux réglementations en vigueur.
            </p>
            <br />
            <h5  className='fw-bold'>
                8. Garantie
            </h5>
            <p>Une garantie minimale de 30 jours est exigée pour chaque produit ou service vendu sur la plateforme. Cette garantie vise à assurer la satisfaction des clients et le maintien de la qualité des produits proposés.</p>
            <p>
                Toute modification de la durée de garantie par un Distributeur Daymond, sans accord préalable de Daymond, est interdite. Selon les spécificités du produit, la garantie peut varier jusqu’à un an ou plus, renforçant ainsi la confiance et la fidélité des clients.
            </p>
            <br />

            <h5  className='fw-bold'>9. Présentation des Produits</h5>
            <p>Les descriptions et les images de produits doivent être fidèles, complètes et précises, reflétant exactement les caractéristiques des produits. Les images doivent être de haute qualité et représenter le produit réel.</p>
            <br />
            <h5  className='fw-bold'>10. Droits d’Utilisation et Propriété Intellectuelle</h5>
            <p>
                En tant que Distributeur Daymond, vous autorisez l’utilisation de vos produits à des fins commerciales et promotionnelles, y compris l’affichage, la promotion et la distribution via la plateforme Daymond et ses canaux de communication. Les produits proposés ne doivent enfreindre aucun droit légal ou de propriété intellectuelle. Vous octroyez à Daymond une licence mondiale non exclusive et gratuite pour utiliser, reproduire et promouvoir les images et descriptions de vos produits.
            </p>
            <br />
            <h5  className='fw-bold'>
                11. Prix et Tarifs
            </h5>
            <p>
                Les Distributeur Daymond sont tenus de proposer leurs produits à des prix de gros pour renforcer la compétitivité de la plateforme et permettre aux vendeurs Daymond de réaliser des marges attractives.
                La tarification doit être suffisamment avantageuse pour garantir une offre concurrentielle, et Distributeur Daymond sont encouragés à réévaluer régulièrement leurs prix pour attirer les acheteurs et maximiser le volume de ventes.
            </p>
            <br />
            <h5  className='fw-bold'>
                12. Stockage et Éligibilité
            </h5>
            <p>
                Le stockage des produits repose sur l’éligibilité du Distributeur Daymond aux conditions stipulées. Daymond met à disposition un entrepôt centralisé pour optimiser la gestion des stocks et accélérer l’expédition des commandes, garantissant ainsi une logistique fluide et une satisfaction client accrue.
            </p>
            <p>
                Distributeurs Daymond conformes aux exigences de Daymond bénéficient de cet espace, permettant une disponibilité constante de leurs produits et une délégation simplifiée de la logistique.
            </p>
            <br />
            <h5  className='fw-bold'>
                13. Traitement des Commandes
            </h5>
            <p>
                Daymond propose deux options pour la gestion des commandes, permettant flexibilité et optimisation des opérations logistiques.
            </p>

            <h6  className='fw-bold'>
                13. 1.   Traitement intégral (par Daymond)
            </h6>
            <p>Daymond prend en charge l’ensemble du processus, depuis la commande jusqu’à la livraison, en incluant le service client, la collecte des paiements, et la gestion après-vente. Le montant des ventes est ensuite versé au Distributeur Daymond, lui permettant de se concentrer sur ses opérations commerciales.</p>

            <h6  className='fw-bold'>
                13. 2.   Traitement conjoint (par le distributeur)
            </h6>

            <p>
                Daymond gère la prise de commande et la coordination initiale avec le client, avant de transférer la responsabilité de la livraison au Distributeur Daymond. Ce modèle permet au Distributeur Daymond de superviser directement la livraison tout en bénéficiant du support de Daymond pour les étapes de gestion.
            </p>
            <br />
            <h5  className='fw-bold'>
                14. Paiement
            </h5>

            <h6  className='fw-bold'>
                14.1. Paiement aux Distributeurs Daymond
            </h6>

            <p>
                Une fois une commande confirmée et livrée par le Distributeur Daymond, celui-ci perçoit le montant correspondant et reverse la part due à Daymond, selon les informations de compte fournies. Pour les commandes livrées par Daymond, le montant est transféré au Distributeur Daymond dans un délai maximum de 24 heures, via le mode de paiement enregistré.
            </p>

            <h6  className='fw-bold'>
                14.2. Commission Daymond
            </h6>

            <p>Daymond est rémunéré uniquement par une commission, sans frais supplémentaires. Cette commission correspond à une majoration sur le prix de gros fourni par le Distributeur Daymond lors de l’enregistrement des produits, dont 50 % est versé au vendeur ayant confirmé la commande. Ce système soutient la gestion logistique, le service client, les efforts marketing, et le développement continu de la plateforme.</p>
            <br />
            <h5  className='fw-bold'>
                15. Livraison & Expédition
            </h5>

            <p>
                Les commandes sont livrées par Daymond ou le Distributeur Daymond, selon le lieu de stockage et les délais requis.
            </p>

            <p>
                Daymond s’engage à respecter des délais courts et la qualité du service logistique.
                Distributeur Daymond, lorsqu’ils assurent la livraison, doivent respecter les délais et veiller à une exécution professionnelle.
            </p>
            <br />
            <h5  className='fw-bold'>16. Retours et Remboursements</h5>

            <p>Les retours sont gérés par le Distributeur Daymond avec l’assistance du service après-vente de Daymond, selon les conditions de retour en vigueur.Les remboursements sont effectués par le Distributeur Daymond à réception du produit, hors frais de livraison, qui restent non remboursables en raison des services déjà fournis.</p>
            <br />
            <h5  className='fw-bold'>
                17. Frais Supplémentaires
            </h5>
            <br />
            <p>
                Les frais de livraison, d’emballage, et autres charges annexes ne sont facturables à l’acheteur que s’ils sont explicitement précisés dans la description des produits.
            </p>
            <br />
            <h5  className='fw-bold'>
                16. Service Client
            </h5>
            <p>
                Daymond offre un support client dédié, mais les Distributeurs Daymond sont également tenus de maintenir un service client de qualité, apte à gérer efficacement les questions et réclamations afin de garantir une expérience client positive.
            </p>
            <br />
            <h5  className='fw-bold'>
                17. Utilisation Interdite
            </h5>
            <p>
                En utilisant notre plateforme, vous vous engagez à ne pas porter atteinte aux performances, à la disponibilité, à la sécurité ou à l’intégrité de celle-ci. Toute utilisation illégale, frauduleuse, nuisible, ou tentative de modifier, pirater, sonder, scanner les vulnérabilités ou d’altérer le site est interdite. La diffusion de logiciels malveillants, la surcharge de nos ressources et le déchiffrement de communications sans autorisation sont strictement prohibés. La collecte automatisée de données sans notre accord explicite est également interdite. Toute infraction expose l’utilisateur à des sanctions juridiques.
            </p>
            <br />
            <h5  className='fw-bold'>
                18. Propriété Intellectuelle (Copyright) et Marques Déposées
            </h5>

            <p>
                Sauf mention contraire, Daymond détient tous les droits d’auteur et de propriété intellectuelle liés à la plateforme. Les marques déposées et non déposées de Daymond sont protégées, et leur utilisation non autorisée est interdite. Les marques de tiers présentes sur le site restent la propriété de leurs détenteurs respectifs et ne peuvent être utilisées sans leur autorisation.
            </p>
            <br />
            <h5  className='fw-bold'>19. Limitations de Responsabilité et Modifications des Services</h5>
            <p>
                Daymond ne garantit pas un fonctionnement ininterrompu de la plateforme, ni la disponibilité continue des services, notamment en cas de force majeure (catastrophes naturelles, conflits, épidémies, etc.). Nous nous réservons le droit de modifier ou d’interrompre tout service sans préavis ni indemnisation, sauf pour les commandes en cours. En cas de suspension ou modification non liée à la force majeure, les utilisateurs seront informés avec un préavis de quinze (15) jours.
            </p>
            <br />
            <h5  className='fw-bold'>
                20. Violation des Conditions et Sanctions
            </h5>
            <p>
                Le non-respect des présentes conditions peut entraîner la suspension ou l’interdiction d’accès à notre plateforme. Cela inclut la restriction d’accès à partir de votre adresse IP et la suppression de votre compte. En cas de blocage, toute tentative de contournement est strictement interdite.
            </p>
            <br />
            <h5  className='fw-bold'>
                21. Résolution des Litiges
            </h5>
            <p>
                Les litiges entre Daymond et Distributeur Daymond sont soumis à notre politique de résolution des conflits, incluant la médiation interne ou, si nécessaire, l’intervention d’un médiateur externe. Distributeur Daymond s’engagent à respecter cette procédure pour une résolution rapide et équitable.
            </p>
            <br />
            <h5  className='fw-bold'>
                22. Modifications des Conditions
            </h5>
            <p>
                Daymond se réserve le droit de modifier ces conditions à tout moment. Les modifications seront publiées sur la plateforme et prendront effet immédiatement.
            </p>
            <br />
            <h5  className='fw-bold'>
                23. Contact
            </h5>
            <p>
                Pour toute question, veuillez nous contacter à hello.daymond.fr ou via le formulaire de contact sur la plateforme.
            </p>

        </div>
    )
}

export default ConditionGenerale