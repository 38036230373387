import React from 'react'

export const ModalFooter = ({onSave, boutonText, disabled, style}) => {
    return (
        <div>
            <div className="modal-footer d-flex justify-content-center" >
                <button type="button" className="btn btn-primary align-items-center w-75" id={style} disabled={disabled} onClick={onSave}>{boutonText}</button>
            </div>
        </div>
    )
}
