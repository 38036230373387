import React from 'react'

export const LoadSpinnerTwo = () => {
  return (
    <div>
        <p><div className="spinner-grow text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
        </div></p>
    </div>
  )
}
