import React, { useState } from 'react'
import { ModalFooter } from './modalComponents/ModalFooter'
// import { ModalBody } from './modalComponents/ModalBody'
import { ModalHeader } from './modalComponents/ModalHeader'
import Swal from 'sweetalert2'
import Axios from '../../services/Config'
import { Link, NavLink } from 'react-router-dom'
import { DeviceFormat } from '../DeviceFormat'
import { FormatDateHeure } from '../FormatDate'

export const Modal = ({ title, bodyContent, boutonText, style }) => {
    // console.log("mydata", bodyContent)
    const token = localStorage.getItem('sessionDataToken');
    // États pour stocker le message et les données
    const [message, setMessage] = useState('');

    const onSave = () => {
        // Réinitialiser le message
        setMessage('');
        Swal.fire({
            title: "Etes-vous sûr d'effectuer cette opération ?",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: "Oui",
            denyButtonText: `Annuler`
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Axios.get(`supplier/transaction/${bodyContent.id}/pay`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(function (response) {
                        Swal.fire({
                            title: "Opération réussie!",
                            text: "retrait effectué avec succès",
                            icon: "success"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                //redirection si le client clique sur le bouton "ok";
                                window.location.reload();
                                // window.location.assign(res.data.url)

                            }
                        });

                    })
                    .catch(error => {
                        // Lorsqu'il y a une erreur 
                        console.log("erreur inattendu", error);
                        setMessage(error.response.data.message);
                        console.log("recharge effectué avec succès : ", message);
                    });
            } else if (result.isDenied) {
                Swal.fire("opération annulée", "", "danger");
            }
        });
    }
    return (
        <div>
            {/* Button trigger modal */}
            {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Launch static backdrop modal
            </button> */}

            {/* Modal parent */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <ModalHeader title={title} style={style}></ModalHeader>
                        <div className="modal-body">
                            {/* {bodyContent?.amount} */}
                            {bodyContent?.order?.items?.map((item) => (
                                <div key={item.id} className="d-flex flex-column justify-content-between text-start">
                                    <div className="row mb-2">
                                        <div className="col-2"><img src={item.product.images[0]} width={80} height={80} className="rounded border" alt="" /></div>
                                        <div className="col-6">
                                            <div>{item.product.name}</div>
                                            <div className='text-reduit'>stock {item.product.stock}/{item.product.stock} pièces</div>
                                            <small className='date'>{item.product.code}</small>
                                        </div>
                                        <div className="col-4 mt-3 info-general">
                                            <div className="btn btn-outline-secondary btn-sm float-end text-truncate" onClick={()=> window.location.assign(`/produits/details/${item.product.id}`) }><span className='text-reduit'>INFORMATION GENERALE</span></div>
                                        </div>
                                    </div>
                                    <div className="border-start border-2 mx-1 mb-2">
                                        <div className=" border-padding ">
                                            <h6>Prix partenaire</h6>
                                            <p className='fw-bold'>{DeviceFormat(item.product.price.partner)}</p>
                                        </div>
                                    </div>
                                    <div className="border-start border-2 mx-1 mb-2">
                                        <div className=" border-padding">
                                            <h6>Prix de vente</h6>
                                            <p className='fw-bold'>{DeviceFormat(item.price)}</p>
                                        </div>
                                    </div>
                                    <div className="border-start border-2 mx-1 mb-2">
                                        <div className=" border-padding">
                                            <h6>Frais de livraison</h6>
                                            <p className='fw-bold'>{DeviceFormat(item.total_fees)}</p>
                                        </div>
                                    </div>
                                    <div className="border-start border-2 mx-1 mb-2">
                                        <div className=" border-padding">
                                            <h6>Total payé</h6>
                                            <p className='fw-bold'>{DeviceFormat(item.total)}</p>
                                        </div>
                                    </div>
                                    <div className="border-start border-2 mx-1 mb-2">
                                        <div className="border-padding">
                                            <h6>Commission Daymond</h6>
                                            <p className='fw-bold text-orange'>{DeviceFormat(bodyContent.amount)}</p>
                                        </div>
                                    </div>
                                    {bodyContent?.category === "sale_admin" && bodyContent?.status === "validated" ? (
                                        <div>
                                            <div className="border-start border-2 mx-1 mb-2">
                                                <div className="border-padding">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className='left'>
                                                        <h6 className='type'>TYPE</h6>
                                                        <div className=''>LIENS</div>
                                                        <p className='fw-meduim'>{bodyContent.operator === null ? "RAS" : bodyContent.operator}</p>
                                                    </div>
                                                    <div className="right">
                                                    <p className='fw-meduim'>{bodyContent.phone_number === null ? "inconnu" : bodyContent.phone_number}</p>

                                                    </div>
                                                </div>

                                                </div>
                                            </div>
                                            <div className="border-start border-2 mx-1">
                                                <div className=" border-padding">
                                                    <h6>REFERENCE</h6>
                                                    <p className='fw-bold'>{bodyContent.reference}</p>
                                                </div>
                                            </div>
                                        </div>

                                    ) : ""}
                                </div>
                            ))}
                        </div>
                        {bodyContent?.category !== "sale_admin" && bodyContent?.status !== "validated" ?
                            (<div>
                                <ModalFooter onSave={onSave} boutonText={boutonText} style="btn-solder"></ModalFooter>
                                {/* Afficher le message s'il existe */}
                                {message && (
                                    <div className={`${message ? 'text-danger mb-3' : 'text-success mb-3'}`} role="alert">
                                        {message}
                                    </div>
                                )}
                            </div>) : (
                                <div className='mx-4 d-flex justify-content-between align-items-center footer-modal'>
                                    <div className='left date'>{FormatDateHeure(bodyContent.updated_at)}</div>
                                    <div className="right">
                                        <div className="btn btn-primary mb-3 disabled">Voire la facture</div>
                                    </div>
                                </div>
                            )}

                    </div>
                </div>
            </div>
        </div>
    )
}
