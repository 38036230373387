import React, { useState } from 'react'
import ImageDefault from "./../../assets/ImageDefault.svg"
import { Image, Upload, Form } from 'antd';

const InfoGenerale = ({
  boutiqueInfo,
  nextStep,
  handleOnChange,
  dataCity,
  // Props pour le registre de commerce
  fileList,
  handlePreview,
  handleChange,
  previewOpen,
  setPreviewOpen,
  previewImage,
  setPreviewImage,
  // Props pour le logo
  logoFileList,
  handleLogoPreview,
  handleLogoChange,
  logoPreviewOpen,
  setLogoPreviewOpen,
  logoPreviewImage,
  setLogoPreviewImage,
}) => {

  // Bouton pour uploader (RC)
  const uploadButton = (
    <div>
      <img src={ImageDefault} width={50} className="img-fluid opacity-50" alt="..." />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  // Bouton pour uploader (logo)
  const uploadLogoButton = (
    <div>
      <img src={ImageDefault} width={50} className="img-fluid opacity-50" alt="..." />
      <div style={{ marginTop: 8 }}>Upload Logo</div>
    </div>
  );
  return (
    <div>
      <div className='mt-3 mb-3' >
        <div className="card-body row">
          <div className="col-4">
            <div className="col mb-3 rounded-2" style={{ backgroundColor: "#F5F6FB" }}>
              <div className="card-body">
                <small className="card-title d-flex text-start">logo</small>
                <div className="card text-center bg-white mb-3">
                  <div className="card-body rounded-pill">
                    <Upload
                      listType="picture-card"
                      fileList={logoFileList}  // Pour le logo
                      onPreview={handleLogoPreview}  // Fonction pour preview logo
                      onChange={handleLogoChange}  // Fonction pour changement logo
                      beforeUpload={() => false}
                    >
                      {logoFileList.length >= 1 ? null : uploadLogoButton}
                    </Upload>

                    {/* Prévisualisation pour le logo */}
                    {logoPreviewImage && (
                      <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: logoPreviewOpen,
                          onVisibleChange: (visible) => setLogoPreviewOpen(visible),
                          afterOpenChange: (visible) => !visible && setLogoPreviewImage(''),
                        }}
                        src={logoPreviewImage}
                      />
                    )}
                    {/* <div className="card-title"><img src={ImageDefault} width={50} className="img-fluid" alt="..." /></div> */}
                  </div>
                </div>
                <div className="file-input-container">
                  {/* <label htmlFor="fileInput1" className="file-input-label">
                    <input
                      type="file"
                      id="fileInput1"
                      accept="image/*"
                      className="file-input"
                      onChange={(e) => handleImageChange(e, 'image1')}
                    />
                    <div className="preview-container">
                      {selectedImage.image1 ? (
                        <img src={selectedImage.image1} alt="Aperçu" className="preview" />
                      ) : (
                        <div className="placeholder">
                          <img
                            src={ImageDefault}
                            alt="placeholder"
                            className="placeholder-icon"
                          />
                        </div>
                      )}
                    </div>
                  </label> */}
                </div>
              </div>
            </div>
            <div className="col p-3 rounded-2" style={{ backgroundColor: "#F5F6FB" }}>
              <div className="d-flex justify-content-between mb-2">
                <small>registre de commerce</small>
                <small>2 image</small>
              </div>
              <div className="row">
                <Upload
                  // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  beforeUpload={() => false}

                >
                  {fileList.length >= 2 ? null : uploadButton}
                </Upload>
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                  />
                )}
                {/* <div className="col">
                  <div className="card">
                  <label htmlFor="fileInput2" className="file-input-label">
                    <input
                      type="file"
                      id="fileInput2"
                      accept="image/*"
                      className="file-input"
                      onChange={(e) => handleImageChange(e, 'image2')}
                    />
                    <div className="preview-container">
                      {selectedImage.image2 ? (
                        <img src={selectedImage.image2} alt="Aperçu 2" className="preview" />
                      ) : (
                        <div className="placeholder 2">
                          <img
                            src={ImageDefault}
                            alt="placeholder"
                            className="placeholder-icon"
                          />
                        </div>
                      )}
                    </div>
                  </label>                  </div>
                  <p>recto</p>
                </div>
                <div className='col'>
                  <div className="col card">
                  <label htmlFor="fileInput3" className="file-input-label">
                    <input
                      type="file"
                      id="fileInput3"
                      accept="image/*"
                      className="file-input"
                      onChange={(e) => handleImageChange(e, 'image3')}
                    />
                    <div className="preview-container">
                      {selectedImage.image3 ? (
                        <img src={selectedImage.image3} alt="Aperçu 3" className="preview" />
                      ) : (
                        <div className="placeholder">
                          <img
                            src={ImageDefault}
                            alt="placeholder 3"
                            className="placeholder-icon"
                          />
                        </div>
                      )}
                    </div>
                  </label>                  </div>
                  <p>verso</p>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="text-start border-none">
              <div className="card-body">
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="name" name='name' placeholder="Nom de la boutique" value={boutiqueInfo.name} onChange={handleOnChange} required/>
                  <label htmlFor="nom">Nom de la boutique</label>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="ncc" name="ncc" placeholder="NCC" value={boutiqueInfo.ncc} onChange={handleOnChange} />
                  <label htmlFor="ncc">NCC</label>
                </div>
                <div className="form-floating mb-3">
                  <input type="email" className="form-control" id="email_shop" name="email_shop" placeholder="Email" value={boutiqueInfo.email_shop} onChange={handleOnChange} required/>
                  <label htmlFor="email">Email</label>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="contact" name='phone_number_shop' placeholder="Contact" value={boutiqueInfo.phone_number_shop} onChange={handleOnChange} required/>
                  <label htmlFor="contact">Contact</label>
                </div>
                <div className="form-floating mb-3">
                  <select className='form-select' id="city_id" name='city_id' value={boutiqueInfo.city_id} onChange={handleOnChange} required>
                    <option disabled></option>
                    {dataCity.map((city) => (
                      <option key={city.id} value={city.id}>{city.name}</option>
                    ))}
                  </select>
                  <label htmlFor="city_id">Ville/Commune</label>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="address" name='address' placeholder="VILLE/COMMUNE" value={boutiqueInfo.address} onChange={handleOnChange} required/>
                  <label htmlFor="address">LOCALISATION MAPS</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <button type="button" className='btn btn-warning' onClick={()=>nextStep()}>Suivant</button> */}
      </div>
    </div>
  )
}

export default InfoGenerale