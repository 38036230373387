import React, { useState, useRef, useEffect } from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import InfoGenerale from './InfoGeneraleBoutiqueForm';
import InfoGerantForm from './InfoGerantForm';
import Swal from 'sweetalert2';
import Axios from '../../services/Config';

const AjouterBoutique = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    // États pour le 1er upload (logo)
    const [logoFileList, setLogoFileList] = useState([]);
    const [logoPreviewOpen, setLogoPreviewOpen] = useState(false);
    const [logoPreviewImage, setLogoPreviewImage] = useState('');
    // États dans le parent pour le 2e upload (RC)
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    // Etats pour le 2e upload (Photo de profil)
    const [profilFileList, setProfilFileList] = useState([]);
    const [profilPreviewOpen, setProfilPreviewOpen] = useState(false);
    const [profilPreviewImage, setProfilPreviewImage] = useState('');
    // État pour le 3e upload (Photo de piece)
    const [pieceFileList, setPieceFileList] = useState([]);
    const [piecePreviewOpen, setPiecePreviewOpen] = useState(false);
    const [piecePreviewImage, setPiecePreviewImage] = useState('');

    // Fonction pour gérer le changement de fichiers (fileList)
    const handleChange = ({ fileList }) => setFileList(fileList);
    // Fonction pour gérer le changement de fichiers pour le logo
    const handleLogoChange = ({ fileList }) => setLogoFileList(fileList);

    // Fonction pour gérer le changement de fichiers pour le profil
    const handleProfilChange = ({ fileList }) => setProfilFileList(fileList);

    // Fonction pour gérer le changement de fichiers pour la pièce
    const handlePieceChange = ({ fileList }) => setPieceFileList(fileList);

    // Fonction pour prévisualiser l'image
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await file.originFileObj;
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    // Fonction pour prévisualiser le logo
    const handleLogoPreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await file.originFileObj;
        }
        setLogoPreviewImage(file.url || file.preview);
        setLogoPreviewOpen(true);
    };
    // Fonction pour prévisualiser le profil
    const handleProfilPreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await file.originFileObj;
        }
        setProfilPreviewImage(file.url || file.preview);
        setProfilPreviewOpen(true);
    };
    // Fonction pour prévisualiser la pièce
    const handlePiecePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await file.originFileObj;
        }
        setPiecePreviewImage(file.url || file.preview);
        setPiecePreviewOpen(true);
    };

    const token = localStorage.getItem('sessionDataToken');

    const [boutiqueInfo, setBoutiqueInfo] = useState({
        name: '',
        ncc: '',
        email_shop: '',
        phone_number_shop: '',
        address: '',
        city_id: '',
        nom_gerant: '',
        prenom_gerant: '',
        cni_gerant: '',
        email_gerant: '',
        contact_gerant: '',
        ville_gerant: '',
    });

    const [steps, setSteps] = useState(1);

    const nextStep = () => {
        setSteps(steps + 1);
    }

    const prevStep = () => {
        setSteps(steps - 1);
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setBoutiqueInfo({ ...boutiqueInfo, [name]: value })
    }

    const MultiStep = () => {
        switch (steps) {
            case 1:
                return <InfoGenerale boutiqueInfo={boutiqueInfo}
                    nextStep={nextStep}
                    dataCity={dataCity}
                    handleOnChange={handleOnChange}
                    //upload ant props
                    fileList={fileList}
                    handlePreview={handlePreview}
                    handleChange={handleChange}
                    previewOpen={previewOpen}
                    setPreviewOpen={setPreviewOpen}
                    previewImage={previewImage}
                    setPreviewImage={setPreviewImage}
                    // upload Props pour le logo
                    logoFileList={logoFileList}
                    handleLogoPreview={handleLogoPreview}
                    handleLogoChange={handleLogoChange}
                    logoPreviewOpen={logoPreviewOpen}
                    setLogoPreviewOpen={setLogoPreviewOpen}
                    logoPreviewImage={logoPreviewImage}
                    setLogoPreviewImage={setLogoPreviewImage}
                />
            case 2:
                return <InfoGerantForm boutiqueInfo={boutiqueInfo} nextStep={nextStep} prevStep={prevStep} handleOnChange={handleOnChange} dataCity={dataCity}
                    // upload Props pour le profile
                    profilFileList={profilFileList}
                    handleProfilPreview={handleProfilPreview}
                    handleProfilChange={handleProfilChange}
                    profilPreviewOpen={profilPreviewOpen}
                    setProfilPreviewOpen={setProfilPreviewOpen}
                    profilPreviewImage={profilPreviewImage}
                    setProfilPreviewImage={setProfilPreviewImage}
                    // upload Props pour la piece
                    pieceFileList={pieceFileList}
                    handlePiecePreview={handlePiecePreview}
                    handlePieceChange={handlePieceChange}
                    piecePreviewOpen={piecePreviewOpen}
                    setPiecePreviewOpen={setPiecePreviewOpen}
                    piecePreviewImage={piecePreviewImage}
                    setPiecePreviewImage={setPiecePreviewImage}
                />
            default:
                return onFormSubmit();
        }
    }

    const onFormSubmit = () => {
        // Envoie des données à votre API
        // Utilisation de FormData pour envoyer l'image et les autres données
        const data = new FormData();
        data.append('name', boutiqueInfo.name);
        data.append('email_shop', boutiqueInfo.email_shop);
        data.append('ncc', boutiqueInfo.ncc);
        data.append('phone_number_shop', boutiqueInfo.phone_number_shop);
        data.append('city_id', boutiqueInfo.city_id);
        data.append('address', boutiqueInfo.address);
        data.append('first_name', boutiqueInfo.nom_gerant);
        data.append('last_name', boutiqueInfo.prenom_gerant);
        data.append('identity_doc_no', boutiqueInfo.cni_gerant);
        data.append('identity_doc_type', boutiqueInfo.cni_gerant);
        data.append('email', boutiqueInfo.email_gerant);
        data.append('phone_number', boutiqueInfo.contact_gerant);
        fileList.forEach((file) => {
            data.append('pictures[]', file.originFileObj); // Envoi du fichier brut
        });
        // Ajout du logo seulement s'il est sélectionné
        if (logoFileList.length > 0) {
            logoFileList.forEach((file) => {
                data.append('logo', file.originFileObj); // Envoi du fichier brut
            });
        }
        // Ajout du profile seulement s'il est sélectionné
        if (profilFileList.length > 0) {
            profilFileList.forEach((file) => {
                data.append('profile_picture', file.originFileObj); // Envoi du fichier brut
            });
        }
        // Ajout des pièces seulement s'illes sont sélectionnées
        if (pieceFileList.length > 0) {
            pieceFileList.forEach((file) => {
                data.append('identity_doc[]', file.originFileObj); // Envoi du fichier brut
            });
        }

        // for (let [key, value] of data.entries()) {
        //     console.log(`${key}: ${value}`);
        //   }
        // Envoi des données à notre API
        Axios.post('supplier/shop', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(function (response) {
                console.log("Ajouter avec succès : ", response.data);
                Swal.fire({
                    title: "Opération réussie!",
                    text: "Produit ajouter avec succès",
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        //redirection si le client clique sur le bouton "ok";
                        navigate('/produits');
                    }
                });
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    title: 'Erreur!',
                    text: error.message,
                    icon: 'error',
                    // confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        //redirection si le client clique sur le bouton "ok";
                        prevStep()
                    }
                });
            });

        // Ajouter les autres champs à data
        // Swal.fire({
        //     title: "Boutique enregistré avec succès",
        //     input: "email",
        //     text: 'Cliquez sur "envoyer" pour transmettre les coordonnées de connexion aux mails suivants',
        //     inputPlaceholder: "Entrez votre adresse email",
        //     confirmButtonText: "Envoyer",  // Personnalise le texte du bouton
        //     customClass: {
        //         title: 'text-warning',  // Classe CSS personnalisée pour le titre
        //         confirmButton: 'btn btn-warning text-white'  // Classe CSS personnalisée pour le bouton
        //     }
        // });
        // .then((result) => {
        //     if (result.value) {  // Vérification si l'utilisateur a bien saisi un email
        //         Swal.fire(`Entered email: ${result.value}`);  // Utiliser result.value pour afficher l'email
        //     } else {
        //         Swal.fire('No email entered');  // Message alternatif si aucune valeur n'a été saisie
        //     }
        // });
    }
    // Récupérer les villes
    const [dataCity, setDataCity] = useState([]);
    useEffect(() => {
        //recupérer toutes les boutique relié au compte
        Axios.get("params/data?option=city", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                console.log("Operation 2 effectuée!", response.data);
                setDataCity(response.data.data);
            })
            .catch(error => console.log(error));
    }, []);
    return (
        <div>
            <div className="card rounded-3">
                <div className="card-header bg-white d-flex flex-row">
                    <span className=''>
                        <IoArrowBack onClick={() => navigate(-1)} className='fs-4 me-3' />
                        Nouvelle Commande
                    </span>
                    <span className='btn btn-warning text-white ms-auto' onClick={() => nextStep()} disabled={loading}>
                        {loading ? <div className="spinner-border text-light" role="status"><span className="visually-hidden">Loading...</span></div>
                            : <div>Enregistrer</div>
                        }

                    </span>
                </div>
                <div className="card-body" id='container'>
                    <div className='d-flex align-items-start gap-3 mx-2'>
                        <button type="button" className={`btn ${steps === 1 ? 'btn-warning' : 'btn-outline-warning'} rounded-pill me-2`} onClick={() => setSteps(1)}>Information boutique</button>
                        {/* Ligne entre les étapes */}
                        <hr className="flex-grow-1" style={{ borderTop: '2px solid ##707070', marginLeft: "-24px", marginRight: "-18px" }} />
                        {/* <div className="flex-grow-1 mx-2" ></div> */}

                        <button type="button" className={`btn ${steps === 2 ? 'btn-warning' : 'btn-outline-warning'} rounded-pill me-2`} onClick={() => setSteps(2)}>Information gerant</button>
                        <hr className="flex-grow-1" style={{ borderTop: '2px solid ##707070', marginLeft: "-24px", marginRight: "20px" }} />

                    </div>
                    {MultiStep()}
                </div>
            </div>
        </div>
    )
}

export default AjouterBoutique